import React, { useState, useEffect } from "react";
import "../../analytics.css";
import { useDispatch, useSelector } from "react-redux";
import Compare from "./Compare";
import CompareDatasets from "./CompareDatasets";
import Risk from "./Risk";
import RiskMap from "./RiskMap";
import MapViewAnalytics from "../Explore/Mapping/MapViewAnalytics";

const Analytics = () => {
  const [showcompare, setShowcompare] = useState(false);
  const [showrisk, setShowrisk] = useState(false);
  const [countryName, setCountryName] = useState("");
  const [comparebutton, setcomparebutton] = useState(false);
  const [showevalution, setshowevalution] = useState(false);
  const [showriskmap, setshowriskmap] = useState(false);
  const [featureData, setFeatureData] = useState();
  const [stateData, setStateData] = useState();
  const [selectedState, setSelectedState] = useState();
  const [popUpData, setPopUpData] = useState();
  const stateName = useSelector((state) => state.stateName);
  const districtName = useSelector((state) => state.districtName);
  const getStartYears = useSelector((state) => state.getStartYears);
  const getStartMonth = useSelector((state) => state.getStartMonth);
  const getEndMonth = useSelector((state) => state.getEndMonth);
  const getEndYears = useSelector((state) => state.getEndYears);
  const compareState = useSelector((state) => state.compareState);

  //console.log("condition",showcompare,showrisk,showevalution)


  const handleCompareclick = () => {
    setShowcompare(true);
  };
  const handleRiskclick = () => {
    setShowrisk(true);
    setshowevalution(false);
  };


  useEffect(() => {
    if (countryName) {
      getSpecificBoundary();
    } else {
      setFeatureData(null);
    }
  }, [countryName]);

  useEffect(() => {
    getStateFeature(selectedState);
  }, [selectedState]);

  const getSpecificBoundary = async () => {
    if (countryName !== "") {
      const postData = {
        country: countryName,
      };
      await fetch(`api/coords/get_data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      })
        .then((response) => response.json())
        .then((result) => {
          setFeatureData(result);
          setStateData(result.features);
        })
        .catch((err) => console.log("ERR:".err));
    } else {
      setFeatureData(null);
      setStateData(null);
    }
  };
  const getStateFeature = async (state) => {
    if (countryName !== "" && selectedState !== "") {
      const postData = {
        country: countryName,
        name_1: selectedState,
      };
      await fetch(`api/coords/get_data2`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      })
        .then((response) => response.json())
        .then((result) => {
          let fc = {
            features: [],
            type: "FeatureCollection",
          };
          let filteredFeature = result.features.filter(
            (feature) => feature.properties.NAME_1 === state
          );
          fc.features = filteredFeature;
          setFeatureData(fc);
        })
        .catch((err) => console.log("ERR:".err));
    } else {
      setFeatureData(null);
    }
  };

  return (
    <div className="explore-container">
      <div className="col-md-12" style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ height: '100vh', width: 340, padding: '24px 16px', marginLeft: '64px', borderRight: '1px solid rgba(26, 26, 0, 0.16)' }}>
          <div className="exploretext">Analyse Data</div>
          <div className="exploresecondarytext">Select a region or location to start exploring datasets. Choose from one of the options below.</div>
          <button className="analytics-button" onClick={handleCompareclick}>
            <img src="compare.png" alt="location icon" style={{ marginRight: '8px' }} />
            Compare
          </button>
          <button className="risk-button" onClick={handleRiskclick}>
            <img src="risk.png" alt="location icon" style={{ marginRight: '8px' }} />
            Risk
          </button>
        </div>
        {showcompare &&
          <Compare
            showcompare={showcompare}
            setShowcompare={setShowcompare}
            countryName={countryName}
            setCountryName={setCountryName}
            comparebutton={comparebutton}
            setcomparebutton={setcomparebutton}
            showevalution={showevalution}
            setshowevalution={setshowevalution}
          />}
        {showrisk &&
          <Risk
            showcompare={showcompare}
            setShowcompare={setShowcompare}
            countryName={countryName}
            setCountryName={setCountryName}
            comparebutton={comparebutton}
            setcomparebutton={setcomparebutton}
            showevalution={showevalution}
            setshowevalution={setshowevalution}
            setShowrisk={setShowrisk}
            setshowriskmap={setshowriskmap}
            stateData={stateData}
            setStateData={setStateData}
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            setPopUpData={setPopUpData}
          />}
        <div style={{ position: 'relative', height: '100%', width: '79vw', padding: '22px', overflow: 'scroll', background: '#F6F6F6' }}>

          <div style={{ display: 'flex', position: 'relative', height: '90vh', padding: '20px' }}>

            {showevalution ?
              <>
                {showcompare&& (
                  <CompareDatasets
                    countryName={countryName}
                    setCountryName={setCountryName}
                    stateName={stateName}
                    districtName={districtName}
                    getStartMonth={getStartMonth}
                    getStartYears={getStartYears}
                    getEndYears={getEndYears}
                    getEndMonth={getEndMonth}
                    comparebutton={comparebutton}
                    setcomparebutton={setcomparebutton}
                  />
                )}
                {showrisk && (

                  <Risk
                    showcompare={showcompare}
                    setShowcompare={setShowcompare}
                    countryName={countryName}
                    setCountryName={setCountryName}
                    comparebutton={comparebutton}
                    setcomparebutton={setcomparebutton}
                    showevalution={showevalution}
                    setshowevalution={setshowevalution}
                    setShowrisk={setShowrisk}
                    setshowriskmap={setshowriskmap}
                    stateData={stateData}
                    setStateData={setStateData}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                    setPopUpData={setPopUpData}
                  />
                )}
              </>
              :
              (!showrisk && !showevalution &&
              <div style={{ display: 'flex', position: 'absolute', height: '90vh', width: '100%', justifyContent: 'center', alignItems: 'center', backgroundImage: 'url("Agtuall_compare_map.svg")', backgroundSize: 'cover', backgroundPosition: 'center', zIndex: 1 }}>
                <div className="selectmodule">Select “Compare” to evaluate datasets or “Risk” to analyse risk scores for selected locations, time period and other variables.</div>
              </div>)}
            

              <div
                style={{
                  position: "relative",
                  height: "calc(-45px + 100vh)",
                  width: "calc(-430px + 100vw)",
                  display: showrisk ? "block" : "none"
                }}
              >

                <MapViewAnalytics
                  featureData={featureData}
                  selectedState={selectedState}
                  countryName={countryName}
                />
                {popUpData && selectedState && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "2",

                      bottom: "70px",
                      right: "25px",
                    }}
                  >
                    {popUpData.flood_index && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          boxShadow: "0 0 3px rgba(0,0,0,0.2)",
                          borderRadius: "4px",
                          background: "#fff",
                          marginBottom: "10px",
                        }}
                      >

                        <div style={{ padding: "10px" }}>
                          Flood Index <strong>{popUpData.flood_index}</strong>
                        </div>
                      </div>
                    )}
                    {popUpData.spi_index && (
                      <div
                        style={{
                          boxShadow: "0 0 3px rgba(0,0,0,0.2)",
                          borderRadius: "4px",
                          background: "#fff",
                        }}
                      >

                        <div style={{ padding: "10px", textAlign: "left" }}>
                          SPI Index <strong>{popUpData.spi_index.toFixed(2)}</strong>
                          <ul className="legend">
                            Legend
                            <li>
                              <span style={{ background: "#730000" }}></span>&lt;-2.0:
                              Exceptional Drought
                            </li>
                            <li>
                              <span style={{ background: "#E60000" }}></span>-1.9 to
                              -1.6: Extreme Drought
                            </li>
                            <li>
                              <span style={{ background: "#E60000" }}></span> -1.5 to
                              -1.3: Severe Drought{" "}
                            </li>
                            <li>
                              <span style={{ background: "#FCD37F" }}></span> -1.2 to
                              -0.8: Moderate Drought{" "}
                            </li>
                            <li>
                              <span style={{ background: "#FFFF00" }}></span> -0.7 to
                              -0.5: Dry{" "}
                            </li>
                            <li>
                              <span style={{ background: "#fff" }}></span> -0.5 to
                              0.5: Normal Conditions{" "}
                            </li>
                            <li>
                              <span style={{ background: "#AAFF55" }}></span> 0.5 to
                              0.7: Wet{" "}
                            </li>
                            <li>
                              <span style={{ background: "#01FFFF" }}></span> 0.8 to
                              1.2: Moderate Wet{" "}
                            </li>
                            <li>
                              <span style={{ background: "#00AAFF" }}></span>1.3 to
                              1.5: Severe Wet{" "}
                            </li>
                            <li>
                              <span style={{ background: "#0000FF" }}></span> 1.6 to
                              1.9: Extreme Wet{" "}
                            </li>
                            <li>
                              <span style={{ background: "#0000AA" }}></span> &gt;2.0:
                              Exceptional Wet
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            
              
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;