import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsReact from 'highcharts-react-official';

// Initialize Highcharts heatmap module
HighchartsHeatmap(Highcharts);

const HeatmapComponent = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      chartRef.current.chart.reflow();
    }
  }, [data]);

  const isEmptyData = data.valuesArray ==[];
  const options = {
    chart: {
      type: 'heatmap',
      marginTop: 40,
      marginBottom: 80,
      plotBorderWidth: 1
    },
    title: {
      text: ''
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'middle',
      itemStyle: {
        color: 'rgba(1, 97, 158, 0.2)'
      }
    },
    xAxis: {
      categories: data["x_axis"]
    },
    yAxis: {
      categories: data["y_axis"],
      title: null
    },
    credits:false,
    colorAxis: {
      min: 0,
      max: 100,
      minColor: 'white',
      maxColor: '#01619E'
    },
    tooltip: { enabled:false },
    series: [{
      name: '',
      borderWidth: 1,
      data: data.valuesArray,
      dataLabels: {
        enabled: true,
        color: 'white'
      }
    }]
  };
  

 return (
    <div>
      {isEmptyData ? (
        <p>No data available</p>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
      )}
    </div>
  );
};

export default HeatmapComponent;
