import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DoubleGraphTemp = ({ data1, data2 }) => {
    useEffect(() => {
      if (data1.length > 0 && data2.length > 0) {
        const options = {
            chart: {
                type: 'area',
                zoomType: 'x', 
            },
           
            credits:false,
            title: {
                text: ''
            },
            xAxis: {
              type: 'datetime',
              title: {
                text: '',
              },
            },
            yAxis: {
                title: {
                    text: 'Temperature (°C)'
                }
            },
          tooltip: {
          formatter: function () {
            return '<b>' + Highcharts.dateFormat('%B %e', new Date(this.x)) + '</b><br/>' +
                   this.series.name + ': ' + this.y;
          }
        },
            series: [
                {
                    name: 'Current',
                    data: data1.map(item => [new Date(item.dateString).getTime(), item.temperature]),
                    color: {
                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                        opacity: 0.2,
                        stops: [
                          [0, '#EBBC00'], // start color
                          [1, 'rgba(230, 184, 82, 0.40)'] // end color
                        ]
                      },
                },
                {
                    name: 'Historical',
                    data: data2.map(item => [new Date(item.dateString).getTime(), item.temperature]),
                    color: {
                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                        opacity: 0.4,
                        stops: [
                          [0, '#01619E'], // start color
                          [1, 'rgba(1, 97, 158, 0.30)'] // end color
                        ]
                      },
                }
            ]
        };

        Highcharts.chart('double-area-temp-chart', options);
      }
      else
      {
          <div>No Data Available for this Dataset</div>
      }
    }, [data1, data2]);

    return <div id="double-area-temp-chart" />;
};

export default DoubleGraphTemp;
