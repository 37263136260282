import React, { useState, useEffect } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  changeIndexName,
  changeLandcoverswitch,
  changeNdvimodisswitch,
} from "../../redux/action";

const PixelDatasets = (props) => {
  const [rainfallOpen, setRainfallOpen] = useState(false);
  const [vegetationOpen, setVegetationOpen] = useState(false);
  const [landcoverOpen, setLandcoverOpen] = useState(false);
  const [tempOpen, setTempOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const landcoverswitch = useSelector((state) => state.landcoverswitch);
  const ndvimodisswitch = useSelector((state) => state.ndvimodisswitch);
  const dispatch = useDispatch();

  const handleOptionToggle = (option) => {
    props.setIsDrawerOpen(true);
    if (option === "Landcover")
      dispatch(changeLandcoverswitch(!landcoverswitch));
    if (option === "MODIS 5 Day Interval NDVI")
      dispatch(changeNdvimodisswitch(!ndvimodisswitch));
    if (selectedOptions.length === 4 && !selectedOptions.includes(option)) {
      toast.error(
        <div>
          <div
            style={{
              fontSize: "14px",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Maximum Data Sets Selected
          </div>

          <div style={{ marginTop: "5px", fontSize: "12px", fontWeight: 400 }}>
            Up to four data sets can be displayed for any selected location
          </div>
        </div>,
        {
          autoClose: 5000,
          hideProgressBar: true,
        }
      );
      return;
    }

    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((selectedOption) => selectedOption !== option)
      : [...selectedOptions, option];

    setSelectedOptions(updatedOptions);

    switch (option) {
      case "Chirps Daily Rainfall":
        dispatch(changeIndexName("Chirps Daily Rainfall"));
        props.setChirps5kmDailyRainfall(!props.chirps5kmDailyRainfall);
        break;
      case "Chirps Monthly Rainfall":
        dispatch(changeIndexName("Chirps Monthly Rainfall"));
        props.setChirps5kmMonthlyRainfall(!props.chirps5kmMonthlyRainfall);
        break;

      default:
        break;
    }
  };
  const renderOptions = (options) => {
    return (
      <div style={{ width: "300px", padding: "8px 12px" }}>
        {options.map((option) => (
          <div
            key={option}
            style={{
              color: "#1C2024",
              display: "flex",
              padding: "8px 12px",
              justifyContent: "space-between",
              alignItems: "center",
              fontFamily: "Inter-Medium, Helvetica",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
            }}
            onClick={() => handleOptionToggle(option)}
          >
            <span>{option}</span>
            <input type="checkbox" checked={selectedOptions.includes(option)} />
          </div>
        ))}
      </div>
    );
  };

  const accordionStyle = (isOpen) => ({
    width: "300px",
    padding: "4px 8px",
    background: isOpen ? "#29290507" : "white",
    color: "#1C2024",
    fontFamily: "Inter-Medium, Helvetica",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "26px",
    letterSpacing: "-0.04px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: isOpen ? "2px" : "24px",
  });

  const iconStyle = {
    marginRight: "8px",
  };

  return (
    <div style={{ height: "370px", overflowY: "scroll", bottom: "60px" }}>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          width: "400px",
          padding: "16px",
          textAlign: "initial",
          color: "#21201C",
        }}
      />

      <>
        {/* Rainfall Accordion */}
        <div onClick={() => setRainfallOpen(!rainfallOpen)}>
          <div style={accordionStyle(rainfallOpen)}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src="raindrop.png" alt="Raindrop" style={iconStyle} />
              <span>Rainfall</span>
            </div>
            {rainfallOpen ? <FaAngleUp /> : <FaAngleDown />}
          </div>
        </div>
        {rainfallOpen && renderOptions(["Chirps Monthly Rainfall", "Chirps Daily Rainfall"])}

        {/* Vegetation Accordion */}
        <div onClick={() => setVegetationOpen(!vegetationOpen)}>
          <div style={accordionStyle(vegetationOpen)}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src="plant.png" alt="Vegetation" style={iconStyle} />
              <span>Vegetation</span>
            </div>
            {vegetationOpen ? <FaAngleUp /> : <FaAngleDown />}
          </div>
        </div>

        {/* Landcover Accordion */}
        <div onClick={() => setLandcoverOpen(!landcoverOpen)}>
          <div style={accordionStyle(landcoverOpen)}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src="mountain.png" alt="Landcover" style={iconStyle} />
              <span>Landcover</span>
            </div>
            {landcoverOpen ? <FaAngleUp /> : <FaAngleDown />}
          </div>
        </div>

        <div onClick={() => setTempOpen(!tempOpen)}>
          <div style={accordionStyle(tempOpen)}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src="thermometer.png" alt="Temperature" style={iconStyle} />
              <span>Temperature</span>
            </div>
            {tempOpen ? <FaAngleUp /> : <FaAngleDown />}
          </div>
        </div>
        {/* {tempOpen && renderOptions(DatasetOptions.temperature)} */}
      </>
    </div>
  );
};

export default PixelDatasets;
