import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const PayoutChart = ({ data }) => {
  const years = data.map(item => item.yearInterval);
  const payouts = data.map(item => item.payout);

  const options = {
    chart: {
      type: 'column',
    },   
    title: {
      text: '',
    },
    xAxis: {
      categories: years,
      title: {
        text: '',
      },
    },
    yAxis: {
      title: {
        text: 'Payout(%)',
      },
      labels: {
        format: '{value}',
      },
    },
    legend: {
        enabled: false, 
      },
      credits: {
        enabled: false, 
      },

    series: [
      {
        name: 'Payout',
        data: payouts,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PayoutChart;
