import React, { useState, useEffect } from "react";
import Select from "react-select";
import "../../styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardTitle, CardBody } from "reactstrap";
import "../../analytics.css"; 
import { changeCombination, changeRainfallDataset, changeTempDataset } from "../../redux/action";
import HeatmapComponent from "./HeatmapComponent";
import AccordionComponent from "./AccordionComponent";
import LatencyTable from "./LatencyTable";
import AccordionComponentAvg from "./AccordionComponentAvg";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
const Evaluation = (props) => {
const compareState = useSelector((state) => state.compareState);
  const frequency = useSelector((state) => state.frequency);
const stateName = useSelector((state) => state.stateName);
const districtName = useSelector((state) => state.districtName);
const getStartYears = useSelector((state) => state.getStartYears);
const getStartMonth = useSelector((state) => state.getStartMonth);
const getEndMonth = useSelector((state) => state.getEndMonth);
const getEndYears = useSelector((state) => state.getEndYears);
const getWeatherVariable = useSelector((state) => state.getWeatherVariable);
const RainfallDataset = useSelector((state) => state.RainfallDataset);
const [latencyTableData, setLatencyTableData] = useState([]);
const [loading, setLoading] = useState(false); // State to manage loading status

  const dispatch = useDispatch();
  const [selectedButton, setSelectedButton] = useState('Good Data Percentage');
  const [comparedatasetButton, setcomparedatasetButton] = useState('Min-Max ');
  const [HeatmapData, setHeatmapData] = useState([]);
  const handleTempButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };
  const handlecompareButtonClick = (buttonName) => {
    setcomparedatasetButton(buttonName);
  };
  useEffect(() => {
    
    async function genToken() {
      setLoading(true); // Set loading to true when data fetching starts
  
      try {
        let frequencyForApi = frequency; // Initialize frequencyForApi with the current value of frequency
        if (getWeatherVariable === "Vegetation") {
          // Update frequencyForApi based on the value of frequency
          if (frequency === "10 day") {
            frequencyForApi = "10 day";
          } else if (frequency === "5 day") {
            frequencyForApi = "5 day";
          }
        }
  
        const heatMapResponse = await fetch(`/api/compare/heatMap?country=${props.countryName}&start_month=${getStartMonth}&end_month=${getEndMonth}&frequency=${frequencyForApi}&weather=${getWeatherVariable}&level1_name=${stateName}&level2_name=${districtName}`);
        const heatMapData = await heatMapResponse.json();
        setHeatmapData(heatMapData);
  
        const latencyTableResponse = await fetch(`/api/compare/datasetLatencyTable?country=${props.countryName}&frequency=${frequency}&start_month=${getStartMonth}&end_month=${getEndMonth}&weather=${getWeatherVariable}`);
        const latencyTableData = await latencyTableResponse.json();
        setLatencyTableData(latencyTableData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    }
  
    genToken();
  }, [compareState]);
  
  return (
    <>
      <Card className="shadow" style={{ width: '68vw',marginBottom:'20px' }}>
        <CardBody style={{ width: '65vw' }} >
          <CardTitle
            style={{ textAlign: "left" }}
            className="card-title-prices"
          >
            <div style={{ display: 'flex', flexDirection: 'row' ,justifyContent:'space-between',marginBottom:'30px'}}>
              <div style={{ textAlign: "left", display: 'flex',marginRight:'23vw' }}>
              {selectedButton === "Good Data Percentage" ? "% Share of good data days in total days" : "Latency  and good data length table"}
            
              </div>
              <div style={{display: 'flex'}}>
              <div style={{ marginLeft: "auto", border: '1px solid #DDDDE3', borderRadius: '3.5px' }}>
                <button
                  className="evalutionbuttons"
                  style={{ backgroundColor: selectedButton === "Good Data Percentage" ? "rgba(255, 108, 3, 0.09)" : "", color: selectedButton === "Good Data Percentage" ? "#F76808" : "" }}
                  onClick={() => handleTempButtonClick("Good Data Percentage")}
                >
                  Good Data Percentage
                </button>
                <button
                  className="evalutionbuttons"
                  style={{ backgroundColor: selectedButton === "Latency and Length" ? "rgba(255, 108, 3, 0.09)" : "", color: selectedButton === "Latency and Length" ? "#F76808" : "" }}
                  onClick={() => handleTempButtonClick("Latency and Length")}
                >
                  Latency and Length
                </button>
                </div>
             
                </div>
            </div>
            {selectedButton === "Good Data Percentage" && <div> 
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
             <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
          </Box>
          ) : (
            <HeatmapComponent data={HeatmapData} />
          )}
          <div className="bottomtext">
            Values displayed correspond to the ratio of number of good days/ total number of days, for each available dataset for last 10 years.</div></div>}
            {selectedButton === "Latency and Length" && <div >
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
             <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
          </Box>
          ) : (
            <LatencyTable data={latencyTableData}/>
          )}
              <div className="bottomtext">Length refers to the total number of good data days in the selected time period.</div>
              </div>}
          </CardTitle>
         
        </CardBody>
      </Card>
      <Card className="shadow" style={{ width: '68vw',marginBottom:'20px'  }}>
        <CardBody  style={{ width: '65vw'  }}>
          <CardTitle
            style={{ textAlign: "left" }}
            className="card-title-prices"
          >
            <div style={{ display: 'flex', flexDirection: 'row' ,justifyContent:'space-between',marginBottom:'30px'}}>
              <div style={{ textAlign: "left", display: 'flex',marginRight: getWeatherVariable === 'Rainfall' ? '31vw' : '24vw'}}>
              Compare all datasets for {getWeatherVariable}
              </div>
              <div style={{display: 'flex'}}>
              <div style={{ marginLeft: "auto", border: '1px solid #DDDDE3', borderRadius: '3.5px' }}>
                <button
                  className="evalutionbuttons"
                  style={{ backgroundColor: comparedatasetButton === "Min-Max " ? "rgba(255, 108, 3, 0.09)" : "", color: comparedatasetButton === "Min-Max " ? "#F76808" : "" }}
                  onClick={() => handlecompareButtonClick("Min-Max ")}
                >
                  Min-Max {getWeatherVariable}
                </button>
                <button
                  className="evalutionbuttons"
                  style={{ backgroundColor: comparedatasetButton === "Average" ? "rgba(255, 108, 3, 0.09)" : "", color: comparedatasetButton === "Average" ? "#F76808" : "" }}
                  onClick={() => handlecompareButtonClick("Average")}
                >
                 Average {getWeatherVariable}
                </button>
                </div>
                {/* <button style={{ marginLeft: "10px" ,background:'none',border:'1px solid #F76808', borderRadius:'3.5px'}}>
                  <img
                    src="selecteddownload.png"
                    alt="Download"
                    style={{ height: '20px', width: '20px' }}
                  />
                </button> */}
                </div>
            </div>
            {comparedatasetButton === "Min-Max " && <div style={{marginBottom:'30px'}}> <AccordionComponent
            countryName={props.countryName}
            setCountryName={props.setCountryName}
            stateName={stateName}
            districtName={districtName}
            getStartMonth={getStartMonth}
            getStartYears={getStartYears}
            getEndYears={getEndYears}
            getEndMonth={getEndMonth}
            frequency={frequency}
            RainfallDataset={RainfallDataset}
            /></div>}
            {comparedatasetButton === "Average" && <div style={{marginBottom:'30px'}}>
              <AccordionComponentAvg
            countryName={props.countryName}
            setCountryName={props.setCountryName}
            stateName={stateName}
            districtName={districtName}
            getStartMonth={getStartMonth}
            getStartYears={getStartYears}
            getEndYears={getEndYears}
            getEndMonth={getEndMonth}
            frequency={frequency}
            RainfallDataset={RainfallDataset}
            /></div>}
          </CardTitle>
        </CardBody>
      </Card>
    </>
  );
};

export default Evaluation;
