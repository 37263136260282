import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

const DoublevegetationGraph = ({ entry1, entry2 }) => {
  useEffect(() => {
    // Filter out entries with NaN values
    const filteredEntry1 = entry1.filter(item => !isNaN(item.ndvi));
    const filteredEntry2 = entry2.filter(item => !isNaN(item.ndvi));

    if (filteredEntry1.length > 0 && filteredEntry2.length > 0) {
      const chartOptions = {
        chart: {
          zoomType: 'x', 
        },
        credits:false,
        title: {
          text: '',
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: '',
          },
        },
        yAxis: {
          title: {
            text: 'NDVI',
          },
        },
        tooltip: {
          formatter: function () {
            return '<b>' + Highcharts.dateFormat('%B %e', new Date(this.x)) + '</b><br/>' +
                   this.series.name + ': ' + this.y;
          }
        },
        series: [{
          name: 'Current',
          type: 'area', // Changed to area graph
          data: filteredEntry1.map(item => [new Date(item.dateString).getTime(), item.ndvi]),
         
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            opacity: 0.2,
            stops: [
              [0, '#EBBC00'], // start color
              [1, 'rgba(230, 184, 82, 0.40)'] // end color
            ]
          },
         
        }, {
          name: 'Historical',
          type: 'area', // Changed to area graph
          data: filteredEntry2.map(item => [new Date(item.dateString).getTime(), item.ndvi]),
         
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            opacity: 0.2,
            stops: [
              [0, '#01619E'], // start color
              [1, 'rgba(1, 97, 158, 0.30)'] // end color
            ]
          },
        }],
      };

      Highcharts.chart('double-area-veg-chart', chartOptions);
    }
    else
    {
        <div>No Data Available for this Dataset</div>
    }
  }, [entry1, entry2]);

  return <div id="double-area-veg-chart" />;
};

export default DoublevegetationGraph;
