import React, { useState } from "react";
import { useMap } from "../../../hooks/useMap";
import { getLayerByName } from "../../../lib/gis";
import { OSM, XYZ } from "ol/source";

function ChangeBaseMap() {
  const { map } = useMap();
  const [isChecked, setIsChecked] = useState(false);

  const changeMap = () => {
    if (!isChecked) {
      const mapTilerSource = new XYZ({
        url: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
      });
      let baseMap = getLayerByName(map, "baseMapLayer");
      baseMap.setSource(mapTilerSource);
      setIsChecked(!isChecked);
    } else {
      const mapTilerSource = new OSM();
      let baseMap = getLayerByName(map, "baseMapLayer");
      baseMap.setSource(mapTilerSource);
      setIsChecked(!isChecked);
    }
  };

  return (
    <button className="changeBaseMap" onClick={() => changeMap(!isChecked)}>
      {!isChecked ? (
        <img src="/sat.png" alt="Satellite" />
      ) : (
        <img src="/osm.png" alt="OSM" />
      )}
    </button>
  );
}

export default ChangeBaseMap;
