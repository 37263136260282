import React, { useState, useEffect } from "react";
import Select from "react-select";
import "../../styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardTitle, CardBody } from "reactstrap";
import "../../analytics.css";
import { changeCombination, changeRainfallDataset, changeTempDataset, changeVegetationDataset } from "../../redux/action";
//import DoubleAreaGraph from "./DoubleAreaGraph";
import DoubleGraph from "./DoubleGraph";
import DualYAxesLineGraph from "./DualYAxesLineGraph";
import DoubleGraphTemp from "./DoubleGraphTemp";
import CircularProgress from '@mui/material/CircularProgress';
//import Box from '@mui/material/Box';
import DoublegraphMonthly from "./DoublegraphMonthly";
import DoubleGraphTempMonthly from "./DoubleGraphTempMonthly";
import DoublevegetationGraph from "./DoublevegetationGraph";
import DualNdviTempGraph from "./DualNdviTempGraph";
import DualNdviRainGraph from "./DualNdviRainGraph";
// import Corelationchart from "./Corelationchart";


const Interrelation = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showTemperatureDataset, setShowTemperatureDataset] = useState(true);
  const [showRainfallDataset, setShowRainfallDataset] = useState(true);
  const [showVegetationDataset, setShowVegetationDataset] = useState(false);
  const [combinationData, setcombinationData] = useState([]);
  const combination = useSelector((state) => state.combination);
  const [TempDatasetData, setTempDatasetData] = useState([]);
  const TempDataset = useSelector((state) => state.TempDataset);
  const [RainfallDatasetData, setRainfallDatasetData] = useState([]);
  const RainfallDataset = useSelector((state) => state.RainfallDataset);
  const [VegetationDatasetData, setVegetationDatasetData] = useState([]);
  const VegetationDataset = useSelector((state) => state.VegetationDataset);
  // const [tempdatasetDisabled, settempdatasetDisabled] = useState(true);
  // const [rainfallDatasetDisabled, setrainfallDatasetDisabled] = useState(true);
  const [tempCorelation, settempCorelation] = useState(null);
  const [ndvitempCorelation, setndvitempCorelation] = useState(null);
  const [ndvirainCorelation, setndvirainCorelation] = useState(null);
  const [rainCorelation, setrainCorelation] = useState(null);
  const [DailyGraph, setDailyGraph] = useState([]);
  const [HistoricalGraph, setHistoricalGraph] = useState([]);
  const [DailyTempGraph, setDailyTempGraph] = useState([]);
  const [MonthlyTempGraph, setMonthlyTempGraph] = useState([]);
  const [HistoricalTempGraph, setHistoricalTempGraph] = useState([]);
  const [HistoricalMonthlyTempGraph, setHistoricalMonthlyTempGraph] = useState([]);
  const [CurrentVegetationgraph, setCurrentVegetationgraph] = useState([]);
  const [HistoricalVegetationgraph, setHistoricalVegetationgraph] = useState([]);
  const frequency = useSelector((state) => state.frequency);
  const stateName = useSelector((state) => state.stateName);
  const districtName = useSelector((state) => state.districtName);
  const getStartYears = useSelector((state) => state.getStartYears);
  const getStartMonth = useSelector((state) => state.getStartMonth);
  const getEndMonth = useSelector((state) => state.getEndMonth);
  const getEndYears = useSelector((state) => state.getEndYears);
  const [correlationscoreTemprain, setcorrelationscoreTemprain] = useState(null);
  const [correlationscoreNdvirain, setcorrelationscoreNdvirain] = useState(null);
  const [correlationscoreNdviTemp, setcorrelationscoreNdviTemp] = useState(null);
  const [dataset, setDataset] = useState("");
  const [loading, setLoading] = useState(false);
  const [correlationgraphloading, setcorrelationgraphloading] = useState(false);
  const [tempgraphloading, settempgraphloading] = useState(false);
  const [veggraphloading, setveggraphloading] = useState(false);
  const [raingraphloading, setraingraphloading] = useState(false);
  const [message, setMessage] = useState("Select Combination and Datasets");
  const getWeatherVariable = useSelector((state) => state.getWeatherVariable);
  const dispatch = useDispatch();


  const getMonthsBetween = (startMonthStr, endMonthStr) => {
    const startMonth = new Date('2000-' + startMonthStr + '-01');
    const endMonth = new Date('2000-' + endMonthStr + '-01');
    const months = [];
    for (let month = startMonth.getMonth(); month <= endMonth.getMonth(); month++) {
      const monthString = new Date(2000, month).toLocaleString('default', { month: 'long' });
      months.push(monthString);
    }

    return months;
  };
 
useEffect(() => {
  if(getWeatherVariable == "Temperature" ||getWeatherVariable == "Rainfall") 
  {
    if (frequency === "Daily"  && RainfallDataset === "Arc2") {
      setDataset("arc2Daily");
    } else if (frequency === "Daily" && RainfallDataset === "Tamsat") {
      setDataset("tamsatDaily");
    } else if (frequency === "Daily" && RainfallDataset === "Era5") {
      setDataset("era5DailyRainfall");

    } else if (frequency === "Monthly" && RainfallDataset === "Era5") {
      setDataset("era5MonthlyRainfall");

    } else if (frequency === "Monthly"  && RainfallDataset === "Arc2") {
      setDataset("arc2Monthly");
    } else if (frequency === "Monthly" && RainfallDataset === "Chirps") {
      setDataset("chirpsMonthly");
    } else if (frequency === "Monthly" && RainfallDataset === "Tamsat") {
      setDataset("tamsatMonthly");
    } else if (frequency === "Daily" && RainfallDataset === "Chirps") {
      setDataset("chirpsDaily");
    }
    else if (frequency === "Daily"  && RainfallDataset === "Gsmap") {
      setDataset("gsMapDaily");
    }
    else if (frequency === "Monthly" && RainfallDataset === "Gsmap") {
      setDataset("gsMapMonthly");
    }
    else {
      setDataset("");
    }
  }
  else
  {
    if (RainfallDataset === "Arc2") {
      setDataset("arc2Daily");
    } else if (RainfallDataset === "Tamsat") {
      setDataset("tamsatDaily");
    } else if (RainfallDataset === "Era5") {
      setDataset("era5DailyRainfall");
    } else if ( RainfallDataset === "Chirps") {
      setDataset("chirpsDaily");
    }
    else if (RainfallDataset === "Gsmap") {
      setDataset("gsMapDaily");
    }
    else {
      setDataset("");
    }
  }
  
},[frequency,RainfallDataset])
useEffect(() => {
  async function fetchData() {
    try {

      const combinationResponse = await fetch("/api/compare/selectCombination?weather_variable=" + getWeatherVariable);
      const combinationData = await combinationResponse.json();
      setcombinationData(combinationData);
      if (combination !== null) {
              fetch("/api/compare/selectDatasetTemperature?country=" +props.countryName+"&frequency="+frequency, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              })
  
                .then((response) => response.json())
                .then((data) => {
                  setTempDatasetData(data);
                  // settempdatasetDisabled(false)
                });
              fetch("/api/compare/selectDatasetRainfall?country=" +props.countryName+"&frequency="+frequency, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              })
  
                .then((response) => response.json())
                .then((data) => {
                    setRainfallDatasetData(data);
                    // settempdatasetDisabled(false)
                  });
              fetch("/api/compare/selectDatasetVegetation?country=" +props.countryName , {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              })
  
                .then((response) => response.json())
                .then((data) => {
                  setVegetationDatasetData(data);
                  // setrainfallDatasetDisabled(false)
                })
      }
      }
      catch (error) {
        console.error("Error fetching data:", error);
        setMessage("No Data available");
      }
    }

    fetchData();
  }, [props.countryName, stateName, districtName, getStartYears, getEndYears, getStartMonth, getEndMonth, RainfallDataset, TempDataset, frequency, dataset, VegetationDataset, getWeatherVariable, selectedOption]);


  useEffect(() => {
    async function genToken() {
      if (selectedOption && (RainfallDataset || TempDataset || VegetationDataset)) {
        if (showRainfallDataset) {
      try {
        let graphType = '';
        if ((frequency === 'Daily' && getWeatherVariable === "Rainfall") || (frequency === 'Daily' && getWeatherVariable === "Temperature") || frequency === '10 day' || frequency === '5 day') {
          graphType = 'DailyGraph';
        } else if (frequency === 'Monthly') {
          graphType = 'MonthlyGraph';
        }
        // API call 1: Current Daily Graph
        setraingraphloading(true); 
        const currentGraphResponse = await fetch(
          `api/compare/get${graphType}?country=${props.countryName}` +
          `&level1=${stateName}` +
          `&level2=${districtName}` +
          `&start_year=${getStartYears}` +
          `&end_year=${getEndYears}` +
          `&start_month=${getStartMonth}` +
          `&end_month=${getEndMonth}` +
          `&graphType=Current` +
          `&dataset=${dataset}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // Authorization: jwtToken,
            },
          }
        );
        const currentGraphData = await currentGraphResponse.json();
        setDailyGraph(currentGraphData);

            // API call 2: Historical Daily Graph
            const historicalGraphResponse = await fetch(
              `api/compare/get${graphType}?country=${props.countryName}` +
              `&level1=${stateName}` +
              `&level2=${districtName}` +
              `&start_year=${getStartYears}` +
              `&end_year=${getEndYears}` +
              `&start_month=${getStartMonth}` +
              `&end_month=${getEndMonth}` +
              `&graphType=Historical` +
              `&dataset=${dataset}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              }
            );
            const historicalGraphData = await historicalGraphResponse.json();
            setHistoricalGraph(historicalGraphData);

          } catch (error) {
            console.error("Error fetching data:", error);
            setDailyGraph(null);
            setHistoricalGraph(null);
            setMessage("No Data available")
          } finally {
            setraingraphloading(false);
          }
        }
        try {
        if ((showTemperatureDataset && (getWeatherVariable === "Rainfall" || getWeatherVariable === "Temperature") && frequency === "Daily") || ((showTemperatureDataset && getWeatherVariable === "Vegetation"))) {
          settempgraphloading(true); 
        const currentTempGraphResponse = await fetch(
          `api/compare/getDailyGraph?country=${props.countryName}` +
          `&level1=${stateName}` +
          `&level2=${districtName}` +
          `&start_year=${getStartYears}` +
          `&end_year=${getEndYears}` +
          `&start_month=${getStartMonth}` +
          `&end_month=${getEndMonth}` +
          `&graphType=Current` +
          `&dataset=era5DailyTemperature`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // Authorization: jwtToken,
            },
          }
        );
        const currentTempGraphData = await currentTempGraphResponse.json();
        setDailyTempGraph(currentTempGraphData);

            // API call 4: Historical Daily Temperature Graph
            const historicalTempGraphResponse = await fetch(
              `api/compare/getDailyGraph?country=${props.countryName}` +
              `&level1=${stateName}` +
              `&level2=${districtName}` +
              `&start_year=${getStartYears}` +
              `&end_year=${getEndYears}` +
              `&start_month=${getStartMonth}` +
              `&end_month=${getEndMonth}` +
              `&graphType=Historical` +
              `&dataset=era5DailyTemperature`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              }
            );
            const historicalTempGraphData = await historicalTempGraphResponse.json();
            setHistoricalTempGraph(historicalTempGraphData);
          }
        }
        catch (error) {
          console.error("Error fetching data:", error);
          setDailyTempGraph(null);
          setHistoricalTempGraph(null);
          setMessage("No Data available")
        } finally {
          settempgraphloading(false);
        }
        if (showTemperatureDataset && frequency == "Monthly") {

          try {
            settempgraphloading(true);
            const currentMonthlyTempGraphResponse = await fetch(
              `api/compare/getMonthlyGraph?country=${props.countryName}` +
              `&level1=${stateName}` +
              `&level2=${districtName}` +
              `&start_year=${getStartYears}` +
              `&end_year=${getEndYears}` +
              `&start_month=${getStartMonth}` +
              `&end_month=${getEndMonth}` +
              `&graphType=Current` +
              `&dataset=era5MonthlyTemperature`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              }
            );
            const currentMonthlyTempGraphData = await currentMonthlyTempGraphResponse.json();
            setMonthlyTempGraph(currentMonthlyTempGraphData);


            // API call 5: Historical Daily Temperature Graph

            const historicalMonthlyTempGraphResponse = await fetch(
              `api/compare/getMonthlyGraph?country=${props.countryName}` +
              `&level1=${stateName}` +
              `&level2=${districtName}` +
              `&start_year=${getStartYears}` +
              `&end_year=${getEndYears}` +
              `&start_month=${getStartMonth}` +
              `&end_month=${getEndMonth}` +
              `&graphType=Historical` +
              `&dataset=era5MonthlyTemperature`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              }
            );
            const historicalMonthlyTempGraphData = await historicalMonthlyTempGraphResponse.json();
            setHistoricalMonthlyTempGraph(historicalMonthlyTempGraphData);
            // Additional API calls...

      } 
      catch (error) {
      console.error("Error fetching data:", error);
      setMonthlyTempGraph(null);
      setHistoricalMonthlyTempGraph(null);
      setMessage("No Data available")
      } finally {
        settempgraphloading(false); 
      }
    }
      let datasetForCorrelation;
      let veginput;
      let tempdatasetForCorrelation;
      if(getWeatherVariable == "Temperature" ||getWeatherVariable == "Rainfall") 
      {
        if (frequency === "Daily"  && RainfallDataset === "Arc2") {
          datasetForCorrelation = "arc2Daily";
        } else if (frequency === "Daily" && RainfallDataset === "Tamsat") {
          datasetForCorrelation = "tamsatDaily";
        } else if (frequency === "Daily" && RainfallDataset === "Era5") {
          datasetForCorrelation = "era5DailyRainfall";
        } else if (frequency === "Monthly" && RainfallDataset === "Era5") {
          datasetForCorrelation = "era5MonthlyRainfall";
        } else if (frequency === "Monthly"  && RainfallDataset === "Arc2") {
          datasetForCorrelation = "arc2Monthly";
        } else if (frequency === "Monthly" && RainfallDataset === "Chirps") {
          datasetForCorrelation = "chirpsMonthly";
        } else if (frequency === "Monthly" && RainfallDataset === "Tamsat") {
          datasetForCorrelation = "tamsatMonthly";
        } else if (frequency === "Daily" && RainfallDataset === "Chirps") {
          datasetForCorrelation = "chirpsDaily";
        }
        else if (frequency === "Daily"  && RainfallDataset === "Gsmap") {
          datasetForCorrelation = "gsMapDaily";
        }
        else if (frequency === "Monthly" && RainfallDataset === "Gsmap") {
          datasetForCorrelation = "gsMapMonthly";
        }
        else {
          datasetForCorrelation = "";
        }
      }
      else
      {
        if (RainfallDataset === "Arc2") {
          datasetForCorrelation = "arc2Daily";
        } else if (RainfallDataset === "Tamsat") {
          datasetForCorrelation = "tamsatDaily";
        } else if (RainfallDataset === "Era5") {
          datasetForCorrelation = "era5DailyRainfall";
        } else if ( RainfallDataset === "Chirps") {
          datasetForCorrelation = "chirpsDaily";
        }
        else if (RainfallDataset === "Gsmap") {
          datasetForCorrelation = "gsMapDaily";
        }
        else {
          datasetForCorrelation = "";
        }
      }
      
      if (VegetationDataset === "Modis") {
        veginput = "modisNDVI";
      }  else if (VegetationDataset === "Sentinel-2") {
        veginput = "sentinel2NDVI";
      }
      if(getWeatherVariable == "Temperature" ||getWeatherVariable == "Rainfall")
     {
      if (frequency === "Daily"){
        tempdatasetForCorrelation = "era5DailyTemperature";
      } else {
        tempdatasetForCorrelation = "era5MonthlyTemperature";
      }

     }
     else
     {
        tempdatasetForCorrelation = "era5DailyTemperature";
     }

      
      try {
        // API call for vegetation correlation if showVegetationDataset is true
        if (showRainfallDataset && showTemperatureDataset) {
          setcorrelationgraphloading(true); 
          await fetch(
            "api/compare/getCorrelationValues?country=" +
              props.countryName +
              "&level1=" +
              stateName +
              "&level2=" +
              districtName +
              "&start_year=" +
              getStartYears +
              "&end_year=" +
              getEndYears +
              "&start_month=" +
              getStartMonth +
              "&end_month=" +
              getEndMonth +
              "&graphType=Historical" +
              "&dataset=" +
              datasetForCorrelation,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setrainCorelation(data);
              });
          }
          if (showTemperatureDataset && showRainfallDataset) {
            setcorrelationgraphloading(true);
            await fetch(
              "api/compare/getCorrelationValues?country=" +
              props.countryName +
              "&level1=" +
              stateName +
              "&level2=" +
              districtName +
              "&start_year=" +
              getStartYears +
              "&end_year=" +
              getEndYears +
              "&start_month=" +
              getStartMonth +
              "&end_month=" +
              getEndMonth +
              "&graphType=Historical" +
              "&dataset=" +
              tempdatasetForCorrelation,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                settempCorelation(data);
              });
          }
          if (showVegetationDataset && showTemperatureDataset) {
            setcorrelationgraphloading(true);
            await fetch(
              "api/compare/datasetVegetationCorrelation?country=" +
              props.countryName +
              "&level1=" +
              stateName +
              "&level2=" +
              districtName +
              "&start_year=" +
              getStartYears +
              "&end_year=" +
              getEndYears +
              "&start_month=" +
              getStartMonth +
              "&end_month=" +
              getEndMonth +
              "&graphType=Historical" +
              "&dataset=" + tempdatasetForCorrelation +
              "&ndvi_dataset=" + veginput,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setndvitempCorelation(data);
              });
          }
          if (showVegetationDataset && showRainfallDataset) {
            setcorrelationgraphloading(true);
            await fetch(
              "api/compare/datasetVegetationCorrelation?country=" +
              props.countryName +
              "&level1=" +
              stateName +
              "&level2=" +
              districtName +
              "&start_year=" +
              getStartYears +
              "&end_year=" +
              getEndYears +
              "&start_month=" +
              getStartMonth +
              "&end_month=" +
              getEndMonth +
              "&graphType=Historical" +
              "&dataset=" +
              datasetForCorrelation +
              "&ndvi_dataset=" + veginput,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setndvirainCorelation(data);
              });
          }

        } catch (error) {
          console.error("Error fetching data:", error);
          setMessage("No Data available");
          setrainCorelation(null);
          setndvirainCorelation(null);
          setndvitempCorelation(null);
          settempCorelation(null);
        } finally {
          setcorrelationgraphloading(false);
        }
        if (showVegetationDataset) {
          setveggraphloading(true);
          try {
            await fetch(
              `api/compare/getVegetationGraph?country=${props.countryName}` +
              `&level1=${stateName}` +
              `&level2=${districtName}` +
              `&start_year=${getStartYears}` +
              `&end_year=${getEndYears}` +
              `&start_month=${getStartMonth}` +
              `&end_month=${getEndMonth}` +
              `&graphType=Historical` +
              `&dataset=${veginput}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setHistoricalVegetationgraph(data);
              })
              .catch((e) => console.log("The historical vegetation graph data was not found.", e));
            await fetch(
              `api/compare/getVegetationGraph?country=${props.countryName}` +
              `&level1=${stateName}` +
              `&level2=${districtName}` +
              `&start_year=${getStartYears}` +
              `&end_year=${getEndYears}` +
              `&start_month=${getStartMonth}` +
              `&end_month=${getEndMonth}` +
              `&graphType=Current` +
              `&dataset=${veginput}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setCurrentVegetationgraph(data)
              });

          } catch (error) {
            console.error("Error fetching data in veg graph:", error);
            setMessage("No Data available")
            setHistoricalVegetationgraph(null);
            setCurrentVegetationgraph(null);
          } finally {
            setveggraphloading(false);
          }
        }
        if (showTemperatureDataset && showRainfallDataset) {
          try {
            setLoading(true)
            fetch("api/compare/getCorrelationScore?country=" + props.countryName +
              "&level1=" + stateName +
              "&level2=" + districtName +
              "&start_year=" + getStartYears +
              "&end_year=" + getEndYears +
              "&start_month=" + getStartMonth +
              "&end_month=" + getEndMonth +
              "&graphType=Historical" +
              "&rainfall_dataset=" + datasetForCorrelation +
              "&temperature_dataset=" + tempdatasetForCorrelation, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                // Authorization: jwtToken,
              },
            })

              .then((response) => response.json())
              .then((data) => {
                setcorrelationscoreTemprain(data.correlation);
              });
          }
          catch (error) {
            console.error("Error fetching data in veg graph:", error);
            setMessage("No Data available")
            setcorrelationscoreTemprain(null);
          } finally {
            setLoading(false);
          }
        }

        if (showVegetationDataset && showRainfallDataset) {
          try {
            setLoading(true)
            fetch("api/compare/getDatasetVegetationCorrelationScore?country=" + props.countryName +
              "&level1=" + stateName +
              "&level2=" + districtName +
              "&start_year=" + getStartYears +
              "&end_year=" + getEndYears +
              "&start_month=" + getStartMonth +
              "&end_month=" + getEndMonth +
              "&graphType=Historical" +
              "&rainfall_dataset=" + datasetForCorrelation +
              "&ndvi_dataset=" + veginput, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                // Authorization: jwtToken,
              },
            })

              .then((response) => response.json())
              .then((data) => {
                setcorrelationscoreNdvirain(data.correlation);
              });
          }
          catch (error) {
            console.error("Error fetching data in veg graph:", error);
            setMessage("No Data available")
            setcorrelationscoreNdvirain(null);
          } finally {
            setLoading(false);
          }
        }
        if (showVegetationDataset && showTemperatureDataset) {
          try {
            setLoading(true)
            fetch("api/compare/getDatasetVegetationCorrelationScore?country=" + props.countryName +
              "&level1=" + stateName +
              "&level2=" + districtName +
              "&start_year=" + getStartYears +
              "&end_year=" + getEndYears +
              "&start_month=" + getStartMonth +
              "&end_month=" + getEndMonth +
              "&graphType=Historical" +
              "&ndvi_dataset=" + veginput +
              "&temperature_dataset=" + tempdatasetForCorrelation, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                // Authorization: jwtToken,
              },
            })

              .then((response) => response.json())
              .then((data) => {
                setcorrelationscoreNdviTemp(data.correlation);
              });
          }
          catch (error) {
            console.error("Error fetching data in veg graph:", error);
            setMessage("No Data available")
            setcorrelationscoreNdviTemp(null);
          } finally {
            setLoading(false);
          }
        }
      }
    }
    genToken();

  }, [props.countryName, stateName, districtName, getStartYears, getEndYears, getStartMonth, getEndMonth, VegetationDataset, RainfallDataset, TempDataset, frequency, dataset]);


  let combinationObject = [];
  let TempDatasetObject = [];
  let RainfallDatasetObject = [];
  let VegetationDatasetObject = [];
  for (let j in combinationData) {
    let items = {};
    items["label"] = combinationData[j];
    items["value"] = combinationData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    combinationObject.push(json_obj);
  }
  for (let j in TempDatasetData) {
    let items = {};
    items["label"] = TempDatasetData[j];
    items["value"] = TempDatasetData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    TempDatasetObject.push(json_obj);
  }
  for (let j in RainfallDatasetData) {
    let items = {};
    items["label"] = RainfallDatasetData[j];
    items["value"] = RainfallDatasetData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    RainfallDatasetObject.push(json_obj);
  }
  for (let j in VegetationDatasetData) {
    let items = {};
    items["label"] = VegetationDatasetData[j];
    items["value"] = VegetationDatasetData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    VegetationDatasetObject.push(json_obj);
  }
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // Reset dataset selection dropdowns
    setShowTemperatureDataset(false);
    setShowRainfallDataset(false);
    setShowVegetationDataset(false);
    setcorrelationgraphloading(true);
    setraingraphloading(true);
    settempgraphloading(true);
    setveggraphloading(true);
    setLoading(true);
    setcorrelationgraphloading(true);
    // Check the selected combination and set the corresponding state variables
    if (selectedOption && selectedOption == 'Temperature - Rainfall' || selectedOption == 'Rainfall - Temperature') {
      setShowTemperatureDataset(true);
      setShowRainfallDataset(true);
      setShowVegetationDataset(false);


    } else if (selectedOption && selectedOption == 'Temperature - Vegetation' || selectedOption == 'Vegetation - Temperature') {
      setShowTemperatureDataset(true);
      setShowVegetationDataset(true);
      setShowRainfallDataset(false);

    }
    else if (selectedOption && selectedOption == 'Rainfall - Vegetation' || selectedOption == 'Vegetation - Rainfall') {
      setShowRainfallDataset(true);
      setShowVegetationDataset(true);
      setShowTemperatureDataset(false);
    }

  };
  return (
    <>

      <div style={{ width: "68vw" }}>
        <div className="dropdownlabel">
          Select Weather Variable Combination
        </div>
        <div className="download-container" >
          <Select
            className="custom-select"
            value={{
              value: combination,
              label: combination ? combination : "Select Combination",
            }}
            onChange={(obj) => {
              dispatch(changeCombination(obj["value"]));
              handleChange(obj["value"])

            }}
            options={combinationObject}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {showTemperatureDataset && (
            <div className="dropdownlabel">Select Dataset for Temperature
              <div className="download-container" style={{ width: '460px', marginTop: '10px' }} >
                <Select
                  className="custom-select"
                  value={{
                    value: TempDataset,
                    label: TempDataset ? TempDataset : "Select",
                  }}
                  onChange={(obj) => {
                    dispatch(changeTempDataset(obj["value"]));
                    settempgraphloading(true);
                    setcorrelationgraphloading(true);
                    setcorrelationscoreTemprain(null);
                    setcorrelationscoreNdvirain(null);
                    setcorrelationscoreNdviTemp(null)
                  }}
                  options={TempDatasetObject}
                // isDisabled={tempdatasetDisabled}
                />
              </div>
            </div>
          )}

          {showRainfallDataset && (
            <div className="dropdownlabel">Select Dataset for Rainfall
              <div className="download-container" style={{ width: '460px', marginTop: '10px' }} >
                <Select
                  className="custom-select"
                  value={{
                    value: RainfallDataset,
                    label: RainfallDataset ? RainfallDataset : "Select ",
                  }}
                  onChange={(obj) => {
                    dispatch(changeRainfallDataset(obj["value"]));
                    setcorrelationgraphloading(true);
                    setraingraphloading(true);
                    setcorrelationscoreTemprain(null);
                    setcorrelationscoreNdvirain(null);
                    setcorrelationscoreNdviTemp(null)
                  }}
                  options={RainfallDatasetObject}
                // isDisabled={rainfallDatasetDisabled}
                />
              </div>
            </div>
          )}
          {showVegetationDataset && (
            <div className="dropdownlabel">Select Dataset for Vegetation
              <div className="download-container" style={{ width: '460px', marginTop: '10px' }} >
                <Select
                  className="custom-select"
                  value={{
                    value: VegetationDataset,
                    label: VegetationDataset ? VegetationDataset : "Select",
                  }}
                  onChange={(obj) => {
                    dispatch(changeVegetationDataset(obj["value"]));
                    setveggraphloading(true);
                    setcorrelationgraphloading(true);
                    setcorrelationscoreTemprain(null);
                    setcorrelationscoreNdvirain(null);
                    setcorrelationscoreNdviTemp(null);
                  }}
                  options={VegetationDatasetObject}
                />
              </div>
            </div>
          )}
        </div>
        {(selectedOption != null && (RainfallDataset && VegetationDataset || VegetationDataset && TempDataset || RainfallDataset && TempDataset) != null) ?
          <>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Card className="shadow" style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>

                {showRainfallDataset && showTemperatureDataset && (
                  <>
                    <CardTitle >
                      Correlation between Datasets  {" "}

                      {/* {!loading && correlationscoreTemprain ? <button> {correlationscoreTemprain}</button> : <button>Loading..</button>} */}

          </CardTitle>
          <div style={{ width: '100%' }}>
          {correlationgraphloading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
              <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }} />
            </div>
          ) : rainCorelation && tempCorelation ? (
            <DualYAxesLineGraph data1={rainCorelation} data2={tempCorelation} months={getMonthsBetween(getStartMonth, getEndMonth)} />
          ) : (
            <div>No data available</div>
          )}
        </div>
        </>)}
          {showRainfallDataset && showVegetationDataset && (  
            <>
            <CardTitle >
            Correlation between Datasets  {" "}
            {/* {correlationscoreNdvirain ? <button>{correlationscoreNdvirain}</button> : null} */}
          </CardTitle>
          <div style={{ width: '100%' }}>
          {correlationgraphloading  && !ndvirainCorelation? 
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
              <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }} />
            </div>
            :
            ('')}
         {ndvirainCorelation && !correlationgraphloading ?
            <DualNdviRainGraph data1={ndvirainCorelation} months={getMonthsBetween(getStartMonth, getEndMonth)} />
         :
         ('')}
           {!ndvirainCorelation && !correlationgraphloading ?
            <div>No data available</div>
            :
            ('')
           }
        </div>
      </>)}
   {showTemperatureDataset && showVegetationDataset && (  
          <>
          <CardTitle >
          Correlation between Datasets  {" "}
          {/* {correlationscoreNdviTemp!=null ? <button>{correlationscoreNdviTemp}</button> : null} */}
          
        </CardTitle>
        <div style={{ width: '100%' }}>
          {correlationgraphloading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
              <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }} />
            </div>
          ) : ndvitempCorelation ? (
            <DualNdviTempGraph data1={ndvitempCorelation} months={getMonthsBetween(getStartMonth, getEndMonth)} />
          ) : (
            <div>No data available</div>
          )}
        </div>
      </>)}
       
        </Card>
      </div>
  
      {showRainfallDataset && (  
        <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Card className="shadow" style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
  <CardTitle>
    Historical Average vs Current Value - {RainfallDataset}
  </CardTitle>
  {(frequency === "Daily" && getWeatherVariable == "Rainfall") ||(frequency === "Daily" && getWeatherVariable == "Temperature" )|| (frequency == '5 day' || frequency == '10 day') ? 
   <div style={{ width: '100%' }}>
   {!raingraphloading ? (
     DailyGraph && HistoricalGraph ? (
       <DoubleGraph data1={DailyGraph} data2={HistoricalGraph} />
     ) : (
       <div>No data available</div>
     )
   ) : (
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
       <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
     </div>
   )}
 </div> :
     <div style={{ width: '100%' }}>
     {!raingraphloading ? (
       DailyGraph && HistoricalGraph ? (
         <DoublegraphMonthly data1={DailyGraph} data2={HistoricalGraph} />
       ) : (
         <div>No data available</div>
       )
     ) : (
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
         <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
       </div>
     )}
   </div>}
</Card>
      </div>
      </>)
      }
    {showVegetationDataset && (  
  <>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Card className="shadow" style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
        <CardTitle >
          Historical Average vs Current Value - {VegetationDataset}
        </CardTitle>

                    <div style={{ width: '100%' }}>
                      {!veggraphloading ? (
                        CurrentVegetationgraph && HistoricalVegetationgraph ? (
                          <DoublevegetationGraph entry1={CurrentVegetationgraph} entry2={HistoricalVegetationgraph} />
                        ) : (
                          <div>No data available</div>
                        )
                      ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
                          <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }} />
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
              </>
            )}

{showTemperatureDataset && (  
  <>
    {(frequency === "Daily" && getWeatherVariable == "Rainfall") ||(frequency === "Daily" && getWeatherVariable == "Temperature" )|| (frequency == '5 day' || frequency == '10 day') ? 
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Card className="shadow" style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
          <CardTitle>
            Historical Average vs Current Value - Era5
          </CardTitle>

                      <div style={{ width: '100%' }}>
                        {!tempgraphloading ? (
                          DailyTempGraph && HistoricalTempGraph ? (
                            <DoubleGraphTemp data1={DailyTempGraph} data2={HistoricalTempGraph} />
                          ) : (
                            <div>No data available</div>
                          )
                        ) : (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
                            <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }} />
                          </div>
                        )}
                      </div>
                    </Card>
                  </div>
                  :
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Card className="shadow" style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
                      <CardTitle>
                        Historical Average vs Current Value - Era5
                      </CardTitle>

                      <div style={{ width: '100%' }}>
                        {!tempgraphloading ? (
                          MonthlyTempGraph && HistoricalMonthlyTempGraph ? (
                            <DoubleGraphTempMonthly data1={MonthlyTempGraph} data2={HistoricalMonthlyTempGraph} />
                          ) : (
                            <div>No data available</div>
                          )
                        ) : (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
                            <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }} />
                          </div>
                        )}
                      </div>
                    </Card>
                  </div>
                }
              </>
            )}

          </>
          :
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Card className="shadow" style={{ height: '40vh', alignItems: 'center', justifyContent: 'center' }}>
              Select Weather Variable  combination and dataset
            </Card>
          </div>}
      </div>

    </>
  );
};

export default Interrelation;
