import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const IUanalysisCommercialChart = ({ data }) => {
  const chartData = data.map(item => ({
    name: item.level4,
    y: item.commercial_premium
  }));

  const options = {
    chart: {
      type: 'bar'
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: data.map(item => item.level),
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Commercial Premium',
        align: 'middle'
      },
      labels: {
        overflow: 'justify'
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        },
        color: '#F3D766'
      }
    },
    series: [
      {
        name: 'Commercial Premium',
        data: data.map(item => item.commercial_premium)
      },
     
    ],
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  };


  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default IUanalysisCommercialChart;
