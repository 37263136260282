import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

export const LineChartTemp = (props) => {
  let subset = [];
  let final = [];
  if (props) {
    for (let i in props.data) {
      subset.push(props.data[i]["date"]);
      subset.push(props.data[i]["temperature"]);
      final.push(subset);
      subset = [];
    }
  }

  const options = {
    chart: {
      alignTicks: false,
    },

    rangeSelector: {
      inputEnabled: false,
    },

    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      opposite: false,
    },

    exporting: {
      enabled: true,
      filename: props.state + props.name + "_Temperature",
    },
    tooltip: {
      formatter: function () {
        return (
          "<b>Date: </b>" +
          Highcharts.dateFormat("%Y-%m-%d", this.x) +
          "<br/><b>Temperature: </b>" +
          Highcharts.numberFormat(this.y, 2)
        );
      },
    },
    series: [
      {
        type: "area",
        name: "Temperature",
        data: final,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          opacity: 0.2,
          stops: [
            [0, "#01619E"], // start color
            [1, "rgba(1, 97, 158, 0.12)"], // end color
          ],
        },
      },
    ],
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={options}
    />
  );
};
