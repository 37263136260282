const awsmobile = {
  "aws_project_region": "ap-south-1",
  "aws_cognito_identity_pool_id": "ap-south-1:d6fc637c-302d-4858-bd0a-6b18364638a2",
  "aws_cognito_region": "ap-south-1",
  "aws_user_pools_id": "ap-south-1_IYze3KUjR",
  "aws_user_pools_web_client_id": "7auimb7t7pf85t6jkljo0i9j8c",
  "oauth": {},
  "aws_mobile_analytics_app_region": "ap-south-1",
  "aws_mobile_analytics_app_id": "0c38b450e6934272814d24a41cf4fc09"
};
export default awsmobile;