import { useMap } from "../../hooks/useMap";
import { GeoJSON } from "ol/format";
import { vector } from "./Mapping/Source";
import VectorLayer from "ol/layer/Vector";
import { useEffect, useState } from "react";
import { calculateArea, removeLayerByName } from "../../lib/gis";
import { fromExtent } from "ol/geom/Polygon";
import VisualDataset from "./VisualDataset";
import FileDropZone from "./FileDropZone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UploadAShape(props) {
  const { map } = useMap();
  const [selectedLocation, setSelectedLocation] = useState("Location");
  const [exploreDataset, setExploreDataset] = useState(false);
  const [uploadedArea, setUploadedArea] = useState();
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    if (!map) return;
    return () => {
      if (map) {
        removeLayerByName(map, "uploadedLayer");
        setUploadedArea();
      }
    };
  }, []);

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = event.target.result;
      const format = new GeoJSON();
      const features = format.readFeatures(data);
      const area = calculateArea(features[0].getGeometry());
      setUploadedArea(area);

      if (area > 1000) {
        toast.error("Uploaded area is geater than 1000 ha");
        setIsReset(true);
        return;
      }

      const vectorSource = vector({
        features: features,
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      vectorLayer.set("name", "uploadedLayer");
      map.addLayer(vectorLayer);
      map.getView().fit(vectorLayer.getSource().getExtent(), {
        padding: [50, 50, 50, 50],
        duration: 1000,
      });
      getLocation(vectorSource);
    };
    reader.readAsText(file);
  };

  const getLocation = (source) => {
    const extent = source.getExtent();
    const polygon = fromExtent(extent);
    const coordinate = polygon.getInteriorPoint().getCoordinates();
    getSelectedLocation([coordinate[0], coordinate[1]]);
  };

  const getSelectedLocation = async (coordinate) => {
    await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinate[1]}&lon=${coordinate[0]}`
    )
      .then((res) => res.json())
      .then((result) => {
        props.setCountryName(result.address.country);
        setSelectedLocation(result.display_name);
      })
      .catch((err) => console.log(err));
  };

  const handleResetFields = () => {
    setExploreDataset(false);
    setSelectedLocation("Location");
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          width: "400px",
          padding: "16px",
          textAlign: "initial",
          color: "#21201C",
        }}
      />
      <div className="menuContent">
        <div className="exploretext">
          <img
            src="chevron-left.png"
            alt="location icon"
            style={{ marginRight: 10 }}
            onClick={() => props.showUploadShape(false)}
          />
          Upload a Shape
        </div>
        <div className="exploresecondarytext">Upload your shape file.</div>

        <FileDropZone
          onChange={handleFileUpload}
          setIsFileUploaded={handleFileUpload}
          isReset={isReset}
          setIsReset={setIsReset}
        />
        {uploadedArea && (
          <div style={{ padding: "16px", textAlign: "center", color: "#999" }}>
            Total Area: {uploadedArea.toFixed(2)} ha
          </div>
        )}

        <div className="exploredataset-button-container">
          <div>
            <hr className="horizontal-rule" />
            <button className="selectedloc-button">
              <img src="loc-pin.png" alt="icon" style={{ marginRight: 5 }} />
              {selectedLocation}
              <img
                src="tabler-icon-x.svg"
                className="reset-button"
                alt="Reset"
                onClick={handleResetFields}
              />
            </button>
          </div>

          <div>
            <button
              className="exploredataset-button"
              onClick={() => setExploreDataset(true)}
              disabled
            >
              Coming Soon
            </button>
          </div>
        </div>
      </div>
      {exploreDataset && (
        <VisualDataset
          tamsatDailyGraph={props.tamsatDailyGraph}
          setTamsatDailyGraph={props.setTamsatDailyGraph}
          tamsatMonthlyGraph={props.tamsatMonthlyGraph}
          setTamsatMonthlyGraph={props.setTamsatMonthlyGraph}
          chirps5kmMonthlyRainfall={props.chirps5kmMonthlyRainfall}
          setChirps5kmMonthlyRainfall={props.setChirps5kmMonthlyRainfall}
          arc210kmDailyRainfall={props.arc210kmDailyRainfall}
          setarc210kmDailyRainfall={props.setarc210kmDailyRainfall}
          modis5DayIntervalNDVIGraph={props.modis5DayIntervalNDVIGraph}
          setModis5DayIntervalNDVIGraph={props.setModis5DayIntervalNDVIGraph}
          setExploreDataset={setExploreDataset}
          exploreDataset={exploreDataset}
          landcover={props.landcover}
          setlandcover={props.setlandcover}
          chirps5kmDailyRainfall={props.chirps5kmDailyRainfall}
          setChirps5kmDailyRainfall={props.setChirps5kmDailyRainfall}
          era5DailyRainfallGraph={props.era5DailyRainfallGraph}
          setera5DailyRainfallGraph={props.setera5DailyRainfallGraph}
          era5MonthlyRainfallGraph={props.era5MonthlyRainfallGraph}
          setera5MonthlyRainfallGraph={props.setera5MonthlyRainfallGraph}
          era5DailyTemperatureGraph={props.era5DailyTemperatureGraph}
          setera5DailyTemperatureGraph={props.setera5DailyTemperatureGraph}
          era5MonthlyTemperatureGraph={props.era5MonthlyTemperatureGraph}
          setera5MonthlyTemperatureGraph={props.setera5MonthlyTemperatureGraph}
          gsMapDaily={props.gsMapDaily}
          setgsMapDaily={props.setgsMapDaily}
          gsMapMonthly={props.gsMapMonthly}
          setgsMapMonthly={props.setgsMapMonthly}
          sentinal2NDVI={props.sentinal2NDVI}
          setsentinal2NDVI={props.setsentinal2NDVI}
          selectedLocation={selectedLocation}
          countryName={props.countryName}
          setIsDrawerOpen={props.setIsDrawerOpen}
        />
      )}
    </>
  );
}

export default UploadAShape;
