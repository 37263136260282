import React, { useRef, useEffect } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const AreaRangeLineGraphRain = ({ data, graphName }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let chartInstance = null;
    if (chartRef && chartRef.current && data.error !== "Null response") {
      const chartData = data.map(item => ({
        x: item.date_milliseconds,
        low: item.min,
        high: item.max,
        y: item.average
      }));
      let series1Color, series2Color;
      if (graphName === 'Arc2') {
        series1Color = '#147D6F4D';
        series2Color = '#147D6F';
      } else if (graphName === 'Tamsat') {
        series1Color = '#EBBC004D';
        series2Color = '#EBBC00';
      } else {
        series1Color = '#01619E4D';
        series2Color = '#01619E';
      }
  
      chartInstance =  Highcharts.chart(chartRef.current, {
        chart: {
          type: 'arearange',
          zoomType: 'x'
        },
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%b ' // Display month and year
          },
          min: data.length > 0 ? data[0].date_milliseconds : null // Set min to the earliest date
        },
        yAxis: {
          title: {
            text: 'Rainfall (mm)'
          }
        },
        tooltip: {
          crosshairs: true,
          shared: true,
          valueSuffix: 'mm',
          formatter: function () {
            return '<b>' + Highcharts.dateFormat(' %b %e', this.x) + '</b><br/>' +
              'Range: ' + this.point.low + 'mm - ' + this.point.high + 'mm <br/>' +
              'Average: ' + this.points[1].y + 'mm';
          }
        },
        credits: false,
        series: [{
          name: 'Historical Min Max',
          data: chartData,
          zIndex: 1,
          color: series1Color,
          
        }, {
          name: 'Average ',
          data: chartData.map(item => [item.x, item.y]),
          type: 'line',
          zIndex: 2,
          color: series2Color,
          
        }]
      });
    }
  
    // Cleanup function
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [data, graphName]);
  
  return data.error === "Null response" ? <div>Null values for this time period</div> : <div ref={chartRef} />;
};

export default AreaRangeLineGraphRain;
