import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "../../ManualStep2.css";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Select,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import ManualSummary from "./ManualSummary";
import {
  changeAggregator,
  changeBrokerFee,
  changeCommercialMaxPremium,
  changeCommercialMinPremium,
  changeCommercialPremium,
  changeCurrency,
  changeIUAnalysisCommercial,
  changeInsurer,
  changeInsurerMargin,
  changePremiumLevy,
  changeProductDevelopmentFee,
  changeStep,
  changeSumInsured,
  changeTargetLossRatioValue,
  changeTargetLossRatioselection,
  changeTotalIU,
  changeValueChain,
  changeWithholdingTax,
  changecommercialpremiumRateType,
} from "../../redux/action";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import IUanalysisChart from "./IUanalysisChart";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { HiOutlineRefresh } from "react-icons/hi";
import DownloadTermsheet from "./DownloadTermsheet";
import IUanalysisCommercialChart from "./IUanalysisCommercialChart";
import { Auth } from "aws-amplify";

const useStyles = makeStyles({
  slider: {
    color: "rgba(247, 104, 8, 1)",
    width: "100%",
    height: "6px",
    outline: "none",
    opacity: "0.7",
    transition: "opacity .2s",
    borderRadius: "10px",
    marginTop: "10px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
  },
});
const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: 5,
    width: "90%",
  },
  container2: {
    display: "flex",
    justifyContent: "space-between",
    padding: 5,
    width: "60%",
  },
  container3: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    width: "100%",
  },
  container4: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    width: "50%",
  },
  container5: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    width: "66%",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    marginRight: 50,

    flex: 1,
  },
  fieldContainer1: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginRight: 50,
    width: "15%",
  },
  heading: {
    color: (props) => (props.buttonClick ? "#FFB381" : "black"),
    marginBottom: 10,
    marginTop: 5,
    textAlign: "left",
    fontSize: "14",
    lineHeight: "26px",
    fontWeight: 500,
    FontFace: "Inter",
  },
  mainheading: {
    color: (props) => (props.buttonClick ? "#FFB381" : "black"),
    marginBottom: 10,
    marginTop: 5,
    textAlign: "left",
    fontSize: "16",
    lineHeight: "26px",
    fontWeight: 600,
    FontFace: "Inter",
  },
  select: {
    borderRadius: 5,
    height: 40,
  },
  textField: {
    borderRadius: 5,
    height: 30,
  },
  hr: {
    width: "100%",
    border: "none",
    borderTop: "1px solid #ccc",
    margin: "15px 0",
  },
  slider: {
    width: "100%",
    height: "6px",
    outline: "none",
    opacity: "0.7",
    transition: "opacity .2s",
    borderRadius: "10px",
    marginTop: "10px",
    marginLeft: 10,
    marginRight: 10,
  },
  sliderValue: {
    textAlign: "center",
    padding: "6px",
    borderRadius: "5px",
    width: "60px",
    border: "1px solid black",
  },
  tableHeader: {
    backgroundColor: "#F9F1FE",
  },
  Menuitem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "10px",
  },
};
const OrangeRadio = styled(Radio)({
  "&.Mui-checked": {
    color: "rgba(247, 104, 8, 1)",
  },
});

const ManualStep2 = (props) => {
  const [buttonClick, setButtonClick] = useState(false);
  const classes = useStyles({ buttonClick });
  const [tabvalue, settabValue] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);
  const step = useSelector((state) => state.step);
  const dispatch = useDispatch();
  const [loopbackyears, setloopbackyears] = useState(0);
  const [threshholdSenstivity, setthreshholdSenstivity] = useState(0);
  const [exitSenstivity, setexitSenstivity] = useState(0);
  const [maxPayout, setMaxPayout] = useState(0);
  const targetLossRatioValue = useSelector(
    (state) => state.targetLossRatioValue
  );
  const targetLossRatioselection = useSelector(
    (state) => state.targetLossRatioselection
  );
  const commercialpremiumRateType = useSelector(
    (state) => state.commercialpremiumRateType
  );
  const insurerMargin = useSelector((state) => state.insurerMargin);
  const productDevelopmentFee = useSelector(
    (state) => state.productDevelopmentFee
  );
  const brokerFee = useSelector((state) => state.brokerFee);
  const withholdingTax = useSelector((state) => state.withholdingTax);
  const PremiumLevy = useSelector((state) => state.PremiumLevy);
  const typeOfProduct = useSelector((state) => state.typeOfProduct);
  const productName = useSelector((state) => state.productName);
  const insurer = useSelector((state) => state.insurer);
  const valueChain = useSelector((state) => state.valueChain);
  const aggregator = useSelector((state) => state.aggregator);
  const currency = useSelector((state) => state.currency);
  const sumInsured = useSelector((state) => state.sumInsured);
  const [payout, setPayout] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const IUAnalysisPremium = useSelector((state) => state.IUAnalysisPremium);
  const IUAnalysisCommercial = useSelector(
    (state) => state.IUAnalysisCommercial
  );
  const [productExists, setProductExists] = useState(null);
  const CommercialPremium = useSelector((state) => state.CommercialPremium);
  const CommercialMaxPremium = useSelector(
    (state) => state.CommercialMaxPremium
  );
  const TotalIU = useSelector((state) => state.TotalIU);
  const [iuSelectedList, setIUSelectedList] = useState("");
  const policyDetails = useSelector((state) => state.policyDetails);
  const EditPolicy = useSelector((state) => state.EditPolicy);
  const EditingState = useSelector((state) => state.EditingState);

  useEffect(() => {
    if (
      loopbackyears !== 0 ||
      maxPayout !== 0 ||
      threshholdSenstivity !== 0 ||
      exitSenstivity !== 0
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [loopbackyears, maxPayout, threshholdSenstivity, exitSenstivity]);

  useEffect(() => {
    const fetchData = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      try {
        if (step === 3) {
          const response = await fetch(
            "/api/pricing/showTopIUCommercialPremium",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: email,
                product_type: typeOfProduct,
                product_name: productName,
              }),
            }
          );
          const data = await response.json();
          dispatch(changeIUAnalysisCommercial(data));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [
    step,
    typeOfProduct,
    productName,
    CommercialPremium,
    CommercialMaxPremium,
  ]);

  const handleloopbackyearsChange = (event) => {
    setloopbackyears(event.target.value);
  };

  const handleMaximumPayoutChange = (event) => {
    setMaxPayout(event.target.value);
  };
  const handlethreshholdSenstivityChange = (event) => {
    setthreshholdSenstivity(event.target.value);
  };
  const handleexitSenstivityChange = (event) => {
    setexitSenstivity(event.target.value);
  };

  const handletabChange = (event, newValue) => {
    settabValue(newValue);
  };

  // const handleCollapse = () => {
  //   setCollapsed(!collapsed);
  // };
  const VerifiedIcon = ({ color }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 1.60787C19.8099 2.6529 21.3155 4.1525 22.3678 5.95823C23.42 7.76396 23.9824 9.8132 23.9993 11.9031C24.0162 13.993 23.487 16.051 22.464 17.8735C21.4411 19.696 19.9599 21.2197 18.1671 22.2938C16.3743 23.368 14.332 23.9553 12.2425 23.9975C10.153 24.0398 8.08866 23.5357 6.25386 22.535C4.41907 21.5342 2.87744 20.0717 1.7816 18.2921C0.685754 16.5125 0.0736912 14.4775 0.00600014 12.3887L0 11.9999L0.00600014 11.6111C0.0732038 9.53866 0.676257 7.51902 1.75637 5.74906C2.83648 3.9791 4.35678 2.51921 6.16907 1.51173C7.98135 0.504246 10.0238 -0.0164529 12.0972 0.000396295C14.1706 0.0172455 16.2043 0.571068 18 1.60787ZM16.4484 8.75147C16.2418 8.54486 15.9668 8.42074 15.6752 8.4024C15.3836 8.38407 15.0953 8.47277 14.8644 8.65187L14.7516 8.75147L10.8 12.7019L9.2484 11.1515L9.1356 11.0519C8.9047 10.8729 8.61644 10.7843 8.32488 10.8027C8.03332 10.8211 7.75849 10.9452 7.55191 11.1518C7.34534 11.3584 7.22122 11.6332 7.20282 11.9248C7.18443 12.2163 7.27302 12.5046 7.452 12.7355L7.5516 12.8483L9.9516 15.2483L10.0644 15.3479C10.2748 15.5111 10.5336 15.5998 10.8 15.5998C11.0664 15.5998 11.3251 15.5111 11.5356 15.3479L11.6484 15.2483L16.4484 10.4483L16.548 10.3355C16.7271 10.1046 16.8158 9.81628 16.7975 9.52465C16.7791 9.23302 16.655 8.9581 16.4484 8.75147Z"
        fill="#8E4EC6"
      />
    </svg>
  );
  const isGenerateButtonDisabled = () => {
    if (
      !aggregator ||
      !insurer ||
      !valueChain ||
      !currency ||
      !sumInsured ||
      !commercialpremiumRateType ||
      (targetLossRatioselection === "Yes" && !targetLossRatioValue) ||
      (targetLossRatioselection !== "Yes" &&
        (!brokerFee ||
          !PremiumLevy ||
          !insurerMargin ||
          !productDevelopmentFee ||
          !withholdingTax))
    ) {
      return true;
    }
    return false;
  };

  const generatepremium = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const email = user.attributes.email;
    try {
      const productExistResponse = await fetch("/api/pricing/productExist", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          product_type: typeOfProduct,
          product_name: productName,
        }),
      });
      const productExistData = await productExistResponse.json();
      setProductExists(productExistData);
      const getPremiumIUWiseResponse = await fetch(
        "/api/pricing/getPremiumIUWise",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            country: "Bangladesh",
            product_type: typeOfProduct,
            product_name: productName,
          }),
        }
      );
      const IUWisePremiumList = await getPremiumIUWiseResponse.json();
      if (productExistData === false || productExistData === true) {
        // Call API to calculate pure premium payout

        const requestBody = {
          email: email,
          country: "Bangladesh",
          product_type: typeOfProduct,
          product_name: productName,
          aggregator: aggregator,
          insurer: insurer,
          value_chain: valueChain,
          currency: currency,
          sum_insured: sumInsured,
          commercial_premium_rate_type: commercialpremiumRateType,
          target_loss_ratio_selection: targetLossRatioselection,
          target_loss_ratio: parseFloat(
            targetLossRatioValue ? targetLossRatioValue : null
          ),
          insurer_reinsurer_margin: parseInt(
            insurerMargin ? insurerMargin : null
          ),
          product_development_fee: parseInt(
            productDevelopmentFee ? productDevelopmentFee : null
          ),
          broker_fee: parseInt(brokerFee ? brokerFee : null),
          premium_levy: parseInt(PremiumLevy ? PremiumLevy : null),
          withholding_tax: parseInt(withholdingTax ? withholdingTax : null),
          iu_list: IUWisePremiumList,
        };

        const calculateResponse = await fetch(
          "/api/pricing/calculateCommercialPremium",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        const data = await calculateResponse.json();
        dispatch(changeCommercialPremium(data["mean_premium"]));
        dispatch(changeCommercialMinPremium(data["min_premium"]));
        dispatch(changeCommercialMaxPremium(data["max_premium"]));
        dispatch(changeTotalIU(data["total_iu"]));
      }
      if (!EditPolicy && EditingState) {
        // Call API to show pure premium
        const showCommercialPremiumResponse = await fetch(
          "/api/pricing/showCommercialPremium",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email,
              country: "Bangladesh",
              product_type: "Crop",
              product_name: "test",
              iu_list: IUWisePremiumList,
            }),
          }
        );
        const commercialPremiumData =
          await showCommercialPremiumResponse.json();
        dispatch(
          changeCommercialPremium(commercialPremiumData["mean_premium"])
        );
        dispatch(
          changeCommercialMinPremium(commercialPremiumData["min_premium"])
        );
        dispatch(
          changeCommercialMaxPremium(commercialPremiumData["max_premium"])
        );
        dispatch(changeTotalIU(commercialPremiumData["total_iu"]));
      } else {
        console.error("Error:");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleaddcommercial = (e) => {
    dispatch(changeStep(3));
    dispatch(changeCommercialPremium(null));
    dispatch(changeCommercialMinPremium(null));
    dispatch(changeCommercialMaxPremium(null));
    dispatch(changeTotalIU(null));
  };
  useEffect(() => {
    if (policyDetails && policyDetails.length > 0) {
      const details = policyDetails[0];
      dispatch(changeAggregator(details.aggregator || null));
      dispatch(changeInsurer(details.insurer || null));
      dispatch(changeCurrency(details.currency || null));
      dispatch(changeSumInsured(details.sum_insured || null));
      dispatch(changeValueChain(details.value_chain || null));
      dispatch(
        changecommercialpremiumRateType(
          details.commercial_premium_rate_type || null
        )
      );
      dispatch(
        changeTargetLossRatioselection(
          details.target_loss_ratio_selection || null
        )
      );
      dispatch(changeTargetLossRatioValue(details.target_loss_ratio || null));
      dispatch(changeInsurerMargin(details.insurer_reinsurer_margin || null));
      dispatch(
        changeProductDevelopmentFee(details.product_development_fee || null)
      );
      dispatch(changeBrokerFee(details.broker_fee || null));
      dispatch(changePremiumLevy(details.premium_levy || null));
      dispatch(changeWithholdingTax(details.withholding_tax || null));
    }
  }, [JSON.stringify(policyDetails)]);

  const renderStepContent = (stepNumber, text) => {
    const isActive = step === stepNumber;
    const isCompleted = step > stepNumber;
    return (
      <div
        className={`styled-step ${isActive ? "active-step" : ""} ${
          isCompleted ? "completed-step" : ""
        }`}
        onClick={() => dispatch(changeStep(stepNumber))}
      >
        <div className="styled-step-content step">
          <div className="stepNumber">
            {isCompleted ? <VerifiedIcon color="#8E4EC6" /> : stepNumber}
          </div>
          <div
            style={{ color: isCompleted || isActive ? "#8E4EC6" : "inherit" }}
          >
            {text}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={`container ${collapsed ? "collapsed" : ""}`}>
      <div className="leftColumn">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="exploretext"
            style={{
              display: collapsed ? "none" : "flex",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            <img
              src="chevron-left.png"
              alt="location icon"
              style={{ marginRight: 10 }}
              onClick={() => dispatch(changeStep(1))}
            />
            Calculate Pricing
          </div>
          <div>
            {/* <LuChevronsLeft onClick={handleCollapse} style={{ cursor: 'pointer' }} size={25} /> */}
          </div>
        </div>
        {!collapsed && (
          <>
            <div className="compareecondarytext" style={{ marginLeft: "15px" }}>
              Fill the required input fields to calculate pricing.
            </div>
            <div className="stepcontainer">
              {renderStepContent(1, "Pricing Inputs")}
              {renderStepContent(2, "Premium Analysis")}
              {renderStepContent(3, "Gross Premium")}
            </div>
            {step == 2 && (
              <>
                <div style={styles.container3}></div>
                {/* <hr style={styles.hr} /> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <div style={styles.mainheading}>Sensitivity Values</div>
                  <div style={{ marginRight: "20px", color: "#706F6C" }}>
                    <HiOutlineRefresh
                      color="#706F6C"
                      style={{ marginRight: "10px", marginTop: "5px" }}
                    />
                    Reset
                  </div>
                </div>
                <div style={styles.container3}>
                  <div style={styles.fieldContainer}>
                    <div style={styles.heading}>Loop Back Years</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span>0</span>
                      <Slider
                        min={0}
                        max={10}
                        value={loopbackyears}
                        onChange={handleloopbackyearsChange}
                        classes={{ root: classes.slider }}
                        style={{ width: "100%", marginLeft: "30px" }}
                      />
                      <span>10</span>
                      <div
                        style={{ marginLeft: "20px", ...styles.sliderValue }}
                      >
                        {loopbackyears}
                      </div>
                    </div>
                  </div>
                </div>

                <div style={styles.container3}>
                  <div style={styles.fieldContainer}>
                    <div style={styles.heading}>Threshold Sensitivity (%)</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span>-30</span>
                      <Slider
                        min={-30}
                        max={30}
                        value={threshholdSenstivity}
                        onChange={handlethreshholdSenstivityChange}
                        classes={{ root: classes.slider }}
                        style={{ width: "100%" }}
                      />
                      <span>30</span>
                      <div
                        style={{ marginLeft: "20px", ...styles.sliderValue }}
                      >
                        {threshholdSenstivity}
                      </div>
                    </div>
                  </div>
                </div>

                <div style={styles.container3}>
                  <div style={styles.fieldContainer}>
                    <div style={styles.heading}>Exit Sensitivity (%)</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span>0</span>
                      <Slider
                        min={0}
                        max={2}
                        value={exitSenstivity}
                        onChange={handleexitSenstivityChange}
                        classes={{ root: classes.slider }}
                        style={{
                          width: "100%",
                          marginLeft: "30px",
                          marginRight: "20px",
                        }}
                      />
                      <span>2</span>
                      <div
                        style={{ marginLeft: "20px", ...styles.sliderValue }}
                      >
                        {exitSenstivity}
                      </div>
                    </div>
                  </div>
                </div>

                <div style={styles.container3}>
                  <div style={styles.fieldContainer}>
                    <div style={styles.heading}>Max Payout (%)</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "100px",
                      }}
                    >
                      <span>0</span>
                      <Slider
                        min={0}
                        max={100}
                        value={maxPayout}
                        onChange={handleMaximumPayoutChange}
                        classes={{ root: classes.slider }}
                        style={{ width: "100%", marginLeft: "30px" }}
                      />
                      <span>100</span>
                      <div
                        style={{ marginLeft: "20px", ...styles.sliderValue }}
                      >
                        {maxPayout}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "35%",
                    padding: "20px",
                    bottom: 0,
                    position: "fixed",
                    background: "white",
                    borderTop: "1px solid rgba(26, 26, 0, 0.16",
                  }}
                >
                  {/* Save Button */}
                  <button
                    style={{
                      marginRight: "10px", // Add space between buttons
                      padding: "7px 14px", // Adjust padding
                      backgroundColor: isButtonDisabled
                        ? "rgba(241, 245, 249, 1)"
                        : "black", // Grey background for disabled button
                      color: isButtonDisabled
                        ? "rgba(30, 25, 1, 0.24)"
                        : "white", // White text color
                      border: "none", // No border
                      borderRadius: "5px", // Rounded corners
                      cursor: isButtonDisabled ? "not-allowed" : "pointer", // Pointer cursor if enabled
                      fontSize: "14px", // Font size
                      fontWeight: 500,
                      width: "182px", // Font weight
                    }}
                    disabled={isButtonDisabled}
                  >
                    Simulate
                  </button>
                  {/* Download Button */}
                  <button
                    style={{
                      padding: "7px 14px", // Adjust padding
                      backgroundColor: "black", // Black background for download button
                      color: "white", // White text color
                      border: "none", // No border
                      borderRadius: "5px", // Rounded corners
                      cursor: "pointer", // Pointer cursor
                      fontSize: "14px", // Font size
                      fontWeight: 500,
                      width: "182px", // Font weight
                    }}
                    onClick={handleaddcommercial}
                  >
                    Add Commercial
                  </button>
                </div>
              </>
            )}
            {step == 3 && (
              <>
                <div style={{ marginBottom: "20%" }}>
                  <div style={styles.heading}>Add Commercial Details</div>
                  <Accordion
                    sx={{
                      marginBottom: "20px",
                      border: "1px solid rgba(26, 26, 0, 0.16)",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        backgroundColor: "grey",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        <img
                          src="loc-pin.png"
                          alt="icon"
                          style={{ marginRight: 5 }}
                        />
                        <Typography>Basic Details</Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={styles.container3}>
                        <div style={styles.fieldContainer}>
                          <div style={styles.heading}>Aggregator </div>
                          <Select
                            abelId="typeOfProductLabel"
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            style={styles.select}
                            value={aggregator}
                            onChange={(event) =>
                              dispatch(changeAggregator(event.target.value))
                            }
                          >
                            <MenuItem style={styles.Menuitem} value="BRAC">
                              BRAC
                            </MenuItem>
                            <MenuItem style={styles.Menuitem} value="GUK">
                              GUK
                            </MenuItem>
                            <MenuItem
                              style={styles.Menuitem}
                              value="Syngenta Bangladesh Ltd."
                            >
                              Syngenta Bangladesh Ltd.
                            </MenuItem>
                            <MenuItem style={styles.Menuitem} value="ESDO">
                              ESDO
                            </MenuItem>
                            <MenuItem
                              style={styles.Menuitem}
                              value="GBK Enterprises Ltd."
                            >
                              GBK Enterprises Ltd.
                            </MenuItem>
                            <MenuItem
                              style={styles.Menuitem}
                              value="Ejab Agro Ltd."
                            >
                              Ejab Agro Ltd.
                            </MenuItem>
                          </Select>
                        </div>
                        <div style={styles.fieldContainer}>
                          <div style={styles.heading}>Insurer</div>
                          <Select
                            abelId="typeOfProductLabel"
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            style={styles.select}
                            value={insurer}
                            onChange={(event) =>
                              dispatch(changeInsurer(event.target.value))
                            }
                          >
                            <MenuItem
                              style={styles.Menuitem}
                              value="Green Delta Insurance Company(GDIC)"
                            >
                              Green Delta Insurance Company(GDIC){" "}
                            </MenuItem>
                            <MenuItem
                              style={styles.Menuitem}
                              value="Sadharan Bima Corporation(SBC)"
                            >
                              Sadharan Bima Corporation(SBC),
                            </MenuItem>
                          </Select>
                        </div>
                      </div>
                      <div style={styles.container3}>
                        <div style={styles.fieldContainer}>
                          <div style={styles.heading}>Value Chain </div>
                          <Select
                            abelId="typeOfProductLabel"
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            style={styles.select}
                            value={valueChain}
                            onChange={(event) =>
                              dispatch(changeValueChain(event.target.value))
                            }
                          >
                            <MenuItem style={styles.Menuitem} value="Boro Rice">
                              Boro Rice
                            </MenuItem>
                            <MenuItem style={styles.Menuitem} value="Potato">
                              Potato
                            </MenuItem>
                            <MenuItem
                              style={styles.Menuitem}
                              value="Winter Maize"
                            >
                              Winter Maize
                            </MenuItem>
                            <MenuItem
                              style={styles.Menuitem}
                              value="Summer Maize"
                            >
                              Summer Maize
                            </MenuItem>

                            <MenuItem
                              style={styles.Menuitem}
                              value="Common Maize"
                            >
                              Common Maize
                            </MenuItem>
                          </Select>
                        </div>
                        <div style={styles.fieldContainer}>
                          <div style={styles.heading}>Currency </div>
                          <Select
                            abelId="typeOfProductLabel"
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            style={styles.select}
                            value={currency}
                            onChange={(event) =>
                              dispatch(changeCurrency(event.target.value))
                            }
                          >
                            <MenuItem style={styles.Menuitem} value="USD">
                              USD
                            </MenuItem>
                            <MenuItem style={styles.Menuitem} value="BDT">
                              BDT
                            </MenuItem>
                          </Select>
                        </div>
                      </div>
                      <div style={styles.container3}>
                        <div style={styles.fieldContainer}>
                          <div style={styles.heading}>Sum insured</div>
                          <TextField
                            fullWidth
                            id="productType"
                            size="small"
                            style={styles.textField}
                            value={sumInsured}
                            type="number"
                            step="0.01"
                            onChange={(event) =>
                              dispatch(changeSumInsured(event.target.value))
                            }
                          />
                        </div>
                      </div>
                      <div style={styles.container3}>
                        <div style={styles.fieldContainer}>
                          <div style={styles.heading}>
                            Method of Calculating Commercial Premium rate
                          </div>
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              aria-label="deductibleType"
                              name="deductibleType"
                              value={commercialpremiumRateType}
                              onChange={(event) =>
                                dispatch(
                                  changecommercialpremiumRateType(
                                    event.target.value
                                  )
                                )
                              }
                              style={{ justifyContent: "space-around" }}
                            >
                              <FormControlLabel
                                value="Linear"
                                control={<OrangeRadio />}
                                label="Linear"
                              />
                              <FormControlLabel
                                value="Compound"
                                control={<OrangeRadio />}
                                label="Compound"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <div style={styles.container3}>
                    <div style={styles.fieldContainer}>
                      <div style={styles.heading}>Target Loss Ratio</div>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="deductibleType"
                          name="deductibleType"
                          value={targetLossRatioselection}
                          onChange={(event) =>
                            dispatch(
                              changeTargetLossRatioselection(event.target.value)
                            )
                          }
                          style={{ justifyContent: "space-around" }}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<OrangeRadio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<OrangeRadio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  {targetLossRatioselection === "No" ? (
                    <>
                      <div style={{ marginBottom: "150px" }}>
                        <div style={styles.container3}>
                          <div style={styles.fieldContainer}>
                            <div style={styles.heading}>
                              Insurer & Reinsurer Margin (%) 
                            </div>
                            <TextField
                              fullWidth
                              id="productType"
                              size="small"
                              style={styles.textField}
                              value={insurerMargin}
                              type="number"
                              step="0.01"
                              onChange={(event) =>
                                dispatch(
                                  changeInsurerMargin(event.target.value)
                                )
                              }
                            />
                          </div>
                          <div style={styles.fieldContainer}>
                            <div style={styles.heading}>
                              Product Development Fee (%)
                            </div>
                            <TextField
                              fullWidth
                              id="productType"
                              size="small"
                              style={styles.textField}
                              value={productDevelopmentFee}
                              type="number"
                              step="0.01"
                              onChange={(event) =>
                                dispatch(
                                  changeProductDevelopmentFee(
                                    event.target.value
                                  )
                                )
                              }
                            />
                          </div>
                        </div>
                        <div style={styles.container3}>
                          <div style={styles.fieldContainer}>
                            <div style={styles.heading}>Broker Fee (%) </div>
                            <TextField
                              fullWidth
                              id="productType"
                              size="small"
                              style={styles.textField}
                              value={brokerFee}
                              type="number"
                              step="0.01"
                              onChange={(event) =>
                                dispatch(changeBrokerFee(event.target.value))
                              }
                            />
                          </div>
                          <div style={styles.fieldContainer}>
                            <div style={styles.heading}>Premium Levy (%) </div>
                            <TextField
                              fullWidth
                              id="productType"
                              size="small"
                              style={styles.textField}
                              value={PremiumLevy}
                              type="number"
                              step="0.01"
                              onChange={(event) =>
                                dispatch(changePremiumLevy(event.target.value))
                              }
                            />
                          </div>
                        </div>
                        <div style={styles.container4}>
                          <div style={styles.fieldContainer}>
                            <div style={styles.heading}>
                              Withholding Tax (%) 
                            </div>
                            <TextField
                              fullWidth
                              id="productType"
                              size="small"
                              style={styles.textField}
                              value={withholdingTax}
                              type="number"
                              step="0.01"
                              onChange={(event) =>
                                dispatch(
                                  changeWithholdingTax(event.target.value)
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div style={{ marginBottom: "150px" }}>
                      <div style={styles.fieldContainer}>
                        <div style={styles.heading}>Target Loss Ratio</div>
                        <TextField
                          fullWidth
                          id="productType"
                          size="small"
                          style={styles.textField}
                          value={targetLossRatioValue}
                          type="number"
                          step="0.01"
                          onChange={(event) =>
                            dispatch(
                              changeTargetLossRatioValue(event.target.value)
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "35%",
                      padding: "20px",
                      bottom: 0,
                      position: "fixed",
                      background: "white",
                      borderTop: "1px solid rgba(26, 26, 0, 0.16)",
                    }}
                  >
                    {/* Save Button */}
                    <button
                      style={{
                        marginRight: "10px", // Add space between buttons
                        padding: "7px 14px", // Adjust padding
                        backgroundColor: "rgba(241, 245, 249, 1)", // Blue background for save button
                        color: "black", // White text color
                        border: "none", // No border
                        borderRadius: "5px", // Rounded corners
                        cursor: "pointer", // Pointer cursor

                        fontSize: "14px", // Font size
                        fontWeight: 500,
                        width: "182px", // Font weight
                      }}
                      onClick={() => dispatch(changeStep(0))}
                    >
                      Close
                    </button>
                    {/* Download Button */}
                    <button
                      style={{
                        padding: "7px 14px", // Adjust padding
                        backgroundColor: isGenerateButtonDisabled()
                          ? "rgba(20, 20, 0, 0.05)"
                          : "black", // Conditional background color
                        color: isGenerateButtonDisabled()
                          ? "rgba(30, 25, 1, 0.24)"
                          : "white", // Conditional text color
                        border: "none", // No border
                        borderRadius: "5px", // Rounded corners
                        cursor: "pointer", // Pointer cursor
                        fontSize: "14px", // Font size
                        fontWeight: 500,
                        width: "182px", // Font weight
                        cursor: isGenerateButtonDisabled()
                          ? "not-allowed"
                          : "pointer", // Not-allowed cursor when disabled
                      }}
                      onClick={generatepremium}
                      disabled={isGenerateButtonDisabled()}
                    >
                      Generate Premium
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className="rightColumn">
        <Tabs
          value={tabvalue}
          onChange={handletabChange}
          textColor="secondary"
          indicatorColor="secondary"
          TabIndicatorProps={{ style: { backgroundColor: "#F76808" } }}
        >
          <Tab
            label="Summary"
            style={{ color: tabvalue === 0 ? "#F76808" : undefined }}
          />
          <Tab
            label="IU Analysis"
            style={{ color: tabvalue === 1 ? "#F76808" : undefined }}
          />
          <Tab
            label="Download"
            style={{ color: tabvalue === 2 ? "#F76808" : undefined }}
          />
        </Tabs>
        <div className={classes.root}>
          {tabvalue === 0 && (
            <ManualSummary
              iuSelectedList={iuSelectedList}
              setIUSelectedList={setIUSelectedList}
              loading={props.loading}
              setLoading={props.setLoading}
              payout={payout}
              setPayout={setPayout}
            />
          )}
          {tabvalue === 1 && (
            <div style={{ marginTop: "20px" }}>
              {step === 3 && IUAnalysisCommercial ? (
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: 500,
                    textAlign: "left",
                    marginBottom: "20px",
                    marginTop: "20px",
                  }}
                >
                  {" "}
                  Commercial Premium Across IU’s (Max 15)
                  <IUanalysisCommercialChart data={IUAnalysisCommercial} />
                </div>
              ) : (
                step == 2 &&
                IUAnalysisPremium && (
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: 500,
                      textAlign: "left",
                      marginBottom: "20px",
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                    Pure Premium Across IU’s (Max 15)
                    <IUanalysisChart data={IUAnalysisPremium} />
                  </div>
                )
              )}
            </div>
          )}

          {tabvalue === 2 && (
            <div style={{ marginTop: "20px" }}>
              <DownloadTermsheet
                payout={payout}
                setPayout={setPayout}
                iuSelectedList={iuSelectedList}
                setIUSelectedList={setIUSelectedList}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManualStep2;
