import React, { useState, useMemo, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, Button, TextField } from "@mui/material";
import "../../PricingAnalytics.css";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDeductiblePercentage,
  changeDeductibleType,
  changeEditingState,
  changeIUAnalysisCommercial,
  changeIUAnalysisPremium,
  changeIUList,
  changeNumberOfCovers,
  changePricingMethod,
  changeProductName,
  changeSavedPolicyDetails,
  changeTypeOfProduct,
  changeshowuploadsite,
  updateCoverDetails,
} from "../../redux/action";
import axios from "axios";
import { Auth } from "aws-amplify";
import {
  changeBlockSize,
  changeCoverName,
  changeCoverPeriodType,
  changeDatasets,
  changeMethodofThreshold,
  changeNewIUList,
  changeRiskCovered,
  changeRiskInterval,
  changeStep,
  changeWeightage,
  changeWithinBlockCalc,
  changeacrossBlockCalc,
  changecoverMaxPayout,
  changecoverMinPayout,
  changecoverendDate,
  changecoverstartDate,
  changeshowManual,
  changeweatherExit,
  changeweatherTrigger,
} from "../../redux/action";
import {
  changeAggregator,
  changeBrokerFee,
  changeCurrency,
  changeInsurer,
  changeInsurerMargin,
  changePremiumLevy,
  changeProductDevelopmentFee,
  changeSumInsured,
  changeTargetLossRatioValue,
  changeTargetLossRatioselection,
  changeValueChain,
  changeWithholdingTax,
  changecommercialpremiumRateType,
} from "../../redux/action";

const columns = [
  { header: "Product Type", accessorKey: "product_type" },
  { header: "Product Name", accessorKey: "product_name" },
  { header: "Country", accessorKey: "country" },
  {
    header: "Risk Covered",
    accessorKey: "cover_1_risk_type",
    Cell: ({ row }) => {
      const { cover_1_risk_type, cover_2_risk_type } = row.original;
      return cover_2_risk_type
        ? `${cover_1_risk_type}, ${cover_2_risk_type}`
        : cover_1_risk_type;
    },
  },
  { header: "Cover 1 Start Date", accessorKey: "cover_1_start_date" },
  { header: "Cover 1 End Date", accessorKey: "cover_1_end_date" },
];

const PricingTable = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const coverDetails = useSelector((state) => state.coverDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      try {
        const response = await axios.get(
          `/api/pricing/viewPolicy?email=${email}`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleManualOption = () => {
    dispatch(changeStep(1));
    dispatch(changeEditingState(false));
    dispatch(changeshowManual(true));
    dispatch(changeshowuploadsite(false));
    dispatch(changeIUAnalysisPremium(null));
    dispatch(changeIUAnalysisCommercial(null));
    dispatch(changeIUList([]));
    dispatch(changeNewIUList([]));
    const emptyCoverDetails = Object.keys(coverDetails).reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {});

    dispatch(updateCoverDetails(emptyCoverDetails));
    dispatch(changeTypeOfProduct(""));
    dispatch(changeProductName(""));
    dispatch(changeSavedPolicyDetails(null));
    dispatch(changeDeductibleType(null));
    dispatch(changeDeductiblePercentage(null));
    dispatch(changePricingMethod(null));
    dispatch(changeNumberOfCovers(1));
    dispatch(changeCoverName(null));
    dispatch(changeRiskCovered(null));
    dispatch(changeDatasets(null));
    dispatch(changecoverstartDate(null));
    dispatch(changecoverendDate(null));
    dispatch(changeWeightage(null));
    dispatch(changeCoverPeriodType(null));
    dispatch(changeBlockSize(null));
    dispatch(changeRiskInterval(null));
    dispatch(changeWithinBlockCalc(null));
    dispatch(changeacrossBlockCalc(null));
    dispatch(changecoverMinPayout(0));
    dispatch(changecoverMaxPayout(0));
    dispatch(changeMethodofThreshold(null));
    dispatch(changeweatherTrigger(null));
    dispatch(changeweatherExit(null));
    dispatch(changecommercialpremiumRateType(null));
    dispatch(changeTargetLossRatioselection(null));
    dispatch(changeTargetLossRatioValue(null));
    dispatch(changeInsurerMargin(null));
    dispatch(changeProductDevelopmentFee(null));
    dispatch(changeBrokerFee(null));
    dispatch(changePremiumLevy(null));
    dispatch(changeWithholdingTax(null));
    dispatch(changeAggregator(null));
    dispatch(changeInsurer(null));
    dispatch(changeCurrency(null));
    dispatch(changeSumInsured(null));
    dispatch(changeValueChain(null));
    handleClose();
  };

  const handleRowDoubleClick = async (row) => {
    dispatch(changeEditingState(true));

    const user = await Auth.currentAuthenticatedUser();
    const email = user.attributes.email;
    const { product_type, product_name } = row.row.original;

    try {
      const response = await axios.post("/api/pricing/getAllPolicyDetails", {
        email,
        product_type,
        product_name,
      });
      dispatch(changeSavedPolicyDetails(response.data));
      dispatch(changeshowManual(true));
      dispatch(changeshowuploadsite(false));
      dispatch(changeIUAnalysisPremium(null));
      dispatch(changeIUAnalysisCommercial(null));
      dispatch(changeStep(1));
      dispatch(changeNewIUList([]));
      dispatch(updateCoverDetails({}));
    } catch (error) {
      console.error("Error fetching policy details:", error);
    }
  };

  const filteredData = useMemo(() => {
    if (!searchText) return data;
    const lowercasedSearchText = searchText.toLowerCase();
    return data.filter((row) =>
      Object.values(row).some((value) =>
        value
          ? value.toString().toLowerCase().includes(lowercasedSearchText)
          : false
      )
    );
  }, [searchText, data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: 2,
        width: "90%",
        marginLeft: "78px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          mb: 2,
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ width: "400px" }}
        />
        <Button
          variant="contained"
          onClick={handleManualOption}
          style={{ background: "black" }}
          startIcon={
            <img
              src="analytics.png"
              alt="Project Icon"
              style={{ height: "20px", width: "20px", margin: 5 }}
            />
          }
        >
          Calculate Pricing
        </Button>
      </Box>
      <MaterialReactTable
        columns={columns}
        data={filteredData}
        options={{
          paging: true,
        }}
        muiTableHeadCellProps={{
          sx: {
            background: "rgba(41, 41, 5, 0.03)",
          },
        }}
        enableTopToolbar={false}
        muiTableBodyRowProps={(row) => ({
          onDoubleClick: (event) => {
            handleRowDoubleClick(row);
          },
        })}
      />
    </Box>
  );
};

export default PricingTable;
