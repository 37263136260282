import { useEffect, useState } from "react";
import { useMap } from "../../hooks/useMap";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import { Icon, Style } from "ol/style";
import VisualDataset from "./VisualDataset";
import { useDispatch, useSelector } from "react-redux";
import { changeLevel1Name, changegetlocationbysearch } from "../../redux/action";
import { changeDistrictName, changeLevel2Name, changeLevel3Name, changeLevel4Name, changeStateName } from "../../redux/action";

function SearchLocation(props) {
  const { map } = useMap();
  const [result, setResult] = useState();
  const [selectedLoc, setSelectedLoc] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [markerLayer, setMarkerLayer] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState("Location");
  const [exploreDataset, setExploreDataset] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!map) return;
    // Initialize marker layer
    const markerSource = new VectorSource();
    const markerLayer = new VectorLayer({
      source: markerSource,
    });
    map.addLayer(markerLayer);
    setMarkerLayer(markerLayer);

    return () => {
      map.removeLayer(markerLayer);
      setResult();
      props.setSelectedLoc();
    };
  }, []);

  const search = (e) => {
    let locationQuery = e.target.value;
    if (locationQuery.length > 2) {
      setIsLoading(true);
      fetch(
        "api/coords/search?query=" +
          locationQuery ,
      )
        .then((response) => response.json())
        .then((data) => {
          setResult(data);
          setIsLoading(false);
        });
    } else {
      setResult();
      props.setSelectedLoc();
    }
  };

  const zoomToLocation = (location) => {
    dispatch(changegetlocationbysearch(location));
    props.setSelectedLoc(`${location.Country}, ${location.Name_1}, ${location.Name_2}, ${location.Name_3}, ${location.Name_4}`);
    props.setSelectedLocation(`${location.Country}, ${location.Name_1}, ${location.Name_2}, ${location.Name_3}, ${location.Name_4}`);
   
    // Clear previous markers
    // markerLayer.getSource().clear();
    // // Add new marker
    // const coordinates = [parseFloat(location.lon), parseFloat(location.lat)];
    // const marker = new Feature({
    //   geometry: new Point(fromLonLat(coordinates)),
    // });
    // marker.setStyle(
    //   new Style({
    //     image: new Icon({
    //       src: "/location-pin.png",
    //     }),
    //   })
    // );
    // markerLayer.getSource().addFeature(marker);
    // map.getView().animate({ center: coordinates, zoom: 10, duration: 1000 });
    setResult(); // Clear the search results after selecting a location
  };

 
  const handleResetFields = () => {
    
    props.setShowAdministrativeBoundary(false);
    props.setSelectedLocation("Location");
    props.setSelectedLoc('')
    map.removeLayer(markerLayer);
  };

  return (
    <>
      <div className="menuContent">
        <div className="exploretext">
          <img
            src="chevron-left.png"
            alt="location icon"
            style={{ marginRight: 10 }}
            onClick={() => 
              {props.showSearchLocation(false);
                setResult();
              }}
          />
          Search region or location
        </div>
        <div className="exploresecondarytext">Search your location.</div>

        <div>
        <input
            type="text"
            className="inputArea"
            placeholder="Search..."
            style={{ marginBottom: "10px" }}
            value={props.selectedLoc}  // Updated value attribute
            onChange={search}
          />
        </div>
        {!isLoading ? (
          result?.length > 0 && (
            <ul className="searchResult">
              {result.map((item, index) => (
                <li key={index} onClick={() => {zoomToLocation(item);
                if (item.Country!= '') {
                  props.setCountryName(item.Country || "");
                }
                if (item.Name_1 != '') {
                  dispatch(changeStateName(item.Name_1 || ""));
                  dispatch(changeLevel1Name(item.Name_1 || ""));

                }
                if (item.Name_2 != '') {
                  dispatch(changeDistrictName(item.Name_2 || ""));
                  dispatch(changeLevel2Name(item.Name_2 || ""));

                }
                if (item.Name_3 != '') {
                  dispatch(changeLevel3Name(item.Name_3|| ""));
                }
                if (item.Name_4 != '') {
                  dispatch(changeLevel4Name(item.Name_4|| ""))
                }}}>
                  {item.Country}, {item.Name_1}, {item.Name_2}, {item.Name_3}, {item.Name_4}
                </li>
              ))}
            </ul>
          )
        ) : (
          <p>Searching...</p>
        )}

       <div className="exploredataset-button-container">
          <div>
            <hr className="horizontal-rule" />
            <button className="selectedloc-button">
              <img src="loc-pin.png" alt="icon" style={{ marginRight: 5 }} />
              {props.selectedLocation}
              <img
                src="tabler-icon-x.svg"
                className="reset-button"
                alt="Reset"
                onClick={handleResetFields}
              />
            </button>
          </div>

          <div>
            <button
               className="exploredataset-button"
              onClick={() => {
                setExploreDataset(true);
              }
              }
              disabled={props.selectedLocation === "Location"}
            >
              Explore Datasets
            </button>
          </div>
        </div>
      </div>
      {exploreDataset && (
        <VisualDataset
          tamsatDailyGraph={props.tamsatDailyGraph}
          setTamsatDailyGraph={props.setTamsatDailyGraph}
          tamsatMonthlyGraph={props.tamsatMonthlyGraph}
          setTamsatMonthlyGraph={props.setTamsatMonthlyGraph}
          chirps5kmMonthlyRainfall={props.chirps5kmMonthlyRainfall}
          setChirps5kmMonthlyRainfall={props.setChirps5kmMonthlyRainfall}
          arc210kmDailyRainfall={props.arc210kmDailyRainfall}
          setarc210kmDailyRainfall={props.setarc210kmDailyRainfall}
          modis5DayIntervalNDVIGraph={props.modis5DayIntervalNDVIGraph}
          setModis5DayIntervalNDVIGraph={props.setModis5DayIntervalNDVIGraph}
          landcover={props.landcover}
          setlandcover={props.setlandcover}
          chirps5kmDailyRainfall={props.chirps5kmDailyRainfall}
          setChirps5kmDailyRainfall={props.setChirps5kmDailyRainfall}
          era5DailyRainfallGraph={props.era5DailyRainfallGraph}
          setera5DailyRainfallGraph={props.setera5DailyRainfallGraph}
          era5MonthlyRainfallGraph={props.era5MonthlyRainfallGraph}
          setera5MonthlyRainfallGraph={props.setera5MonthlyRainfallGraph}
          era5DailyTemperatureGraph={props.era5DailyTemperatureGraph}
          setera5DailyTemperatureGraph={props.setera5DailyTemperatureGraph}
          era5MonthlyTemperatureGraph={props.era5MonthlyTemperatureGraph}
          setera5MonthlyTemperatureGraph={props.setera5MonthlyTemperatureGraph}
          gsMapDaily={props.gsMapDaily}
          setgsMapDaily={props.setgsMapDaily}
          gsMapMonthly={props.gsMapMonthly}
          setgsMapMonthly={props.setgsMapMonthly}
          sentinal2NDVI={props.sentinal2NDVI}
          setsentinal2NDVI={props.setsentinal2NDVI}
          selectedLocation={selectedLocation}
          countryName={props.countryName}
          setIsDrawerOpen={props.setIsDrawerOpen}
          setExploreDataset={setExploreDataset}
        />
      )}
    </>
  );
}

export default SearchLocation;
