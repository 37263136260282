import React, { useState } from "react";
import '../../login.css'; 
export const Forgetpass = (props) => {
const [emailError, setEmailError] = useState('');
const [errorMessage, setErrorMessage] = useState('');

const handleEmailChange = (e) => {
    props.setEmail(e.target.value);
    setEmailError('');
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (props.ForgetPasswordComp) {
      // Handle regular login form submission
    if (!props.email) {
        setEmailError('Please enter email');
        return;
      } 
       else {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(props.email)) {
          setEmailError('Please enter email in the correct format');
          return;
        }
      }
    } 
    props.setResetPasswordComp(true)
    setEmailError('');
    setErrorMessage('');
  };
  return (
    <>
     <div className="login-right">
            <div className='loginpannel'>
              <div className='loginheading'>Forgot password?</div>
              <div className='subheading'>We’ll send a verification code to this email or phone number if it matches an existing account.</div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email" style={{ color: emailError ? 'red' : '' }}>Email</label>
                  <input
                    className={`logininput ${emailError ? 'error-input' : ''}`}
                    type="text"
                    id="email"
                    value={props.email}
                    onChange={handleEmailChange}
                    style={{ height: '36px', borderColor: emailError ? 'red' : '' }}
                  />
                  {emailError && <div className="error-message">{emailError}</div>}
                </div>
                <button type="submit" className='loginbutton' style={{ backgroundColor: 'black', marginBottom: '28px' }} disabled={!!emailError}>Send Code</button>
                <div className="backtologin"
                  onClick={() =>
                    {
                    props.setForgetPasswordComp(false)
                    props.setLoginscreen(true)
                    }
                  }>
                  <img src="chevron-left.png" alt="location icon" style={{height:'16px',width:'16px' }} />
                  <div> Back to login</div>
                </div>
              </form>
            </div>
          </div>
    </>
  );
};
