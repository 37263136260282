import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DoubleGraphTempMonthly = ({ data1, data2 }) => {
    useEffect(() => {
        const options = {
            chart: {
                type: 'area',
                zoomType: 'x', 
            },
           
            credits:false,
            title: {
                text: ''
            },
            xAxis: {
              categories: data1.map(({ Month }) => Month),
              title: {
                text: 'Months',
              },
            },
            yAxis: {
                title: {
                    text: 'Temperature (°C)'
                }
            },
        
            series: [
                {
                    name: 'Current',
                    data: data1.map(({ Month, temperature }) => ({ name: Month, y: temperature })),
                    color: {
                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                        opacity: 0.2,
                        stops: [
                          [0, '#EBBC00'], // start color
                          [1, 'rgba(230, 184, 82, 0.40)'] // end color
                        ]
                      },
                },
                {
                    name: 'Historical',
                    data: data1.map(({ Month, temperature }) => ({ name: Month, y: temperature })),
                    color: {
                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                        opacity: 0.4,
                        stops: [
                          [0, '#01619E'], // start color
                          [1, 'rgba(1, 97, 158, 0.30)'] // end color
                        ]
                      },
                }
            ]
        };

        Highcharts.chart('double-area-temp-chart', options);
    }, [data1, data2]);

    return <div id="double-area-temp-chart" />;
};

export default DoubleGraphTempMonthly;
