
export const changecoverstartDateReducer = (state = "", action) => {
    switch (action.type) {
      case "CHANGE_START_DATE":
        return action.payload.coverstartDate;
      default:
        return state;
    }
  };
  
  