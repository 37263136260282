import React, { useState, useEffect } from "react";
import "../styles.css";
import { Nav, NavItem } from "reactstrap";
import "../styles.css";

export const Sidebar = (props) => {
  const [selectedMenu, setSelectedMenu] = useState("");

  useEffect(() => {
    if (props.selectedIcon === "Pricing") {
      props.setMenu("Project");
      setSelectedMenu("Project");
    } else {
      props.setMenu("Explore");
      setSelectedMenu("Explore");
    }
  }, [props.selectedIcon]);

  const handleClick = (menu) => {
    setSelectedMenu(menu);
    props.setMenu(menu);
  };

  return (
    <>
      <div
        className="sidebar-sticky d-none d-md-block"
        style={{ marginTop: "48px", zIndex: 1000 }}
      >
        <Nav className="flex-column" style={{ listStyle: "none" }}>
          <div
            className="nav-links"
            onClick={() => {
              props.setMenu("Explore");
            }}
          >
            {props.selectedIcon === "Climate Data Library" ? (
              <NavItem
                className="mb-2 d-flex align-items-center justify-content-center"
                style={{
                  // background: selectedMenu === "Explore" ? "orange" : "none",
                  cursor: "pointer",
                  marginLeft: 10,
                  borderRadius: "5px",
                  marginBottom: "24px",
                }}
                onClick={() => handleClick("Explore")}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ marginLeft: "5px" }}
                >
                  <div
                    className="menuicon"
                    style={{
                      background:
                        selectedMenu === "Explore" ? "#F76808" : "none",
                    }}
                  >
                    {selectedMenu === "Explore" ? (
                      <div style={{ margin: 5 }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11.1567 16.5243L9.36754 15.6297M9.36754 15.6297L4 18.3134V6.68377L9.36754 4M9.36754 15.6297V4M9.36754 4L14.7351 6.68377M14.7351 6.68377L20.1026 4V10.7094M14.7351 6.68377V11.1567M19.3897 18.4917L21 20.1019M14.7351 16.5243C14.7351 17.236 15.0178 17.9187 15.5211 18.422C16.0244 18.9253 16.7071 19.208 17.4188 19.208C18.1306 19.208 18.8133 18.9253 19.3166 18.422C19.8199 17.9187 20.1026 17.236 20.1026 16.5243C20.1026 15.8125 19.8199 15.1299 19.3166 14.6265C18.8133 14.1232 18.1306 13.8405 17.4188 13.8405C16.7071 13.8405 16.0244 14.1232 15.5211 14.6265C15.0178 15.1299 14.7351 15.8125 14.7351 16.5243Z"
                            stroke="white"
                            strokeWidth="1.20764"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    ) : (
                      <img
                        src="explore.png"
                        alt="Explore Icon"
                        style={{ height: "24px", width: "24px", margin: 5 }}
                      />
                    )}
                  </div>
                  <div className="menutext">Explore</div>
                </div>
              </NavItem>
            ) : null}
          </div>
          <div
            className="nav-links"
            onClick={() => {
              props.setMenu("Project");
            }}
          >
            <NavItem
              className="mb-2 d-flex align-items-center justify-content-center"
              style={{
                cursor: "pointer",
                marginLeft: 10,
                borderRadius: "5px",
                marginBottom: "24px",
              }}
              onClick={() => handleClick("Project")}
            >
              <div
                className="d-flex align-items-center"
                style={{ marginLeft: "5px" }}
              >
                <div
                  className="menuicon"
                  style={{
                    background: selectedMenu === "Project" ? "#F76808" : "none",
                  }}
                >
                  {selectedMenu === "Project" ? (
                    <img
                      src="analytics.png"
                      alt="Project Icon"
                      style={{ height: "24px", width: "24px", margin: 5 }}
                    />
                  ) : (
                    <img
                      src="Analyticsnots.png"
                      alt="Project Icon"
                      style={{ height: "24px", width: "24px", margin: 5 }}
                    />
                  )}
                </div>
                <div className="menutext">Analytics</div>
              </div>
            </NavItem>
          </div>
          <div
            className="nav-links"
            onClick={() => {
              props.setMenu("Download");
            }}
          >
            {props.selectedIcon === "Climate Data Library" ? (
              <NavItem
                className="mb-2 d-flex align-items-center justify-content-center"
                style={{
                  // background: selectedMenu === "Download" ? "orange" : "none",
                  cursor: "pointer",
                  marginLeft: 10,
                  borderRadius: "5px",
                  marginBottom: "24px",
                }}
                onClick={() => handleClick("Download")}
              >
                <div className="d-flex align-items-center">
                  <div
                    className="menuicon"
                    style={{
                      background:
                        selectedMenu === "Download" ? "#F76808" : "none",
                    }}
                  >
                    {selectedMenu === "Download" ? (
                      <div style={{ margin: 5 }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12.5 20.1053H5.78947C5.31488 20.1053 4.85972 19.9167 4.52412 19.5811C4.18853 19.2455 4 18.7904 4 18.3158V5.78947C4 5.31488 4.18853 4.85972 4.52412 4.52412C4.85972 4.18853 5.31488 4 5.78947 4H18.3158C18.7904 4 19.2455 4.18853 19.5811 4.52412C19.9167 4.85972 20.1053 5.31488 20.1053 5.78947V12.5M4 10.2632H20.1053M10.2632 4V20.1053M18.3158 15.6316V21M18.3158 21L21 18.3158M18.3158 21L15.6316 18.3158"
                            stroke="#FEFCFB"
                            strokeWidth="1.26"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    ) : (
                      <img
                        src="download-data.svg"
                        alt="Download Icon"
                        style={{ height: "24px", width: "24px", margin: 5 }}
                      />
                    )}
                  </div>
                  <div className="menutext">Download</div>
                </div>
              </NavItem>
            ) : null}
          </div>
        </Nav>
      </div>
    </>
  );
};
