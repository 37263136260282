import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const IUanalysisChart = ({ data }) => {
  const options = {
    chart: {
      type: 'bar'
    },
    title: {
      text: '',
      align: 'left',
      x: 70 
    },
    xAxis: {
      categories: data.map(item => item.level),
      title: {
        text: '',
        align: 'middle'
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Pure Premium',
        align: 'middle'
      },
      labels: {
        overflow: 'justify'
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        },
        color: '#F3D766'
      }
    },
    series: [
      {
        name: 'Pure Premium',
        data: data.map(item => item.pure_premium)
      }
    ],
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default IUanalysisChart;
