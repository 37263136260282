import { useEffect, useState } from 'react';
import { useMap } from '../../hooks/useMap';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON.js';
import VectorSource from 'ol/source/Vector';
import Select from 'react-select';
import { Style, Stroke, Fill } from 'ol/style';
import VisualPixelDataset from './VisualPixelDataset';
import { changePixelName } from '../../redux/action';
import { useDispatch, useSelector } from "react-redux";

function SearchByPixel(props) {
	const dispatch = useDispatch();
	const { map } = useMap();
	const pixelName = useSelector((state) => state.pixelName)
	const [layerName, setLayerName] = useState(null);
	const [pixelLayer, setPixelLayer] = useState(null);

	useEffect(() => {
		if (!map) return;
		// Initialize pixel layer
		const pixelSource = new VectorSource();
		const pixelLayer = new VectorLayer({
			source: pixelSource,
			style: new Style({
				stroke: new Stroke({
					color: 'blue',
					width: 3,
				}),
				fill: new Fill({
					color: 'rgba(0, 0, 255, 0)',
				}),
			}),
		});
		pixelLayer.set('name', 'pixelLayer');
		map.addLayer(pixelLayer);
		setPixelLayer(pixelLayer);

		return () => {
			// Remove when component unmounts
			map.removeLayer(pixelLayer);
		};
	}, []);

	const searchPixel = async () => {
		pixelLayer.getSource().clear();

		const response = await fetch(
			`https://cdl.agtuall.com/geoserver/agtull/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=agtull:${layerName}&CQL_FILTER=PixelNames='${pixelName}'&outputFormat=application/json`
		);
		const geojsonObject = await response.json();
		const pixelSource = new VectorSource({
			features: new GeoJSON().readFeatures(geojsonObject),
		});

		pixelLayer.setSource(pixelSource);

		// Center the map on the layer
		map.getView().fit(pixelLayer.getSource().getExtent(), {
			padding: [50, 50, 50, 50],
			duration: 1000,
		});
	};

	const customStyles = {
		control: (base, state) => ({
			...base,
			borderColor: state.isFocused ? 'orange' : base.borderColor,
			'&:hover': {
				borderColor: state.isFocused ? 'orange' : base.borderColor,
			},
			minHeight: 28,
		}),
		dropdownIndicator: (base) => ({
			...base,
			padding: 4,
		}),
		valueContainer: (base) => ({
			...base,
			padding: '0px 6px',
		}),
	};

	return (
		<>
			<div className='menuContent'>
				<div className='exploretext'>
					<img
						src='chevron-left.png'
						alt='location icon'
						style={{ marginRight: 10 }}
						onClick={() => props.setShowSearchByPixel(false)}
					/>
					Search by Pixel Name
				</div>
				<div className='exploresecondarytext'>Enter the pixel name.</div>
				<div>
					<input
						type='text'
						className='inputArea'
						placeholder='Enter Pixel Name'
						style={{ marginBottom: '10px' }}
						onChange={(e) => dispatch(changePixelName(e.target.value))}
					/>
					<div className='dropdown-container'>
						<Select
							className='custom-select'
							onChange={(e) => {
								setLayerName(e.value);
							}}
							options={[
								{ value: '5km_grid', label: '5km Grid' },
							]}
							placeholder='Select Layer'
							styles={customStyles}
							isDisabled={pixelName === ''}
						/>
					</div>
				</div>

				<div className='exploredataset-button-container'>
					<div>
						<button
							className='exploredataset-button'
							onClick={() => {
								props.setExplorePixelDataset(true);
								searchPixel();
							}}
							disabled={pixelName === '' || layerName === null}
						>
							Explore Datasets
						</button>
					</div>
				</div>
			</div>

			{props.explorePixelDataset && (
				<VisualPixelDataset
					tamsatDailyGraph={props.tamsatDailyGraph}
					setTamsatDailyGraph={props.setTamsatDailyGraph}
					tamsatMonthlyGraph={props.tamsatMonthlyGraph}
					setTamsatMonthlyGraph={props.setTamsatMonthlyGraph}
					chirps5kmMonthlyRainfall={props.chirps5kmMonthlyRainfall}
					setChirps5kmMonthlyRainfall={props.setChirps5kmMonthlyRainfall}
					arc210kmDailyRainfall={props.arc210kmDailyRainfall}
					setarc210kmDailyRainfall={props.setarc210kmDailyRainfall}
					modis5DayIntervalNDVIGraph={props.modis5DayIntervalNDVIGraph}
					setModis5DayIntervalNDVIGraph={props.setModis5DayIntervalNDVIGraph}
					setExplorePixelDataset={props.setExplorePixelDataset}
					explorePixelDataset={props.explorePixelDataset}
					landcover={props.landcover}
					setlandcover={props.setlandcover}
					chirps5kmDailyRainfall={props.chirps5kmDailyRainfall}
					setChirps5kmDailyRainfall={props.setChirps5kmDailyRainfall}
					era5DailyRainfallGraph={props.era5DailyRainfallGraph}
					setera5DailyRainfallGraph={props.setera5DailyRainfallGraph}
					era5MonthlyRainfallGraph={props.era5MonthlyRainfallGraph}
					setera5MonthlyRainfallGraph={props.setera5MonthlyRainfallGraph}
					era5DailyTemperatureGraph={props.era5DailyTemperatureGraph}
					setera5DailyTemperatureGraph={props.setera5DailyTemperatureGraph}
					era5MonthlyTemperatureGraph={props.era5MonthlyTemperatureGraph}
					setera5MonthlyTemperatureGraph={props.setera5MonthlyTemperatureGraph}
					gsMapDaily={props.gsMapDaily}
					setgsMapDaily={props.setgsMapDaily}
					gsMapMonthly={props.gsMapMonthly}
					setgsMapMonthly={props.setgsMapMonthly}
					sentinal2NDVI={props.sentinal2NDVI}
					setsentinal2NDVI={props.setsentinal2NDVI}
					countryName={props.countryName}
					setIsDrawerOpen={props.setIsDrawerOpen}
				/>
			)}
		</>
	);
}

export default SearchByPixel;
