import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PieChart = ({ data }) => {
  useEffect(() => {
    const chartData = Object.entries(data).map(([name, value]) => ({
      name,
      y: value,
    }));

    const blueShades = [];
    for (let i = 0; i < chartData.length; i++) {
      const opacity = 0.2 + i * 0.1; 
      blueShades.push(`rgba(1, 97, 158, ${opacity})`);
    }

    Highcharts.chart("pie-chart-container", {
      chart: {
        type: "pie",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          colors: blueShades,
        },
      },
      series: [
        {
          data: chartData,
        },
      ],
    });
  }, [data]);

  return <div id="pie-chart-container" />;
};

export default PieChart;
