import React, { useState } from "react";
import '../../login.css'; 

export const Passwordreset = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [showError, setShowError] = useState(false);
  
  const inputStyle = {
    display: 'flex',
    width: '49px',
    height: '60px',
    padding: '10.667px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginRight: 10.667,
    borderRadius: '8px',
    border: showError ? '1.333px solid red' : '1.333px solid rgba(26, 26, 0, 0.16)',
  };

  const handleVerificationCodeChange = (index, value) => {
    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode);
  };

  const handleVerificationCodeSubmit = (e) => {
    e.preventDefault();
    const filledFields = verificationCode.filter(code => code !== '').length;
    if (filledFields === 0) {
      setErrorMessage('Please enter a code.');
      setShowError(true);
    } else if (filledFields < verificationCode.length) {
      setErrorMessage('Wrong code.');
      setShowError(true);
    } else {
      props.setNewPasswordComp(true);
    }
  };

  return (
    <div className="login-right">
      <div className='loginpannel'>
        <div className='loginheading'>Password reset</div>
        <div className='subheading'>A verification code has been sent to {props.email}.</div>
        <div className='subheading2'>Enter verification code</div>
        <form onSubmit={handleVerificationCodeSubmit}>
          <div className="verification-code-container" style={{ display: 'flex' }}>
            {verificationCode.map((code, index) => (
              <input
                key={index}
                type="text"
                className="verification-code-input"
                maxLength={1}
                style={{ ...inputStyle  }}
                value={code}
                onChange={(e) => handleVerificationCodeChange(index, e.target.value)}
              />
            ))}
          </div>
          {(errorMessage ) && <div className="error-message" style={{ marginBottom: '10px', color: 'red' }}>{errorMessage}</div>}
          <button type="submit" className='loginbutton' style={{ backgroundColor:  'black'  , marginBottom: '28px', marginTop: '48px' }}>Submit</button>
          <div className='subheading2' style={{textAlign:'center'}}>Didn’t receive an email? Resend code</div>
          <div className="backtologin" onClick={() => {
            props.setResetPasswordComp(false);
            props.setLoginscreen(true);
            props.setForgetPasswordComp(false);
          }}>
            <img src="chevron-left.png" alt="location icon" style={{height:'16px',width:'16px' }} />
            <div> Back to login</div>
          </div>
        </form>
      </div>
    </div>
  );
};
