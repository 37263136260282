import React, { useRef, useState, useEffect } from "react";
import { Tile, Group } from "ol/layer";
import { XYZ, OSM } from "ol/source";
import * as ol from "ol";
import "ol/ol.css";
import { useMap } from "../../../../hooks/useMap";
import ScaleLine from "ol/control/ScaleLine";

const MyMap = ({ children, zoom, center, getMapRef }) => {
  const { map, setMap } = useMap();
  const mapRef = useRef();
  React.useEffect(() => {
    let xyz = new Tile({
      source: new XYZ({
        url:
        "https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=hDIdtmulJI4evYNmtNPG",
        tileSize: 512,
        crossOrigin: 'anonymous'
      }),

      baseLayer: true,
      visible: true,
      title: "osm",
      name: "baseMapLayer",
    });

    let op = {
      view: new ol.View({
        projection: "EPSG:4326",
        zoom,
        center: center,
      }),
      controls: [
        new ScaleLine({
          units: "metric",
          bar: true,
          steps: 4,
          text: true,
          minWidth: 140,
        }),
      ],
      layers: [xyz],
    };
    let mapObj = new ol.Map(op);
    mapObj.setTarget(mapRef.current);
    setMap(mapObj);
    getMapRef(mapRef.current);

    return () => {
      mapObj.setTarget(undefined);
      setMap(null);
    };
  }, []);

  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(center);
  }, [center]);

  useEffect(() => {
    if (!map) return;
    map.getView().animate({ zoom: zoom, duration: 1000 });
  }, [zoom]);

  return (
    <div ref={mapRef} style={{ position: "absolute", inset: 0 }}>
      {children}
    </div>
  );
};
export default MyMap;
