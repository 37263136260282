import { getArea } from "ol/sphere";
import { Style, Stroke, Fill, Text } from "ol/style";

export const getLayerByName = (map, layerName) => {
  if (!map) return;
  var layers = map.getLayers().getArray();

  for (var i = 0; i < layers.length; i++) {
    if (layers[i].get("name") === layerName) {
      return layers[i];
    }
  }
};

export const removeLayerByName = (map, layerName) => {
  const layer = getLayerByName(map, layerName);
  map.removeLayer(layer);
};

export const calculateArea = function (polygon) {
  const cloned = polygon.clone();
  cloned.transform("EPSG:4326", "EPSG:3857");
  const area = getArea(cloned) / 10000;
  return area;
};

export const getAvgColor = (feature, selectedState) => {
  const gradientColors = [
    "#30ad43", // Green
    "#84bd32", // Light green
    "#fdbf20", // Yellow
    "#f3af00", // Orange
    "#f88", // Red
    "#ff5656", // Light Red
  ];
  let flood_index = feature.getProperties().flood_index;
  let spi_index = feature.getProperties().spi_index;
  if (flood_index && spi_index) {
    const style = new Style({
      stroke: new Stroke({
        color: "#fff",
        width: 1,
      }),
      text: new Text({
        font: "bold 14px",
        text: selectedState
          ? feature.getProperties().NAME_2
          : feature.getProperties().NAME_1, // Change this to the label you want
        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 1,
        }),
      }),
      fill: new Fill({
        color: gradientColors[Math.round((flood_index + spi_index) / 2) - 1],
      }),
    });
    return style;
  } else {
    return new Style({
      stroke: new Stroke({
        color: "#fff",
        width: 1,
      }),
      text: new Text({
        font: "bold 14px",
        text: selectedState
          ? feature.getProperties().NAME_2
          : feature.getProperties().NAME_1, // Change this to the label you want
        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 1,
        }),
      }),
      fill: new Fill({
        color: "#ccc",
      }),
    });
  }
};

export const getFlood_indexColor = (index) => {
  if (index < 1) {
    return "#30ad43";
  } else if (index >= 1 && index < 2) {
    return "#84bd32";
  } else if (index >= 2 && index < 3) {
    return "#fdbf20";
  } else if (index >= 3 && index < 4) {
    return "#f3af00";
  } else if (index >= 4 && index <= 5) {
    return "#ff5656";
  } else {
    return "#000";
  }
};

export const getFloodColor = (feature, selectedState, select) => {
  let flood_index = feature.getProperties().flood_index;
  if (flood_index) {
    const style = new Style({
      stroke: new Stroke({
        color: "#fff",
        width: select ? 5 : 1,
      }),
      text: new Text({
        font: "bold 14px",
        text: selectedState
          ? feature.getProperties().NAME_2
          : feature.getProperties().NAME_1, // Change this to the label you want
        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 1,
        }),
      }),
      fill: new Fill({
        color: getFlood_indexColor(flood_index),
      }),
    });
    return style;
  } else {
    return new Style({
      stroke: new Stroke({
        color: "#fff",
        width: 1,
      }),
      text: new Text({
        font: "bold 14px",
        text: selectedState
          ? feature.getProperties().NAME_2
          : feature.getProperties().NAME_1, // Change this to the label you want
        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 1,
        }),
      }),
      fill: new Fill({
        color: "#ccc",
      }),
    });
  }
};

export const getSpiColor = (feature, selectedState, select) => {
  let spi_index = feature.getProperties().spi_index;
  if (spi_index) {
    const style = new Style({
      stroke: new Stroke({
        color: "#fff",
        width: select ? 5 : 1,
      }),
      text: new Text({
        font: "bold 14px",
        text: selectedState
          ? feature.getProperties().NAME_2
          : feature.getProperties().NAME_1, // Change this to the label you want
        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 1,
        }),
      }),
      fill: new Fill({
        color: getSpi_indexColor(spi_index),
      }),
    });
    return style;
  } else {
    return new Style({
      stroke: new Stroke({
        color: "#fff",
        width: 1,
      }),
      text: new Text({
        font: "bold 14px",
        text: selectedState
          ? feature.getProperties().NAME_2
          : feature.getProperties().NAME_1, // Change this to the label you want
        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 1,
        }),
      }),
      fill: new Fill({
        color: "#ccc",
      }),
    });
  }
};

export const getSpi_indexColor = (ind) => {
  const index = Math.ceil(ind * 10) / 10;
  if (index <= -2.0) {
    return "#730000";
  } else if (index >= -1.9 && index <= -1.6) {
    return "#E60000";
  } else if (index >= -1.5 && index <= -1.3) {
    return "#FFAA00";
  } else if (index >= -1.2 && index <= -0.8) {
    return "#FCD37F";
  } else if (index >= -0.7 && index <= -0.5) {
    return "#FFFF00";
  } else if (index >= -0.4 && index <= 0.4) {
    return "#fff";
  } else if (index >= 0.5 && index <= 0.7) {
    return "#AAFF55";
  } else if (index >= 0.8 && index <= 1.2) {
    return "#01FFFF";
  } else if (index >= 1.3 && index <= 1.5) {
    return "#00AAFF";
  } else if (index >= 1.6 && index <= 1.9) {
    return "#0000FF";
  } else if (index >= 2.0) {
    return "#0000AA";
  }
};
