import React, { useState, useEffect } from "react";
import Select from "react-select";
import "../../styles.css";
import { useDispatch, useSelector } from "react-redux";
//import { Auth } from "aws-amplify";
import {
  changeDistrictName,
  changeLevel3Name,
  changeLevel4Name,
  changeStateName,
  changeLevel1Name,
  changeLevel2Name,
  changeWeathervariable,
  changegetStartYears,
  changegetEndYears,
  changegetEndMonth,
  changegetStartMonth,
  changefrequency,
  changeCombination,
  changeRainfallDataset,
  changeTempDataset,
  changeVegetationDataset,
  changeCompareState,
} from "../../redux/action";
import { LuRefreshCcw } from "react-icons/lu";
import { useMap } from "../../hooks/useMap";
import { Select as olSelect } from "ol/interaction";
import { getFloodColor, getSpiColor } from "../../lib/gis";

const Risk = (props) => {
  const availableCountries = [
    "Tanzania",
    "Zambia",
    "Sudan",
    "Bangladesh",
    "Kenya",
  ];
  const [startYearDisabled, setstartYearDisabled] = useState(false);
  const [EndYearDisabled, setEndYearDisabled] = useState(false);
  const [startMonthDisabled, setstartMonthDisabled] = useState(false);
  const [EndMonthDisabled, setEndMonthDisabled] = useState(false);
  const [frequencyDisabled, setfrequencyDisabled] = useState(true);
  const getStartYears = useSelector((state) => state.getStartYears);
  const getStartMonth = useSelector((state) => state.getStartMonth);
  const [getStartMonthData, setgetStartMonthData] = useState([]);
  const getEndMonth = useSelector((state) => state.getEndMonth);
  const [getEndMonthData, setgetEndMonthData] = useState([]);
  const [getStartYearsData, setgetStartYearsData] = useState([]);
  const getEndYears = useSelector((state) => state.getEndYears);
  const [getEndYearsData, setgetEndYearsData] = useState([]);
  const [frequencyData, setfrequencyData] = useState([]);
  const frequency = ["Drought Index", "Flood Index", "SPI"];
  const timeperiod = ["Last year", "Current year", "Last 5 years"];
  const [timeperioddata, settimeperioddata] = useState(false);
  const compareState = useSelector((state) => state.compareState);
  const [index, setindex] = useState(false);
  const dispatch = useDispatch();
  const { map } = useMap();
  // const [selectClick, setSelectClick] = useState();

  // useEffect(() => {
  //   if (!map) return;

  // setSelectClick(new olSelect());
  // map.on("click", clickHandler);
  // }, [map, props.countryName]);

  // const clickHandler = (evt) => {
  //   // Get the features at the clicked pixel
  //   var features = map.getFeaturesAtPixel(evt.pixel);

  //   const selectedFeature = features[0]; // Assuming only one feature is selected
  //   if (selectedFeature) {
  //     const properties = selectedFeature.getProperties();
  //     if (!props.selectedState) {
  //       props.setSelectedState(properties.NAME_1);
  //     } else {
  //       props.setPopUpData(properties);
  //     }
  //   }
  // };

  useEffect(() => {
    if (!map) return;

    const selectClick = new olSelect();
    map.addInteraction(selectClick);
    selectClick.on("select", (event) => {
      const selectedFeature = event.selected[0]; // Assuming only one feature is selected
      if (selectedFeature) {
        const properties = selectedFeature.getProperties();
        const selectedStyle =
          props.countryName === "Zambia"
            ? getSpiColor(selectedFeature, !!props.selectedState, true)
            : getFloodColor(selectedFeature, !!props.selectedState, true);

        selectedFeature.setStyle(selectedStyle);
        if (!props.selectedState) {
          props.setSelectedState(properties.NAME_1);
        } else {
          props.setPopUpData(properties);
        }
      } else {
        props.setPopUpData();
      }
    });

    return () => {
      map.removeInteraction(selectClick);
    };
  }, [map, props.selectedState]);

  useEffect(() => {
    props.setcomparebutton(false);
    // console.log(props.comparebutton,'from useeffect')
    async function genToken() {
      if (props.countryName != null) {
        fetch("/api/compare/selectStartYear", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setgetStartYearsData(data);
            setstartYearDisabled(false);

            if (getStartYears != null) {
              fetch("/api/compare/selectEndYear?start_year=" + getStartYears, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  setgetEndYearsData(data);
                  setEndYearDisabled(false);

                  if (getEndYears !== null) {
                    fetch("/api/compare/selectStartMonth", {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json",
                        // Authorization: jwtToken,
                      },
                    })
                      .then((response) => response.json())
                      .then((data) => {
                        setgetStartMonthData(data);
                        setstartMonthDisabled(false);
                      });
                    if (getStartMonth !== null) {
                      fetch(
                        "/api/compare/selectEndMonth?start_month=" +
                          getStartMonth,
                        {
                          method: "GET",
                          headers: {
                            "Content-Type": "application/json",
                            // Authorization: jwtToken,
                          },
                        }
                      )
                        .then((response) => response.json())
                        .then((data) => {
                          setgetEndMonthData(data);
                          setEndMonthDisabled(false);
                        });
                      if (getEndYears !== null) {
                        fetch("/api/compare/selectFrequency", {
                          method: "GET",
                          headers: {
                            "Content-Type": "application/json",
                            // Authorization: jwtToken,
                          },
                        })
                          .then((response) => response.json())
                          .then((data) => {
                            setfrequencyData(data);
                            setfrequencyDisabled(false);
                          });
                      }
                    }
                  }
                });
            }
          });
      }
    }

    genToken();
  }, [
    props.countryName,
    getStartYears,
    getEndYears,
    getStartMonth,
    getEndMonth,
  ]);
  const handleComparebuttonlick = () => {
    // dispatch(changeCompareState(compareState + 1))
    props.setshowriskmap(true);
  };
  
  const handleResetbuttonlick = () => {
    props.setCountryName(null);
    props.setStateData(null);
    dispatch(changegetStartYears(null));
    dispatch(changegetEndYears(null));
    dispatch(changegetStartMonth(null));
    dispatch(changegetEndMonth(null));
    setindex(null);
    props.setSelectedState(null);
    props.setStateData(null);
    props.setPopUpData(null);
  };
  const countryFlags = {
    Tanzania: "flags/tzflag.png",
    Zambia: "flags/zambiaFlag.png",
    Sudan: "flags/Sudanflag.jpeg",
    Bangladesh: "flags/Bangladeshflag.png",
    Kenya: "flags/kenyaflag.webp",
  };

  let getStartyearsObject = [];
  let getEndyearsObject = [];
  let getStartMonthsObject = [];
  let getEndMonthsObject = [];

  let frequencyObject = [];
  let timeperiodobject = [];
  const countryObject = availableCountries.map((country) => ({
    label: (
      <div>
        <img
          src={countryFlags[country]}
          alt={`${country} flag`}
          style={{ width: 20, marginRight: 10 }}
        />
        {country}
      </div>
    ),
    value: country,
  }));

  for (let j in getStartYearsData) {
    let items = {};
    items["label"] = getStartYearsData[j];
    items["value"] = getStartYearsData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    getStartyearsObject.push(json_obj);
  }
  for (let j in getEndYearsData) {
    let items = {};
    items["label"] = getEndYearsData[j];
    items["value"] = getEndYearsData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    getEndyearsObject.push(json_obj);
  }
  for (let j in getStartMonthData) {
    let items = {};
    items["label"] = getStartMonthData[j];
    items["value"] = getStartMonthData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    getStartMonthsObject.push(json_obj);
  }
  for (let j in getEndMonthData) {
    let items = {};
    items["label"] = getEndMonthData[j];
    items["value"] = getEndMonthData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    getEndMonthsObject.push(json_obj);
  }
  for (let j in frequency) {
    let items = {};
    items["label"] = frequency[j];
    items["value"] = frequency[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    frequencyObject.push(json_obj);
  }
  for (let j in timeperiod) {
    let items = {};
    items["label"] = timeperiod[j];
    items["value"] = timeperiod[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    timeperiodobject.push(json_obj);
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "orange" : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? "orange" : base.borderColor,
      },
      minHeight: 25,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
  };

  return (
    <>
      <div className="compare-pannel">
        <div>
          <div className="exploretext">
            <img
              src="chevron-left.png"
              alt="location icon"
              style={{ marginRight: 10 }}
              onClick={() => {
                props.setShowrisk(false);
                handleResetbuttonlick();
              }}
            />
            Risk
          </div>
          <div className="compareecondarytext">
            Mark required selections to view available datasets.
          </div>
          <hr className="horizontal-rule" />
          <div className="dropdownlabel" style={{ marginBottom: "20px" }}>
            Location{" "}
          </div>
          <div className="download-container" style={{ marginBottom: "20px" }}>
            <Select
              className="custom-select"
              value={
                props.countryName
                  ? { label: props.countryName, value: props.countryName }
                  : null
              }
              onChange={(obj) => {
                props.setCountryName(obj.value);
                props.setSelectedState();
              }}
              options={countryObject}
              placeholder="Select Country"
            />
          </div>

          <div className="dropdownlabel" style={{ marginBottom: "20px" }}>
            Select state{" "}
          </div>
          <div className="download-container" style={{ marginBottom: "20px" }}>
            <Select
              className="custom-select"
              value={
                props.selectedState
                  ? { label: props.selectedState, value: props.selectedState }
                  : null
              }
              onChange={(obj) => {
                props.setSelectedState(obj.value);
              }}
              options={
                props.stateData
                  ? props.stateData.map((state) => ({
                      label: state.properties.NAME_1,
                      value: state.properties.NAME_1,
                    }))
                  : [{ label: "Select country", value: null }]
              }
              placeholder="Select State"
            />
          </div>

          {/* <div className="dropdownlabel" style={{ marginBottom: "20px" }}>
            Select Time Period
          </div>

          <div className="download-container">
            <Select
              className="custom-select"
              value={
                timeperioddata
                  ? { label: timeperioddata, value: timeperioddata }
                  : null
              }
              onChange={(obj) => {
                settimeperioddata(obj.value);
              }}
              options={timeperiodobject}
              placeholder="Select Time period"
              styles={customStyles}
              isDisabled={EndYearDisabled}
            />
          </div> */}

          {/* <div className="dropdownlabel" style={{ marginBottom: "20px" }}>
            Classification
          </div>
          <div className="download-container">
            <Select
              className="custom-select"
              value={index ? { label: index, value: index } : null}
              onChange={(obj) => {
                setindex(obj.value);
              }}
              options={frequencyObject}
              placeholder="Select"
              styles={customStyles}
              //  isDisabled={frequencyDisabled}
            />
          </div> */}
        </div>
        <div className="compare-button-container">
          {/* <button
            className="comparedataset-button"
            onClick={handleComparebuttonlick}
          >
            Calculate risk
          </button> */}
          <div className="resetbutton" onClick={handleResetbuttonlick}>
            <LuRefreshCcw style={{ marginRight: "10px" }} />
            Reset selections
          </div>
        </div>
      </div>
    </>
  );
};

export default Risk;
