import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../PricingAnalytics.css"; // Import the CSS file for styling
import "react-toastify/dist/ReactToastify.css";
import MenuItem from "@mui/material/MenuItem";
import { TextField, Select, FormControl } from "@mui/material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  changeBlockCountCalc,
  changeBlockCountExit,
  changeBlockCountTrigger,
  changeBlockSize,
  changeCoverName,
  changeCoverPeriodType,
  changeDatasets,
  changeEditPolicy,
  changeMethodofThreshold,
  changeNewIUList,
  changePhaseCalculation,
  changeRiskCovered,
  changeRiskInterval,
  changeStep,
  changeWeightage,
  changeWithinBlockCalc,
  changeacrossBlockCalc,
  changecoverMaxPayout,
  changecoverMinPayout,
  changecoverendDate,
  changecoverstartDate,
  changeshowManual,
  changeweatherExit,
  changeweatherTrigger,
  updateCoverDetails,
} from "../../redux/action";
import Slider from "@mui/material/Slider";
import { withStyles, makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
const StyledTableCell = withStyles({
  root: {
    padding: "7px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "center",
    borderBottom: "2px solid #FFCCA7",
  },
})(TableCell);

const useStyles = makeStyles({
  slider: {
    color: "rgba(247, 104, 8, 1)",
    width: "100%",
    height: "6px",
    outline: "none",
    opacity: "0.7",
    transition: "opacity .2s",
    borderRadius: "10px",
    marginTop: "20px",
  },
  disabledField: {
    backgroundColor: "rgba(41, 41, 5, 0.03) !important",
    padding: "5px",
  },
  headerRow: {
    height: "15px",
    fontSize: "12px",
  },
  headerCell: {
    padding: "10px 20px",
    width: "200px",
  },

  tableRow: {
    height: "30px",
  },
  textField: {
    "& .MuiInputBase-root": {
      height: "25px",
      fontSize: "16px",
    },
  },
  tableContainer: {
    maxHeight: "320px",
  },
});

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: 5,
    width: "90%",
  },
  container2: {
    display: "flex",
    justifyContent: "space-between",
    padding: 5,
    width: "60%",
  },
  container3: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    width: "100%",
  },
  container4: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    width: "33%",
  },
  container5: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    width: "66%",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginRight: 50,
  },
  fieldContainer1: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginRight: 50,
    width: "15%",
  },
  heading: {
    color: "black",
    marginBottom: 5,
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "26px",
    FontFace: "Inter",
  },
  select: {
    borderRadius: 5,
    height: 40,
  },
  textField: {
    borderRadius: 5,
    height: 30,
  },
  hr: {
    width: "100%",
    border: "none",
    borderTop: "1px solid #ccc",
    margin: "20px 0",
  },
  slider: {
    width: "100%",
    height: "6px",
    outline: "none",
    opacity: "0.7",
    transition: "opacity .2s",
    borderRadius: "10px",
    marginTop: "20px",
  },
  sliderValue: {
    textAlign: "center",
    padding: "6px",
    borderRadius: "5px",
    width: "60px",
    border: "1px solid black",
  },
  disabledHeading: {
    marginBottom: 5,
    textAlign: "left",
    fontSize: "14",
    lineHeight: "26px",
    fontWeight: 500,
    FontFace: "Inter",
    color: "grey",
  },
};

const Cover1 = (props) => {
  const classes = useStyles();
  const acrossBlockCalc = useSelector((state) => state.acrossBlockCalc);
  const coverName = useSelector((state) => state.coverName);
  const riskCovered = useSelector((state) => state.riskCovered);
  const datasets = useSelector((state) => state.datasets);
  const coverstartDate = useSelector((state) => state.coverstartDate);
  const coverendDate = useSelector((state) => state.coverendDate);
  const weightage = useSelector((state) => state.weightage);
  const coverPeriodType = useSelector((state) => state.coverPeriodType);
  const phaseCalculation = useSelector((state) => state.phaseCalculation);
  const coverMaxPayout = useSelector((state) => state.coverMaxPayout);
  const coverMinPayout = useSelector((state) => state.coverMinPayout);
  const weatherTrigger = useSelector((state) => state.weatherTrigger);
  const weatherExit = useSelector((state) => state.weatherExit);
  const MethodofThreshold = useSelector((state) => state.MethodofThreshold);
  const blockSize = useSelector((state) => state.blockSize);
  const riskInterval = useSelector((state) => state.riskInterval);
  const withinBlockCalc = useSelector((state) => state.withinBlockCalc);
  const blockCountCalc = useSelector((state) => state.blockCountCalc);
  const blockCountTrigger = useSelector((state) => state.blockCountTrigger);
  const blockCountExit = useSelector((state) => state.blockCountExit);
  const coverDetails = useSelector((state) => state.coverDetails);
  const [error, setError] = useState("");
  const [triggererror, settriggererror] = useState("");
  const [dateError, setDateError] = useState("");
  const IUList = useSelector((state) => state.IUList);
  const NewIUList = useSelector((state) => state.NewIUList);
  const policyDetails = useSelector((state) => state.policyDetails);

  const handleWeightageChange = (event) => {
    const value = event.target.value;
    if (value > 100) {
      setError("Percentage value cannot be greater than 100");
    } else {
      setError("");
    }
    dispatch(changeWeightage(value));
  };
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (IUList.length > 0) {
      const highestLevel = Object.keys(IUList[0]).filter((key) =>
        key.startsWith("level")
      ).length;
      const columnsMap = {
        2: ["level1", "level2"],
        3: ["level2", "level3"],
        4: ["level3", "level4"],
      };

      setColumns(columnsMap[highestLevel] || []);

      const startTriggerKey = "cover_1_trigger";
      const endExitKey = "cover_1_exit";

      const newRows = IUList.map((item) => ({
        level1: item.level1,
        level2: item.level2,
        level3: item.level3,
        level4: item.level4,
        [startTriggerKey]:
          parseInt(item[startTriggerKey], 10) || parseInt(weatherTrigger), // Initialize with weatherTrigger
        [endExitKey]: parseInt(item[endExitKey], 10) || parseInt(weatherExit), // Initialize with weatherExit
        cover_2_trigger:
          item.cover_2_trigger ?? parseInt(coverDetails.cover2_WeatherTrigger), // Default value if not present
        cover_2_exit:
          item.cover_2_exit ?? parseInt(coverDetails.cover2_WeatherExit),
      }));
      props.setcover1Rows(newRows);
      dispatch(changeNewIUList(newRows));
    }
  }, [IUList, weatherTrigger, weatherExit, JSON.stringify(props.cover2Rows)]);

  const columnHeaders = {
    level1: "Division",
    level2: "Zila",
    level3: "Upazila",
    level4: "Union",
  };
  const handleCellChange = (index, field, value) => {
    const newRows = [...props.cover1rows];
    newRows[index][field] =
      field === "cover_1_trigger" || field === "cover_1_exit"
        ? parseInt(value, 10)
        : value;
    props.setcover1Rows(newRows);
    dispatch(changeNewIUList(newRows));
  };

  const dispatch = useDispatch();

  const handleCoverPeriodTypeChange = (event) => {
    dispatch(changeCoverPeriodType(event.target.value));
  };
  const handleMinimumPayoutChange = (event) => {
    dispatch(changecoverMinPayout(event.target.value));
  };

  const handleMaximumPayoutChange = (event) => {
    dispatch(changecoverMaxPayout(event.target.value));
  };

  const shouldDisableWeatherExit =
    coverPeriodType === "Risky Days" ||
    (coverPeriodType === "Block" && acrossBlockCalc === "Count");

  useEffect(() => {
    const newCoverDetails = {
      coverName,
      riskCovered,
      datasets,
      coverstartDate,
      coverendDate,
      weightage,
      coverPeriodType,
      phaseCalculation,
      coverMaxPayout,
      coverMinPayout,
      weatherTrigger,
      weatherExit,
      MethodofThreshold,
      blockSize,
      riskInterval,
      withinBlockCalc,
      acrossBlockCalc,
      blockCountCalc,
      blockCountTrigger,
      blockCountExit,
    };

    dispatch(updateCoverDetails(newCoverDetails));
  }, [
    coverName,
    riskCovered,
    datasets,
    coverstartDate,
    coverendDate,
    weightage,
    coverPeriodType,
    phaseCalculation,
    coverMaxPayout,
    coverMinPayout,
    weatherTrigger,
    weatherExit,
    MethodofThreshold,
    blockSize,
    riskInterval,
    withinBlockCalc,
    acrossBlockCalc,
    blockCountCalc,
    blockCountTrigger,
    blockCountExit,
    dispatch,
    updateCoverDetails,
  ]);

  const getDatasets = () => {
    switch (riskCovered) {
      case "Excess Rainfall":
      case "Deficit Rainfall":
        return [
          <MenuItem key="Chirps Daily Rainfall" value="Chirps Daily Rainfall">
            Chirps Daily Rainfall
          </MenuItem>,
          <MenuItem
            key="Grid Chirps Daily Rainfall"
            value="Grid Chirps Daily Rainfall"
          >
            Grid Chirps Daily Rainfall
          </MenuItem>,
          <MenuItem key="Tamsat Daily Rainfall" value="Tamsat Daily Rainfall">
            Tamsat Daily Rainfall
          </MenuItem>,
          <MenuItem key="Gsmap Daily Rainfall" value="Gsmap Daily Rainfall">
            Gsmap Daily Rainfall
          </MenuItem>,
          <MenuItem key="Era5 Daily Rainfall" value="Era5 Daily Rainfall">
            Era5 Daily Rainfall
          </MenuItem>,
          <MenuItem key="Arc2 Daily Rainfall" value="Arc2 Daily Rainfall">
            Arc2 Daily Rainfall
          </MenuItem>,
        ];
      case "High Temperature":
      case "Low Temperature":
        return [
          <MenuItem key="Era5 Daily Temperature" value="Era5 Daily Temperature">
            Era5 Daily Temperature
          </MenuItem>,
          <MenuItem
            key="Era5 Monthly Temperature"
            value="Era5 Monthly Temperature"
          >
            Era5 Monthly Temperature
          </MenuItem>,
        ];
      case "Low Vegetation":
        return [
          <MenuItem key="Sentinel Ndvi" value="Sentinel Ndvi">
            Sentinel-2 5 Day interval NDVI
          </MenuItem>,
          <MenuItem key="Modis Eviirs Ndvi" value="Modis Eviirs Ndvi">
            Modis 16 Day Interval NDVI
          </MenuItem>,
        ];
      default:
        return null;
    }
  };

  useEffect(() => {
    if (
      riskCovered === "Low Vegetation" ||
      riskCovered === "Deficit Rainfall"
    ) {
      if (parseFloat(weatherExit) >= parseFloat(weatherTrigger)) {
        settriggererror("Exit should be less than Trigger");
      } else {
        settriggererror("");
      }
    } else {
      if (parseFloat(weatherTrigger) >= parseFloat(weatherExit)) {
        settriggererror("Exit should be greater than trigger");
      } else {
        settriggererror("");
      }
    }
  }, [weatherTrigger, weatherExit, riskCovered]);
  useEffect(() => {
    if (new Date(coverstartDate) > new Date(coverendDate)) {
      setDateError("Start date cannot be greater than end date");
    } else {
      setDateError("");
    }
  }, [coverstartDate, coverendDate]);

  useEffect(() => {
    if (policyDetails && policyDetails.length > 0) {
      const policy = policyDetails[0];
      dispatch(changeCoverName(policy.cover_1_name || "cover1"));
      dispatch(changeRiskCovered(policy.cover_1_risk_type || ""));
      dispatch(changeDatasets(policy.cover_1_dataset_type || ""));
      dispatch(changecoverstartDate(policy.cover_1_start_date || ""));
      dispatch(changecoverendDate(policy.cover_1_end_date || ""));
      dispatch(changeWeightage(policy.cover_1_weightage * 100 || ""));
      dispatch(changeCoverPeriodType(policy.cover_1_period_type || ""));
      dispatch(changePhaseCalculation(policy.cover_1_phase_calculation_type || ""));
      dispatch(changeBlockSize(policy.cover_1_block_size || ""));
      dispatch(changeRiskInterval(policy.cover_1_risk_interval || ""));
      dispatch(changeWithinBlockCalc(policy.cover_1_within_block_method || ""));
      dispatch(changeacrossBlockCalc(policy.cover_1_across_block_method || ""));
      dispatch(
        changecoverMinPayout(Math.round(policy.cover_1_min_payout * 100) || "")
      );
      dispatch(
        changecoverMaxPayout(Math.round(policy.cover_1_max_payout * 100) || "")
      );
      dispatch(
        changeMethodofThreshold(
          policy.cover_1_threshold_calculation_method || ""
        )
      );
      dispatch(changeweatherTrigger(policy.cover_1_trigger || ""));
      dispatch(changeweatherExit(policy.cover_1_exit || ""));

      const newIUList = IUList.map((item, index) => {
        const policy1 = policyDetails[index];
        if (policy1) {
          return {
            ...item,
            level1: policy1.level1,
            level2: policy1.level2,
            level3: policy1.level3,
            level4: policy1.level4,
            cover_1_trigger: parseInt(policy1.cover_1_trigger, 10),
            cover_1_exit: parseInt(policy1.cover_1_exit, 10),
          };
        }

        return item;
      });
      props.setcover1Rows(newIUList);
      dispatch(changeNewIUList(newIUList));
    }
    //THIS HOOK SHOULD BE ONLY TRIGGERED WHEN POLICY DETAILS ARE FETCHED FIRST TIME ON EDITING
  }, [JSON.stringify(policyDetails), JSON.stringify(IUList)]);

  useEffect(() => {
   
    if (policyDetails && policyDetails.length > 0) {
      const policy = policyDetails[0] || {};
      const roundToZero = (value) => Math.round(value * 100);

      if (
        coverName !== (policyDetails[0] && policyDetails[0].cover_1_name) ||
        riskCovered !==
          (policyDetails[0] && policyDetails[0].cover_1_risk_type) ||
        datasets !==
          (policyDetails[0] && policyDetails[0].cover_1_dataset_type) ||
        coverstartDate !==
          (policyDetails[0] && policyDetails[0].cover_1_start_date) ||
        coverendDate !==
          (policyDetails[0] && policyDetails[0].cover_1_end_date) ||
        roundToZero(weightage) !== roundToZero(policy.cover_1_weightage) ||
        coverPeriodType !==
          (policyDetails[0] && policyDetails[0].cover_1_period_type) ||
          phaseCalculation !==
          (policyDetails[0] && policyDetails[0].cover_1_phase_calculation_type) ||
        blockSize !==
          (policyDetails[0] && policyDetails[0].cover_1_block_size) ||
        riskInterval !==
          (policyDetails[0] && policyDetails[0].cover_1_risk_interval) ||
        withinBlockCalc !==
          (policyDetails[0] && policyDetails[0].cover_1_within_block_method) ||
        acrossBlockCalc !==
          (policyDetails[0] && policyDetails[0].cover_1_across_block_method) ||
        roundToZero(coverMinPayout) !==
          roundToZero(policy.cover_1_min_payout) ||
        roundToZero(coverMaxPayout) !==
          roundToZero(policy.cover_1_max_payout) ||
        MethodofThreshold !==
          (policyDetails[0] &&
            policyDetails[0].cover_1_threshold_calculation_method) ||
        weatherExit !==
          (policyDetails[0] && policyDetails[0].cover_1_trigger) ||
        weatherTrigger !== (policyDetails[0] && policyDetails[0].cover_1_exit)
      ) {
        dispatch(changeEditPolicy(true));
      } else {
        dispatch(changeEditPolicy(false));
      }
    }
  }, [
    coverName,
    riskCovered,
    datasets,
    coverstartDate,
    coverendDate,
    weightage,
    coverPeriodType,
    blockSize,
    riskInterval,
    withinBlockCalc,
    acrossBlockCalc,
    coverMinPayout,
    coverMinPayout,
    coverMaxPayout,
    MethodofThreshold,
    weatherExit,
    weatherTrigger,
  ]);

  return (
    <Accordion style={{ width: "100%", marginTop: "0px" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Cover 1</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <div style={styles.container3}>
            <div style={styles.fieldContainer}>
              <div style={styles.heading}>Cover Name</div>
              <TextField
                fullWidth
                id="productType"
                size="small"
                style={styles.textField}
                value={coverName}
                onChange={(event) =>
                  dispatch(changeCoverName(event.target.value))
                }
              />
            </div>
            <div style={styles.fieldContainer}>
              <div style={styles.heading}>Risk Covered</div>
              <FormControl fullWidth>
                <Select
                  labelId="typeOfProductLabel"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  style={styles.select}
                  value={riskCovered}
                  onChange={(event) =>
                    dispatch(changeRiskCovered(event.target.value))
                  }
                >
                  <MenuItem value="Excess Rainfall"> Excess Rainfall</MenuItem>
                  <MenuItem value="Deficit Rainfall">
                    {" "}
                    Deficit Rainfall
                  </MenuItem>
                  <MenuItem value="Low Temperature">Low Temperature</MenuItem>
                  <MenuItem value="High Temperature">High Temperature</MenuItem>
                  <MenuItem value="Low Vegetation"> Low Vegetation</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={styles.fieldContainer}>
              <div style={styles.heading}>Datasets</div>
              <FormControl fullWidth>
                <Select
                  labelId="typeOfProductLabel"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  style={styles.select}
                  value={datasets}
                  onChange={(event) =>
                    dispatch(changeDatasets(event.target.value))
                  }
                >
                  {getDatasets()}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={styles.container3}>
            <div style={styles.fieldContainer}>
              <div style={styles.heading}> Start Date</div>
              <TextField
                id="startDate"
                type="date"
                value={coverstartDate}
                onChange={(event) =>
                  dispatch(changecoverstartDate(event.target.value))
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!dateError}
                helperText={dateError}
              />
            </div>
            <div style={styles.fieldContainer}>
              <div style={styles.heading}> End Date</div>
              <TextField
                id="endDate"
                type="date"
                fullWidth
                value={coverendDate}
                onChange={(event) =>
                  dispatch(changecoverendDate(event.target.value))
                }
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!dateError}
                helperText={dateError}
              />
            </div>

            <div style={styles.fieldContainer}>
              <div style={styles.heading}>Weightage(%)</div>
              <TextField
                fullWidth
                id="productType"
                size="small"
                style={styles.textField}
                value={weightage}
                onChange={handleWeightageChange}
                error={!!error}
                helperText={error}
                type="number"
                step="0.01"
              />
            </div>
          </div>
          <hr style={styles.hr} />

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={styles.container4}>
              <div style={styles.fieldContainer}>
                <div style={styles.heading}>Cover Period Type</div>
                <FormControl fullWidth>
                  <Select
                    labelId="typeOfProductLabel"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    style={styles.select}
                    value={coverPeriodType}
                    onChange={handleCoverPeriodTypeChange}
                  >
                    <MenuItem value="Full Phase">Full Phase</MenuItem>
                    <MenuItem value="Block">Block</MenuItem>
                    {/* <MenuItem value="Risky Days"> Risky Days</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
            </div>
            {coverPeriodType == "Full Phase" && (
              <div style={styles.container4}>
                <div style={styles.fieldContainer}>
                  <div style={styles.heading}>Phase Calculation</div>
                  <FormControl fullWidth>
                    <Select
                      labelId="typeOfProductLabel"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      style={styles.select}
                      value={phaseCalculation}
                      onChange={(event) =>
                        dispatch(changePhaseCalculation(event.target.value))
                      }
                    >
                      <MenuItem value="Minimum">Minimum</MenuItem>
                      <MenuItem value="Maximum">Maximum</MenuItem>
                      <MenuItem value="Average"> Average</MenuItem>
                      <MenuItem value="Cumulative"> Cumulative</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}
            {coverPeriodType == "Risky Days" && (
              <>
                <div style={styles.container4}>
                  <div style={styles.fieldContainer}>
                    <div style={styles.heading}>Risky Day Calculation</div>
                    <FormControl fullWidth>
                      <Select
                        labelId="typeOfProductLabel"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        style={styles.select}
                      >
                        <MenuItem value="Manual"> Manual</MenuItem>
                        <MenuItem value="Percentile"> Percentile</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div style={styles.container4}>
                  <div style={styles.fieldContainer1}>
                    <div style={styles.heading}>Risky Days Trigger </div>
                    <TextField
                      fullWidth
                      id="productType"
                      size="small"
                      style={styles.textField}
                    />
                  </div>
                  <div style={styles.fieldContainer1}>
                    <div style={styles.heading}>Risky Days Exit</div>
                    <TextField
                      fullWidth
                      id="productType"
                      size="small"
                      style={styles.textField}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          {coverPeriodType === "Block" && (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={styles.container4}>
                  <div style={styles.fieldContainer1}>
                    <div style={styles.heading}>Block Size </div>
                    <TextField
                      fullWidth
                      id="productType"
                      size="small"
                      style={styles.textField}
                      value={blockSize}
                      type="number"
                      onChange={(event) =>
                        dispatch(changeBlockSize(event.target.value))
                      }
                    />
                  </div>
                  <div style={styles.fieldContainer1}>
                    <div style={styles.heading}>Risk Interval</div>
                    <TextField
                      fullWidth
                      id="productType"
                      size="small"
                      style={styles.textField}
                      value={riskInterval}
                      type="number"
                      step="0.01"
                      onChange={(event) =>
                        dispatch(changeRiskInterval(event.target.value))
                      }
                    />
                  </div>
                </div>
                <div style={styles.container4}>
                  <div style={styles.fieldContainer}>
                    <div style={styles.heading}>Within Block Calculation </div>
                    <FormControl fullWidth>
                      <Select
                        labelId="typeOfProductLabel"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        style={styles.select}
                        value={withinBlockCalc}
                        onChange={(event) =>
                          dispatch(changeWithinBlockCalc(event.target.value))
                        }
                      >
                        <MenuItem value="Minimum">Minimum</MenuItem>
                        <MenuItem value="Maximum">Maximum</MenuItem>
                        <MenuItem value="Average"> Average</MenuItem>
                        <MenuItem value="Cumulative"> Cumulative</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div style={styles.container4}>
                  <div style={styles.fieldContainer}>
                    <div style={styles.heading}>Across Block Calculation </div>
                    <FormControl fullWidth>
                      <Select
                        labelId="typeOfProductLabel"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        style={styles.select}
                        value={acrossBlockCalc}
                        onChange={(event) =>
                          dispatch(changeacrossBlockCalc(event.target.value))
                        }
                      >
                        {/* <MenuItem value="Count"> Count</MenuItem> */}
                        <MenuItem value="Minimum">Minimum</MenuItem>
                        <MenuItem value="Maximum">Maximum</MenuItem>
                        <MenuItem value="Average"> Average</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              {acrossBlockCalc === "Count" && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={styles.container4}>
                    <div style={styles.fieldContainer}>
                      <div style={styles.heading}>Block Count Calculation</div>
                      <FormControl fullWidth>
                        <Select
                          labelId="typeOfProductLabel"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          style={styles.select}
                          value={blockCountCalc}
                          onChange={(event) =>
                            dispatch(changeBlockCountCalc(event.target.value))
                          }
                        >
                          <MenuItem value="Manual">Manual</MenuItem>
                          <MenuItem value="Percentile">Percentile</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div style={styles.container4}>
                    <div style={styles.fieldContainer1}>
                      <div style={styles.heading}>Block Count Trigger </div>
                      <TextField
                        fullWidth
                        id="productType"
                        size="small"
                        style={styles.textField}
                        value={blockCountTrigger}
                        onChange={(event) =>
                          dispatch(changeBlockCountTrigger(event.target.value))
                        }
                      />
                    </div>
                    <div style={styles.fieldContainer1}>
                      <div style={styles.heading}>Block Count Exit</div>
                      <TextField
                        fullWidth
                        id="productType"
                        size="small"
                        style={styles.textField}
                        value={blockCountExit}
                        onChange={(event) =>
                          dispatch(changeBlockCountExit(event.target.value))
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <hr style={styles.hr} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={styles.container4}>
              <div style={styles.fieldContainer}>
                <div style={styles.heading}>
                  Method of Threshold Calculation
                </div>
                <FormControl fullWidth>
                  <Select
                    labelId="typeOfProductLabel"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    style={styles.select}
                    value={MethodofThreshold}
                    onChange={(event) =>
                      dispatch(changeMethodofThreshold(event.target.value))
                    }
                  >
                    <MenuItem value="Manual">Manual</MenuItem>
                    <MenuItem value="Percentile">Percentile</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div style={styles.container4}>
              <div style={styles.fieldContainer1}>
                <div style={styles.heading}>Weather Trigger</div>
                <TextField
                  fullWidth
                  id="productType"
                  size="small"
                  style={styles.textField}
                  value={weatherTrigger}
                  type="number"
                  step="0.01"
                  onChange={(event) =>
                    dispatch(changeweatherTrigger(event.target.value))
                  }
                />
              </div>
              <div style={styles.fieldContainer1}>
                <div
                  style={
                    shouldDisableWeatherExit
                      ? styles.disabledHeading
                      : styles.heading
                  }
                >
                  Weather Exit
                </div>
                <TextField
                  fullWidth
                  id="productType"
                  size="small"
                  style={styles.textField}
                  value={weatherExit}
                  disabled={shouldDisableWeatherExit}
                  className={
                    shouldDisableWeatherExit ? classes.disabledField : ""
                  }
                  onChange={(event) =>
                    dispatch(changeweatherExit(event.target.value))
                  }
                  error={!!triggererror}
                  helperText={triggererror}
                  type="number"
                  step="0.01"
                />
              </div>
            </div>
          </div>
          <div
            style={{ padding: "5px", marginTop: "30px", marginBottom: "10px" }}
          >
            {IUList.length > 0 && (
              <TableContainer
                component={Paper}
                className={
                  props.cover1rows.length > 5 ? classes.tableContainer : ""
                }
              >
                <Table style={{ borderSpacing: "0 5px" }}>
                  <TableHead>
                    <TableRow
                      className={classes.headerRow}
                      style={{
                        backgroundColor: "#FFF1E7",
                        height: 12,
                        lineHeight: "10px",
                      }}
                    >
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column}
                          className={classes.headerCell}
                          style={{ height: "12px" }}
                        >
                          {columnHeaders[column]}
                        </StyledTableCell>
                      ))}
                      <StyledTableCell style={{}}>
                        Weather Trigger
                      </StyledTableCell>
                      <StyledTableCell style={{}}>Weather Exit</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.cover1rows.map((row, index) => (
                      <TableRow key={index}>
                        {columns.map((column) => (
                          <StyledTableCell key={column} style={{}}>
                            <Typography variant="body2" className="cellText">
                              {row[column]}
                            </Typography>
                          </StyledTableCell>
                        ))}

                        <StyledTableCell style={{}}>
                          <TextField
                            value={row.cover_1_trigger} // Access the field dynamically
                            onChange={(e) =>
                              handleCellChange(
                                index,
                                "cover_1_trigger",
                                e.target.value
                              )
                            }
                            variant="outlined"
                            size="small"
                            type="number"
                            step="0.01"
                            className="textField"
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            value={row.cover_1_exit} // Access the field dynamically
                            onChange={(e) =>
                              handleCellChange(
                                index,
                                "cover_1_exit",
                                e.target.value
                              )
                            }
                            variant="outlined"
                            size="small"
                            type="number"
                            step="0.01"
                            disabled={shouldDisableWeatherExit}
                            className={
                              shouldDisableWeatherExit
                                ? classes.disabledField
                                : "textField"
                            }
                          />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
          <div style={styles.container5}>
            <div style={styles.fieldContainer}>
              <div style={styles.heading}>Minimum Payout (%)</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Slider
                  min={0}
                  max={50}
                  value={coverMinPayout}
                  onChange={handleMinimumPayoutChange}
                  classes={{ root: classes.slider }}
                  style={{ width: "100%" }}
                />
                <div style={{ marginLeft: "20px", ...styles.sliderValue }}>
                  {coverMinPayout}
                </div>
              </div>
            </div>
            <div style={styles.fieldContainer}>
              <div style={styles.heading}>Maximum Payout (%)</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Slider
                  min={0}
                  max={100}
                  value={coverMaxPayout}
                  onChange={handleMaximumPayoutChange}
                  classes={{ root: classes.slider }}
                  style={{ width: "100%" }}
                />
                <div style={{ marginLeft: "20px", ...styles.sliderValue }}>
                  {coverMaxPayout}
                </div>
              </div>
            </div>
          </div>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Cover1;
