import React from "react";
import "../../styles.css";
import PixelDatasets from "./PixelDatasets";
import { useSelector } from "react-redux";
const VisualPixelDataset = (props) => {
  const pixelName = useSelector((state) => state.pixelName);
  return (
    <>
      <div className="visualise-boundary">
        <div>
          <div className="exploretext">
            <img
              src="chevron-left.png"
              alt="location icon"
              style={{ marginRight: 10 }}
              onClick={() => {
                props.setChirps5kmMonthlyRainfall(false);
                props.setTamsatDailyGraph(false);
                props.setTamsatMonthlyGraph(false);
                props.setarc210kmDailyRainfall(false);
                props.setTamsatMonthlyGraph(false);
                props.setModis5DayIntervalNDVIGraph(false);
                props.setlandcover(false);
                props.setChirps5kmDailyRainfall(false);
                props.setera5DailyRainfallGraph(false);
                props.setera5MonthlyRainfallGraph(false);
                props.setera5DailyTemperatureGraph(false);
                props.setera5MonthlyTemperatureGraph(false);
                props.setgsMapDaily(false);
                props.setgsMapMonthly(false);
                props.setsentinal2NDVI(false);
                props.setExplorePixelDataset(false);
              }}
            />
            <div> Select Datasets</div>
          </div>
          <div className="exploresecondarytext">
            Select datasets to visualise them as charts or on the map.
          </div>
          {pixelName ? (
            <>
              <div className="selectedloctext">Showing datasets for</div>
              <button className="selectedloc-buttonvisual">
                <img src="loc-pin.png" alt="icon" style={{ marginRight: 5 }} />
                {pixelName}
              </button>
            </>
          ) : null}
          <hr className="horizontal-rule" />
          <div className="datasetstext">Datasets</div>
          <PixelDatasets
            pixelName={props.pixelName}
            setPixelName={props.setPixelName}
            layerName={props.layerName}
            setLayerName={props.setLayerName}
            countryName={props.countryName}
            tamsatDailyGraph={props.tamsatDailyGraph}
            setTamsatDailyGraph={props.setTamsatDailyGraph}
            tamsatMonthlyGraph={props.tamsatMonthlyGraph}
            setTamsatMonthlyGraph={props.setTamsatMonthlyGraph}
            chirps5kmMonthlyRainfall={props.chirps5kmMonthlyRainfall}
            setChirps5kmMonthlyRainfall={props.setChirps5kmMonthlyRainfall}
            arc210kmDailyRainfall={props.arc210kmDailyRainfall}
            setarc210kmDailyRainfall={props.setarc210kmDailyRainfall}
            modis5DayIntervalNDVIGraph={props.modis5DayIntervalNDVIGraph}
            setModis5DayIntervalNDVIGraph={props.setModis5DayIntervalNDVIGraph}
            setIsDrawerOpen={props.setIsDrawerOpen}
            landcover={props.landcover}
            setlandcover={props.setlandcover}
            chirps5kmDailyRainfall={props.chirps5kmDailyRainfall}
            setChirps5kmDailyRainfall={props.setChirps5kmDailyRainfall}
            era5DailyRainfallGraph={props.era5DailyRainfallGraph}
            setera5DailyRainfallGraph={props.setera5DailyRainfallGraph}
            era5MonthlyRainfallGraph={props.era5MonthlyRainfallGraph}
            setera5MonthlyRainfallGraph={props.setera5MonthlyRainfallGraph}
            era5DailyTemperatureGraph={props.era5DailyTemperatureGraph}
            setera5DailyTemperatureGraph={props.setera5DailyTemperatureGraph}
            era5MonthlyTemperatureGraph={props.era5MonthlyTemperatureGraph}
            setera5MonthlyTemperatureGraph={
              props.setera5MonthlyTemperatureGraph
            }
            gsMapDaily={props.gsMapDaily}
            setgsMapDaily={props.setgsMapDaily}
            gsMapMonthly={props.gsMapMonthly}
            setgsMapMonthly={props.setgsMapMonthly}
            sentinal2NDVI={props.sentinal2NDVI}
            setsentinal2NDVI={props.setsentinal2NDVI}
          />
        </div>
      </div>
    </>
  );
};

export default VisualPixelDataset;
