import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const AreaRangeLineGraphTempMonthly = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0 && chartRef && chartRef.current) {
      const chart = chartRef.current.chart;
      const chartData = data.map(item => ({
        x: item.date_milliseconds,
        low: item.min,
        high: item.max,
        y: item.average
      }));
      // Filter out null values
      const filteredData = data.filter(item => item.min !== null && item.max !== null && item.average !== null);

      // Update chart data
      chart.update({
        series: [{
          type: 'arearange',
          data: filteredData.map(item => [Date.parse(item.dateString), item.min, item.max]),
        
        }, {
          type: 'line',
          data: filteredData.map(item => [Date.parse(item.dateString), item.average]),
          // tooltip: {
          //   valueSuffix: '°C'
          // }
        }]
      });
    }
  }, [data]);

  const options = {
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: ''
      }
    },
    yAxis: {
      title: {
        text: 'Temperature (°C)'
      }
    },
    legend: {
      enabled: true
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      valueSuffix: '°C',
      formatter: function () {
        return '<b>' + Highcharts.dateFormat('%b %e', this.x) + '</b><br/>' +
          'Range: ' + this.point.low + '°C - ' + this.point.high + '°C<br/>' +
          'Average: ' + this.points[1].y + '°C'; 
      }
    },
    credits:false,
    series: [{
      type: 'arearange',
      name: 'Historical Min Max',
      color:'#01619E4D',
      data: data.map(item => [Date.parse(item.dateString), item.min, item.max]),
      // tooltip: {
      //   valueSuffix: '°C'
      // }
    }, {
      type: 'line',
      name: 'Average ',
      data: data.map(item => [Date.parse(item.dateString), item.average]),
      // tooltip: {
      //   valueSuffix: '°C'
      // },
      color:'#01619E'
    }]
  };

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />;
};

export default AreaRangeLineGraphTempMonthly;
