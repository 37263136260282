import { useDropzone } from "react-dropzone";
import { FaUpload } from "react-icons/fa6";
import { forwardRef, useEffect, useRef } from "react";

function FileDropZone(
  { onChange, setIsFileUploaded, isReset, setIsReset },
  ref
) {
  const afRef = useRef([]);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
          onChange(acceptedFiles[0]);
          setIsFileUploaded(true);
        }
      },
      accept: {
        geojson: [".geojson"],
      },
      maxFiles: 1,
      minSize: 0,
      maxSize: 1048576,
      noKeyboard: true,
    });

  afRef.current = acceptedFiles;

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const isError = fileRejections.length > 0;

  useEffect(() => {
    if (isReset) {
      afRef.current.length = 0;
      setIsReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset, acceptedFiles]);

  return (
    <>
      <section className="flex flex-col">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {acceptedFiles[0] ? (
            <p className="text-xl w-full h-full">
              {acceptedFiles[0].path + " - " + acceptedFiles[0].size + " bytes"}
            </p>
          ) : (
            <>
              <span style={{ fontSize: "36px", color: "#ccc" }}>
                <FaUpload />
              </span>
              <p style={{ fontSize: "12px", color: "#aaa" }}>
                Drag and drop your file or click to upload.
              </p>
            </>
          )}
        </div>
        <aside className="flex items-center gap-2">
          {isError && (
            <span className="text-red-500">
              {fileRejections[0].errors[0].message}
            </span>
          )}
        </aside>
      </section>
    </>
  );
}

export default forwardRef(FileDropZone);
