// Define the initial state as an empty object or with default values
const initialState = {};

// Create the reducer function
export const changeAllcoverDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_COVER_DETAILS":
      // Return a new state object with updated cover details
      return {
        ...state,
        ...action.payload,
      };
    default:
      // Return the current state if the action type is not recognized
      return state;
  }
};
