import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const AreaRangeLineGraphTemp = ({ data , graphName }) => {
  useEffect(() => {
    if (data.length > 0) {
      const chartData = data.map(item => ({
        x: item.date_milliseconds,
        low: item.min,
        high: item.max,
        y: item.average
      }));

      Highcharts.chart('graph-container', {
        chart: {
          type: 'arearange',
          zoomType: 'x'
        },
        credits:false,
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%b ', // Display month and year,
          }
        },
        yAxis: {
          title: {
            text: 'Temperature (°C)'
          }
        },
        tooltip: {
          crosshairs: true,
          shared: true,
          valueSuffix: '°C',
          formatter: function () {
            return '<b>' + Highcharts.dateFormat('%b %e', this.x) + '</b><br/>' +
              'Range: ' + this.point.low + '°C - ' + this.point.high + '°C<br/>' +
              'Average: ' + this.points[1].y + '°C'; 
          }
        },
        series: [{
          name: 'Historical Min Max',
          data: chartData,
          zIndex: 2,
          color:'#01619E4D',
          // tooltip: {
          //   crosshairs: true,
          // shared: true,
          //   valueSuffix: '°C'
          // }
        }, {
          name: 'Average ',
          data: chartData.map(item => [item.x, item.y]),
          type: 'line',
          zIndex: 2,
          color:'#01619E',
          // tooltip: {
          //   crosshairs: true,
          // shared: true,
          //   valueSuffix: '°C'
          // }
        }]
      });
    }
  }, [data]);

  return <div id="graph-container" />;
};

export default AreaRangeLineGraphTemp;
