import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Menu,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { IoSearchOutline } from "react-icons/io5";
import { FaCaretDown } from "react-icons/fa";
import { IoDownloadOutline } from "react-icons/io5";
import getBimodalContent from "../../utils/biModal";
import { useDispatch, useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import { changeSelectedIU } from "../../redux/action";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import PayoutChart from "./PayoutChart";
import { createRoot } from "react-dom";
import Auth from "@aws-amplify/auth";

const OrangeRadio = styled(Radio)({
  "&.Mui-checked": {
    color: "rgba(247, 104, 8, 1)",
  },
});

const OrangeCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "rgba(247, 104, 8, 1)",
  },
});

const DownloadTermsheet = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [IuType, setIuType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const coverDetails = useSelector((state) => state.coverDetails);
  const insurer = useSelector((state) => state.insurer);
  const valueChain = useSelector((state) => state.valueChain);
  const aggregator = useSelector((state) => state.aggregator);
  const currency = useSelector((state) => state.currency);
  const methodofSelection = useSelector((state) => state.methodofSelection);
  const numberOfCovers = useSelector((state) => state.numberOfCovers);
  const coverName = useSelector((state) => state.coverName);
  const IULevel1 = useSelector((state) => state.IULevel1);
  const IULevel2 = useSelector((state) => state.IULevel2);
  const IULevel3 = useSelector((state) => state.IULevel3);
  const IULevel4 = useSelector((state) => state.IULevel4);
  const typeOfProduct = useSelector((state) => state.typeOfProduct);
  const productName = useSelector((state) => state.productName);
  const deductiblePercentage = useSelector(
    (state) => state.deductiblePercentage
  );
  const targetLossRatioValue = useSelector(
    (state) => state.targetLossRatioValue
  );
  const targetLossRatioselection = useSelector(
    (state) => state.targetLossRatioselection
  );
  const commercialpremiumRateType = useSelector(
    (state) => state.commercialpremiumRateType
  );
  const insurerMargin = useSelector((state) => state.insurerMargin);
  const productDevelopmentFee = useSelector(
    (state) => state.productDevelopmentFee
  );
  const brokerFee = useSelector((state) => state.brokerFee);
  const MethodofThreshold = useSelector((state) => state.MethodofThreshold);
  const IUList = useSelector((state) => state.IUList);
  const dispatch = useDispatch();
  const [checkboxes, setCheckboxes] = useState([]);
  const [termsheetChecked, setTermsheetChecked] = useState(false);
  const [dataChecked, setDataChecked] = useState(false);
  const SelectedIU = useSelector((state) => state.SelectedIU);
  const NewIUList = useSelector((state) => state.NewIUList);
  const [selectedIUCover1_weathertrigger, setselectedIUCover1_weathertrigger] =
    useState(null);
  const [selectedIUCover2_weathertrigger, setselectedIUCover2_weathertrigger] =
    useState(null);
  const [selectedIUCover1_weatherexit, setselectedIUCover1_weatherexit] =
    useState(null);
  const [selectedIUCover2_weatherexit, setselectedIUCover2_weatherexit] =
    useState(null);
  const [commercialPremiumData, setCommercialPremiumData] = useState({});
  const [PurePremiumData, setPurePremiumData] = useState({});
  const [selectedIUcommercial_premium, setselectedIUcommercial_premium] =
    useState(null);
  const [selectedIUpurepremium, setselectedIUpurepremium] = useState(null);
  const policyDetails = useSelector((state) => state.policyDetails);
  const blockCountCalc = useSelector((state) => state.blockCountCalc);
  const blockCountTrigger = useSelector((state) => state.blockCountTrigger);
  const blockCountExit = useSelector((state) => state.blockCountExit);
  const deductibleType = useSelector((state) => state.deductibleType);

  useEffect(() => {
    if (SelectedIU.length > 0) {
      const selectedIUData = NewIUList.find(
        (iu) =>
          `${iu.level1}->${iu.level2}->${iu.level3}->${iu.level4}` ===
          SelectedIU[0]
      );
      if (selectedIUData) {
        setselectedIUCover1_weathertrigger(selectedIUData.cover_1_trigger);
        setselectedIUCover2_weathertrigger(
          selectedIUData.cover_2_trigger ? selectedIUData.cover_2_trigger : 0
        );
        setselectedIUCover1_weatherexit(selectedIUData.cover_1_exit);
        setselectedIUCover2_weatherexit(
          selectedIUData.cover_2_exit ? selectedIUData.cover_2_exit : 0
        );

        if (Array.isArray(PurePremiumData)) {
          const purePremium = PurePremiumData.find(
            (premium) => premium.level4 === selectedIUData.level4
          );
          if (purePremium) {
            setselectedIUpurepremium(purePremium.pure_premium);
          } else {
            setselectedIUpurepremium(null);
          }
        } else {
          setselectedIUpurepremium(null);
        }

        if (Array.isArray(commercialPremiumData)) {
          const commercialPremium = commercialPremiumData.find(
            (premium) => premium.level4 === selectedIUData.level4
          );
          if (commercialPremium) {
            setselectedIUcommercial_premium(
              commercialPremium.commercial_premium
            );
          } else {
            setselectedIUcommercial_premium(null);
          }
        } else {
          setselectedIUcommercial_premium(null);
        }
      }
    }
  }, [SelectedIU, NewIUList, PurePremiumData, commercialPremiumData]);
  useEffect(() => {
    const iuArrayTemp = [];

    if (!policyDetails || policyDetails.length === 0) {
      for (let i in IUList) {
        if (IUList[i].level4 != null)
          iuArrayTemp.push({
            name: `${IUList[i]["level1"]}->${IUList[i]["level2"]}->${IUList[i]["level3"]}->${IUList[i]["level4"]}`,
            checked: false,
          });
        else if (IUList[i].level3 != null)
          iuArrayTemp.push({
            name: `${IUList[i]["level1"]}->${IUList[i]["level2"]}->${IUList[i]["level3"]}`,
            checked: false,
          });
        else if (IUList[i].level2 != null)
          iuArrayTemp.push({
            name: `${IUList[i]["level1"]}->${IUList[i]["level2"]}`,
            checked: false,
          });
        else
          iuArrayTemp.push({ name: `${IUList[i]["level1"]}`, checked: false });
      }
      setCheckboxes(iuArrayTemp);
    } else {
      IUList.forEach((detail) => {
        let name = detail.level1 || "";
        if (detail.level2) name += `->${detail.level2}`;
        if (detail.level3) name += `->${detail.level3}`;
        if (detail.level4) name += `->${detail.level4}`;

        iuArrayTemp.push({ name, checked: false });
      });

      setCheckboxes(iuArrayTemp);
    }
  }, [policyDetails, IUList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const email = user.attributes.email;

        if (Array.isArray(SelectedIU) && SelectedIU.length > 0) {
          const selectedString = SelectedIU[0];
          if (typeof selectedString === "string" && selectedString !== "") {
            const levels = selectedString.split("->");
            const [level1, level2, level3, level4] = levels;

            const fetchPayout = fetch(`/api/pricing/showPayout`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: email,
                product_type: typeOfProduct,
                product_name: productName,
                level1: level1 || null,
                level2: level2 || null,
                level3: level3 || null,
                level4: level4 || null,
              }),
            }).then((response) => response.json());

            const fetchCommercialPremium = fetch(
              `/api/pricing/showAllIUCommercialPremium`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  product_type: typeOfProduct,
                  product_name: productName,
                  email: email,
                }),
              }
            ).then((response) => response.json());

            const fetchPurePremium = fetch(
              `/api/pricing/showAllIUPurePremium`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  product_type: typeOfProduct,
                  product_name: productName,
                  email: email,
                }),
              }
            ).then((response) => response.json());

            const [payoutData, commercialData, pureData] = await Promise.all([
              fetchPayout,
              fetchCommercialPremium,
              fetchPurePremium,
            ]);

            props.setPayout(payoutData);
            setCommercialPremiumData(commercialData);
            setPurePremiumData(pureData);
          }
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [SelectedIU, typeOfProduct, productName]);

  const handleCheckboxChange = (index) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index].checked = !newCheckboxes[index].checked;
    setCheckboxes(newCheckboxes);
    setSelectAllChecked(newCheckboxes.every((checkbox) => checkbox.checked));

    // Update selectedIU state
    const updatedSelectedIU = newCheckboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.name);
    dispatch(changeSelectedIU(updatedSelectedIU));
  };

  const handleSelectAll = () => {
    const newCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: true,
    }));
    setCheckboxes(newCheckboxes);
    setSelectAllChecked(true);
  };

  const handleClearAll = () => {
    const newCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: false,
    }));
    setCheckboxes(newCheckboxes);
    setSelectAllChecked(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCheckboxes = checkboxes.filter((checkbox) =>
    checkbox.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTermsheetChange = () => {
    setTermsheetChecked(!termsheetChecked);
  };

  const handleDataChange = () => {
    setDataChecked(!dataChecked);
  };
  function generateDefinitionsTable() {
    return `
      <div style="background-color: white; padding: 7px; margin-bottom: 20px;page-break-before: always;">
        <h4>Definitions</h4>
        <table style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th style="border: 1px solid #B8B8B8; padding: 8px; text-align: left; font-weight: bold; background-color: lightgrey;">Term</th>
              <th style="border: 1px solid #B8B8B8; padding: 8px; text-align: left; font-weight: bold; background-color: lightgrey;">Definition</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">Term 1</td>
              <td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">Definition 1</td>
            </tr>
            <tr>
              <td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">Term 2</td>
              <td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">Definition 2</td>
            </tr>
            <tr>
              <td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">Term 3</td>
              <td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">Definition 3</td>
            </tr>
            <tr>
              <td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">Term 4</td>
              <td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">Definition 4</td>
            </tr>
          </tbody>
        </table>
      </div>
      `;
  }
  const downloadPdfDocument = async () => {
    const zip = new JSZip();
    const buildComparisonString = (iuData) => {
      let comparisonString = iuData.level1;
      if (iuData.level2) comparisonString += `->${iuData.level2}`;
      if (iuData.level3) comparisonString += `->${iuData.level3}`;
      if (iuData.level4) comparisonString += `->${iuData.level4}`;
      return comparisonString;
    };

    for (const iu of SelectedIU) {
      const selectedIUData = NewIUList.find(
        (iuData) => buildComparisonString(iuData) === iu
      );
      const selectedLevel =
        selectedIUData.level4 || selectedIUData.level3 || selectedIUData.level2;
      const purePremium =
        PurePremiumData.find((premium) => premium.level === selectedLevel)
          ?.pure_premium || null;
      const commercialPremium =
        commercialPremiumData.find((premium) => premium.level === selectedLevel)
          ?.commercial_premium || null;
      const input = getBimodalContent(
        valueChain,
        [iu],
        typeOfProduct,
        methodofSelection,
        MethodofThreshold,
        insurer,
        aggregator,
        deductibleType,
        deductiblePercentage,
        numberOfCovers,
        coverName,
        coverDetails,
        selectedIUData?.cover_1_trigger,
        selectedIUData?.cover_1_exit,
        blockCountCalc,
        blockCountTrigger,
        blockCountExit,
        currency,
        commercialpremiumRateType,
        targetLossRatioselection,
        targetLossRatioValue,
        insurerMargin,
        productDevelopmentFee,
        brokerFee,
        [purePremium],
        [commercialPremium]
      );
      let div = document.createElement("div");
      div.innerHTML = input;
      const chartContainer = document.createElement("div");
      div.innerHTML = input;
      let element = document.getElementById("payoutchart");
      let clone = element.cloneNode(true);
      clone.style.display = "block"; // Add this line to set display to block
      div.appendChild(clone);
      div.innerHTML += generateDefinitionsTable();
      let opt = {
        margin: 1,
        filename: `${iu}_Termsheet.pdf`,
        // html2canvas: { scale: 2 },
        // jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };

      const pdfBlob = await html2pdf().from(div).set(opt).outputPdf("blob");

      zip.file(`${iu}_Termsheet.pdf`, pdfBlob);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "Termsheet_Documents.zip");
    });
  };

  return (
    <div
      className="card"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 160px)",
        position: "relative",
        paddingBottom: "50px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <div
            style={{
              color: "black",
              marginBottom: 15,
              textAlign: "left",
              fontSize: "16px",
              lineHeight: "26px",
              letterSpacing: "-0.04px",
            }}
          >
            Download for
          </div>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="deductibleType"
              name="deductibleType"
              value={IuType}
              onChange={(event) => setIuType(event.target.value)}
            >
              <FormControlLabel
                style={{ marginLeft: "10px", marginRight: "60px" }}
                value="All IU's"
                control={<OrangeRadio />}
                label="All IU's"
              />
              <FormControlLabel
                value="Individual IU's"
                control={<OrangeRadio />}
                label="Individual IU's"
              />
            </RadioGroup>
          </FormControl>

          <div style={{ width: "100%", marginTop: "20px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ position: "relative", marginRight: "10px" }}>
                <IoSearchOutline
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#777",
                  }}
                />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Choose IU's"
                  style={{
                    paddingLeft: "30px",
                    width: "45vw",
                    height: "36px",
                    fontSize: "14px",
                    borderRadius: "8px",
                    border: "1px solid rgba(26, 26, 0, 0.16)",
                  }}
                />
              </div>
              <div
                style={{
                  border: "1px solid rgba(26, 26, 0, 0.16)",
                  gap: "4px",
                  borderRadius: "8px",
                  height: "36px",
                  width: "60px",
                  padding: "8px",
                  display: "flex",
                }}
              >
                <IconButton
                  style={{ width: "20px", height: "20px" }}
                  onClick={handleSelectAll}
                  disabled={IuType !== "Individual IU's"}
                >
                  <Checkbox checked={selectAllChecked} />
                </IconButton>
                <IconButton
                  style={{ width: "20px", height: "20px", padding: 0 }}
                  onClick={handleMenuOpen}
                >
                  <FaCaretDown />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "250px",
                    padding: 10,
                  }}
                >
                  <MenuItem
                    onClick={handleSelectAll}
                    disabled={selectAllChecked || IuType !== "Individual IU's"}
                  >
                    Select All
                  </MenuItem>
                  <MenuItem
                    onClick={handleClearAll}
                    disabled={!selectAllChecked}
                  >
                    Clear All
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div
              className="list"
              style={{
                textAlign: "left",
                padding: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {filteredCheckboxes.map((checkbox, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <OrangeCheckbox
                      checked={checkbox.checked}
                      onChange={() => handleCheckboxChange(index)}
                      disabled={IuType !== "Individual IU's"}
                    />
                  }
                  label={
                    <span style={{ marginLeft: 10 }}>{checkbox.name}</span>
                  }
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
                    width: "100%",
                    padding: "6px",
                  }}
                />
              ))}
            </div>
          </div>
          <div id="payoutchart" style={{ display: "none", width: "100%" }}>
            <PayoutChart data={props.payout} />
          </div>
          <div
            style={{
              color: "black",
              marginBottom: 20,
              textAlign: "left",
              fontSize: "16px",
              lineHeight: "26px",
              letterSpacing: "-0.04px",
              marginTop: "20px",
            }}
          >
            Export
          </div>

          <div style={{ display: "flex", marginTop: "10px" }}>
            <FormControlLabel
              control={
                <OrangeCheckbox
                  checked={termsheetChecked}
                  onChange={handleTermsheetChange}
                />
              }
              label="Termsheet"
              style={{ marginRight: "50px" }}
            />
            <FormControlLabel
              control={
                <OrangeCheckbox
                  checked={dataChecked}
                  onChange={handleDataChange}
                />
              }
              label="Data"
            />
          </div>
        </div>
      </div>
      <Button
        variant="outlined"
        startIcon={<IoDownloadOutline />}
        onClick={downloadPdfDocument}
        style={{
          display: "flex",
          height: "35.527px",
          padding: "8px 16px",
          alignItems: "center",
          gap: "8px",
          flexShrink: 0,
          borderRadius: "8px",
          border: "1px solid #451E11",
          position: "absolute",
          bottom: "10px",
          right: "10px",
        }}
      >
        Download
      </Button>
    </div>
  );
};

export default DownloadTermsheet;
