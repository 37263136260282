import { useEffect } from "react";
import OLTileLayer from "ol/layer/Tile";
import { useMap } from "../../../../hooks/useMap";

const TileLayer = ({ source, zIndex = 0, name = "" }) => {
  const { map } = useMap();

  useEffect(() => {
    if (!map) return;
    let tileLayer = new OLTileLayer({
      source,
      zIndex,
    });

    map.addLayer(tileLayer);
    if (name !== "") tileLayer.set("name", name);
    tileLayer.setZIndex(zIndex);

    return () => {
      if (map) {
        map.removeLayer(tileLayer);
      }
    };
  }, [map, source.key_]);

  return null;
};

export default TileLayer;
