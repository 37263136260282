
import React from "react";
import '../../login.css'; 
import { BsSend } from "react-icons/bs";

export const Success = (props) => {
    
  const handleSubmit = (e) => {
    props.setLoginscreen(true)
    
  };
  return (
    <>
       <div className="login-right">
            <div className='loginpannel'>
              <div style={{display:'flex', justifyContent:'center'}}>
            <BsSend style={{ width: '56px', height: '56px', marginBottom: '47px' }}/>
              </div>
              <div className='loginheading'>Password reset successful</div>
              <div className='subheading'>Your password has been successfully updated.</div>
              <form onSubmit={handleSubmit}>
                <button type="submit" className='loginbutton' style={{ backgroundColor: 'black', marginBottom: '28px' }} >Continue to login</button>
              </form>
            </div>
          </div>
    </>
  );
};
