import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeatmapComponent from "./HeatmapComponent";
import { useDispatch, useSelector } from "react-redux";
import ColumnRangeGraph from "./ColumnRangeGraph";
import ColumnRangeGraphRainfall from "./ColumnRangeGraphRainfall";
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import ColumnRangeGraphVegetation from "./ColumnRangeGraphVegetation";
const AccordionComponent = (props) => {
  const frequency = useSelector((state) => state.frequency);
  const stateName = useSelector((state) => state.stateName);
  const districtName = useSelector((state) => state.districtName);
  const getStartYears = useSelector((state) => state.getStartYears);
  const getStartMonth = useSelector((state) => state.getStartMonth);
  const getEndMonth = useSelector((state) => state.getEndMonth);
  const getEndYears = useSelector((state) => state.getEndYears);
  const RainfallDataset = useSelector((state) => state.RainfallDataset);
  const getWeatherVariable = useSelector((state) => state.getWeatherVariable);
  const [TamsatDailyData, setTamsatDailyData] = useState(null);
  const [Arc2DailyData, setArc2DailyData] = useState(null);
  const [Era5DailyRainfallData, setEra5DailyRainfallData] = useState(null);
  const [Era5DailyTemperatureData, setEra5DailyTemperatureData] = useState([]);
  const [Era5MonthlyRainfallData, setEra5MonthlyRainfallData] = useState(null);
  const [Era5MonthlyTemperatureData, setEra5MonthlyTemperatureData] = useState([]);
  const [ChirpsMonthlyData, setChirpsMonthlyData] = useState(null);
  const [TamsatMonthlyData, setTamsatMonthlyData] = useState(null);
  const [Arc2MonthlyData, setArc2MonthlyData] = useState(null);
  const [ErasMonthlyData, setErasMonthlyData] = useState(null);
  const [ChirpsDailyData, setChirpsDailyData] = useState(null);
  const [ModisDailyData, setModisDailyData] = useState(null);
  const [gsMapDailyData, setgsMapDailyData] = useState(null);
  const [gsMapMonthlyData, setgsMapMonthlyData] = useState(null);
  const [Sentinal2DailyData, setSentinal2DailyData] = useState(null);
  const [ModisMonthlyData, setModisMonthlyData] = useState(null);
  const [loading, setLoading] = useState(true); // Initialize loading state
const compareState = useSelector((state) => state.compareState);

  useEffect(() => {
    async function fetchData() {
      setLoading(true); // Set loading to true before fetching data
      let datasets = [];
      let dailyStates = [];
      let monthlyStates = [];
  
   
      if (frequency === 'Daily') {
        if (getWeatherVariable === 'Rainfall') {
            datasets = ['chirpsDaily', 'arc2Daily', 'tamsatDaily', 'era5DailyRainfall', 'gsMapDaily'];
            dailyStates = [setChirpsDailyData, setArc2DailyData, setTamsatDailyData, setEra5DailyRainfallData, setgsMapDailyData];
        } else if (getWeatherVariable === 'Temperature') {
            datasets = ['era5DailyTemperature'];
            dailyStates = [setEra5DailyTemperatureData];
        } 
    } else if (frequency == '5 day') {
        if (getWeatherVariable === 'Vegetation') {
            datasets = ['sentinel2NDVI'];
            dailyStates = [setSentinal2DailyData];
        }
    } else if (frequency == '10 day') {
        if (getWeatherVariable === 'Vegetation') {
            datasets = ['modisNDVI'];
            dailyStates = [setModisDailyData];
        }
    } else {
        if (getWeatherVariable === 'Rainfall') {
            datasets = ['chirpsMonthly', 'tamsatMonthly', 'arc2Monthly', 'era5MonthlyRainfall', 'gsMapMonthly'];
            monthlyStates = [setChirpsMonthlyData, setTamsatMonthlyData, setArc2MonthlyData, setEra5MonthlyRainfallData, setgsMapMonthlyData];
        } else if (getWeatherVariable === 'Temperature') {
            datasets = ['era5MonthlyTemperature'];
            monthlyStates = [setEra5MonthlyTemperatureData];
        } 
    }
  
      const promises = datasets.map((dataset, index) =>
        fetch("api/compare/getMinMaxGraph?country=" + props.countryName +
          "&level1=" + stateName +
          "&level2=" + districtName +
          "&start_year=" + getStartYears +
          "&end_year=" + getEndYears +
          "&start_month=" + getStartMonth +
          "&end_month=" + getEndMonth +
          "&dataset=" + dataset, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // Authorization: jwtToken,
            },
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            if (frequency === 'Daily' ||frequency == '5 day' ||frequency == '10 day') {
              dailyStates[index](data);
            } else {
              monthlyStates[index](data);
            }
          })
          .catch(error => {
            console.error("Error fetching data:", error);
            if (frequency === 'Daily') {
              dailyStates[index](null);
            } else {
              monthlyStates[index](null);
            }
          })
       
          .catch(error => {
            console.error("Error fetching data:", error);
            setChirpsDailyData(null);
            setChirpsMonthlyData(null);
            setArc2DailyData(null);
            setTamsatDailyData(null);
            setEra5DailyRainfallData(null);
            setEra5DailyTemperatureData(null);
            setTamsatMonthlyData(null);
            setArc2MonthlyData(null);
            setEra5MonthlyRainfallData(null);
            setEra5MonthlyTemperatureData(null);
          })
      );
  
      await Promise.all(promises);
      setLoading(false); // Set loading to false after fetching data
    }
  
    fetchData();
  }, [compareState]);
  
  return (
    <>
      {getWeatherVariable == "Rainfall" ? (
        <div>
          {(props.countryName === "Kenya") || (props.countryName === "Sudan" && frequency === "Monthly") || props.countryName === "Zambia" || props.countryName === "Tanzania" || (props.countryName === "Bangladesh") ?
            <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="chirps-content"
                id="chirps-header"
                sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
              >
                <Typography>CHIRPS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                  <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
                </div>
                ) : (
                  <>
                    {frequency === 'Daily' && ChirpsDailyData && <ColumnRangeGraphRainfall data={ChirpsDailyData} graphName="chirps" />}
                    {frequency !== 'Daily' && ChirpsMonthlyData && <ColumnRangeGraphRainfall data={ChirpsMonthlyData} graphName="chirps" />}
                    {!ChirpsDailyData && !ChirpsMonthlyData && <Typography>No data available</Typography>}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            : null}
          {props.countryName !== "Bangladesh" ?
            <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="chirps-content"
                id="chirps-header"
                sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
              >
                <Typography>TAMSAT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                  <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
                </div>
                ) : (
                  <>
                    {frequency === 'Daily' && TamsatDailyData && <ColumnRangeGraphRainfall data={TamsatDailyData} graphName="Tamsat" />}
                    {frequency !== 'Daily' && TamsatMonthlyData && <ColumnRangeGraphRainfall data={TamsatMonthlyData} graphName="Tamsat" />}
                    {!TamsatDailyData && !TamsatMonthlyData && <Typography>No data available</Typography>}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            : null}
             {props.countryName=="Zambia" && frequency == "Daily"|| props.countryName=="Tanzania"  && frequency == "Daily"|| props.countryName=="Sudan"  && frequency == "Daily" || props.countryName=="Kenya"  && frequency == "Daily"? 
               <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="chirps-content"
            id="chirps-header"
            sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
          >
            <Typography>ARC 2</Typography>
          </AccordionSummary>
          <AccordionDetails>
          {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                  <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
                </div>
                ) : (
                  <>
            {frequency === 'Daily' && Arc2DailyData && <ColumnRangeGraphRainfall data={Arc2DailyData} graphName="Arc2" />}
            {frequency !== 'Daily' && Arc2MonthlyData && <ColumnRangeGraphRainfall data={Arc2MonthlyData} graphName="Arc2" />}
            {!Arc2DailyData && !Arc2MonthlyData && <Typography>No data available</Typography>}
            </>
                )}
          </AccordionDetails>
        </Accordion>
        :
        null}
          {props.countryName === "Zambia" || props.countryName === "Tanzania" ||  props.countryName === "Bangladesh" || props.countryName=="Kenya" || props.countryName == "Sudan" ?
            <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="chirps-content"
                id="chirps-header"
                sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
              >
                <Typography>ERA 5 Rainfall</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                    <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
                  </div>
                ) : (
                  <>
                    {frequency === 'Daily' && Era5DailyRainfallData && <ColumnRangeGraphRainfall data={Era5DailyRainfallData} graphName="Eras" />}
                    {frequency !== 'Daily' && Era5MonthlyRainfallData && <ColumnRangeGraphRainfall data={Era5MonthlyRainfallData} graphName="Eras" />}
                    {!Era5DailyRainfallData && !Era5MonthlyRainfallData && <Typography>No data available</Typography>}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            : null}
              { props.countryName === "Zambia" || props.countryName === "Tanzania" || props.countryName === "Bangladesh" || props.countryName=="Kenya"|| props.countryName == "Sudan" ?
            <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="chirps-content"
                id="chirps-header"
                sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
              >
                <Typography>GSMAP</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                    <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
                  </div>
                ) : (
                  <>
                    {frequency === 'Daily' && gsMapDailyData && <ColumnRangeGraphRainfall data={gsMapDailyData} graphName="Eras" />}
                    {frequency !== 'Daily' && gsMapMonthlyData && <ColumnRangeGraphRainfall data={gsMapMonthlyData} graphName="Eras" />}
                    {!gsMapDailyData && !gsMapMonthlyData && <Typography>No data available</Typography>}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            : null} 
        </div>
       ) : getWeatherVariable === "Temperature" ? (
        <div>
          {props.countryName === "Zambia" || props.countryName === "Tanzania"  ||  props.countryName === "Bangladesh"|| props.countryName=="Kenya" || props.countryName == "Sudan" ?
            <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="chirps-content"
                id="chirps-header"
                sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
              >
                <Typography>ERA 5 Temperature</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                  <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
                </div>
                ) : (
                  <>
                    {frequency === 'Daily' && Era5DailyTemperatureData && <ColumnRangeGraph data={Era5DailyTemperatureData} graphName="Eras" />}
                    {frequency !== 'Daily' && Era5MonthlyTemperatureData && <ColumnRangeGraph data={Era5MonthlyTemperatureData} graphName="Eras" />}
                    {!Era5DailyTemperatureData && !Era5MonthlyTemperatureData && <Typography>No data available</Typography>}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            :
            <div>No Datasets available</div>
          }
      
        </div>
        ) : getWeatherVariable === "Vegetation" ? (
          <div>
               {frequency == "5 day" ? 
          <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="chirps-content"
                id="chirps-header"
                sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
              >
                <Typography>SENTINEL-2 </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                  <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
                </div>
                ) : (
                  <>
                    {frequency === '5 day' && Sentinal2DailyData && <ColumnRangeGraphVegetation data={Sentinal2DailyData} graphName="Sentinel2" />}
                    {/* {frequency !== '5 day ' && Sentinal2DailyData && <ColumnRangeGraphVegetation data={Sentinal2DailyData} graphName="Sentinel2" />} */}
                    { !Sentinal2DailyData && <Typography>No data available</Typography>}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            :
              <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="chirps-content"
                id="chirps-header"
                sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
              >
                <Typography>MODIS EVIIRS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                  <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
                </div>
                ) : (
                  <>
                    { ModisDailyData && <ColumnRangeGraphVegetation data={ModisDailyData} graphName="Eras" />}
                    {/* {frequency !== '10 Day ' && ModisDailyData && <ColumnRangeGraphVegetation data={ModisDailyData} graphName="Eras" />} */}
                    { !ModisDailyData && <Typography>No data available</Typography>}
                  </>
                )}
              </AccordionDetails>
            </Accordion>}
            </div>
) : null}
    
    </>
  );
  
};

export default AccordionComponent;
