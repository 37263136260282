import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Sidebar } from "./Sidebar";
import Explore from "./Explore/Explore";
import Download from "./Download/Download";
import Analytics from "./Analytics/Analytics";
import { Auth, Analytics as Analytics2 } from "aws-amplify";
import PricingAnalytics from "./PricingAnalytics/PricingAnalytics";
import Manual from "./PricingAnalytics/Manual";
import { useSelector } from "react-redux";
import ManualStep2 from "./PricingAnalytics/ManualStep2";

export const MainLayout = (props) => {
  const [explorepannel, setExplorepannel] = useState(true);
  const [menu, setMenu] = useState("Explore");
  const [level3Data, setLevel3Data] = useState(null);
  const [level4Data, setLevel4Data] = useState(null);
  const [stateName, setStateName] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState("Climate Data Library");
  const showManual = useSelector((state) => state.showManual);
  const step = useSelector((state) => state.step);
  const showuploadsite = useSelector((state) => state.showuploadsite);
  const [loading, setLoading] = useState(false);

  const handleMenuClick = (selectedMenu) => {
    setMenu(selectedMenu);
  };
  // useEffect(() => {
  //   async function genToken() {
  //     try {
  //       const user = await Auth.currentAuthenticatedUser();
  //       const email = user.attributes.email;

  //       Analytics2.autoTrack('session', {
  //         enable: true,
  //         attributes: {
  //           email: email,
  //           page: menu
  //         },
  //       });
  //       //console.log("The email and page is",email,menu)
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   }

  //   genToken();
  // }, [menu]);

  return (
    <>
      <Header
        email={props.email}
        setEmail={props.setEmail}
        selectedIcon={selectedIcon}
        setSelectedIcon={setSelectedIcon}
      />

      <>
        {selectedIcon === "Climate Data Library" && (
          <div style={{ marginTop: "45px" }}>
            <Sidebar
              setMenu={handleMenuClick}
              menu={menu}
              selectedIcon={selectedIcon}
              setSelectedIcon={setSelectedIcon}
            />
            {menu === "Explore" ? (
              explorepannel ? (
                <Explore
                  setLevel3Data={setLevel3Data}
                  setLevel4Data={setLevel4Data}
                  setStateName={setStateName}
                />
              ) : null
            ) : menu === "Download" ? (
              <Download />
            ) : menu === "Project" ? (
              <Analytics />
            ) : (
              ""
            )}
          </div>
        )}
        {selectedIcon === "Pricing" && (
          <div style={{ marginTop: "45px" }}>
            <Sidebar
              setMenu={handleMenuClick}
              menu={menu}
              selectedIcon={selectedIcon}
              setSelectedIcon={setSelectedIcon}
            />
            {menu === "Download" ? (
              <Download />
            ) : menu === "Project" ? (
              showManual ? (
                step === 1 ? (
                  <Manual loading={loading} setLoading={setLoading} />
                ) : step === 2 || step === 3 ? (
                  <ManualStep2 loading={loading} setLoading={setLoading} />
                ) : (
                  <PricingAnalytics />
                )
              ) : (
                <PricingAnalytics />
              )
            ) : (
              ""
            )}
          </div>
        )}
      </>
    </>
  );
};
