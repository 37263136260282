function getBimodalContent(
  valueChain,
  SelectedIU,
  typeOfProduct,
  methodofSelection,
  MethodofThreshold,
  insurer,
  aggregator,
  deductibleType,
  deductiblePercentage,
  numberOfCovers,
  coverName,
  coverDetails,
  selectedIUCover1_weathertrigger,
  selectedIUCover1_weatherexit,
  blockCountCalc,
  blockCountTrigger,
  blockCountExit,
  currency,
  commercialpremiumRateType,
  targetLossRatioselection,
  targetLossRatioValue,
  insurerMargin,
  productDevelopmentFee,
  brokerFee,
  selectedIUpurepremium, // Use selected pure premium here
  selectedIUcommercial_premium
) {
  return (
    `<div style="padding: 25px; font-size: 12px;">
      <div style="position: relative; text-align: center; margin-bottom: 40px;">
        <img src="agtuall.png" alt="Icon" style="position: absolute; top: 0; right: 0; width: 150px; height: 70px;">
      </div>
      <div style="position: relative; text-align: center;">
        <h4 style="margin: 0;font-size: 14px;"><u>Term Sheet: ${valueChain}</u></h4>
      </div>
      <div>
        <ul style="list-style-type: none; padding-left: 0; margin-bottom: 30px;">
          <li><strong>Location (Country/State/Districts/IU): </strong> Bangladesh, ${SelectedIU}</li>
          <li><strong>Type of Product: </strong> ${typeOfProduct}</li>
          <li><strong>Cluster mechanism: </strong> ${methodofSelection}</li>
          <li><strong>Insurer: </strong> ${insurer}</li>
          <li><strong>Aggregator: </strong> ${aggregator}</li>
          <li><strong>Deductible Type : </strong> ${deductibleType}</li>
          <li><strong>Deductible Percentage (%): </strong> ${deductiblePercentage}</li>
        </ul>
      </div>
      <!-- Second Section -->
      <div style="margin-bottom: 10px;">
        <h3>Cover Details</h3>
      </div>
      <table style="width: 100%; border-collapse: collapse;">
        <thead>
          <tr>
            <th style="border: 1px solid #B8B8B8; padding: 8px; text-align: left; font-weight: bold; background-color: lightgrey;"></th>
            ${numberOfCovers >= 1  ? `<th style="border: 1px solid #B8B8B8; padding: 8px; text-align: left; font-weight: bold; background-color: lightgrey;">Cover1</th>` : ''}
            ${numberOfCovers >= 2  ? `<th style="border: 1px solid #B8B8B8; padding: 8px; text-align: left; font-weight: bold; background-color: lightgrey;">Cover2</th>` : ''}
            ${numberOfCovers >= 3  ? `<th style="border: 1px solid #B8B8B8; padding: 8px; text-align: left; font-weight: bold; background-color: lightgrey;">Cover3</th>` : ''}
          </tr>
        </thead>
        <tbody>
          ${generateTableRow('Cover Name', coverName, coverDetails.cover2Name, numberOfCovers)}
          ${generateTableRow('Risk covered', coverDetails.riskCovered, coverDetails.cover2_riskCovered, numberOfCovers)}
          ${generateTableRow('Dataset', coverDetails.datasets, coverDetails.cover2_datasets, numberOfCovers)}
          ${generateTableRow('Start Date', formatDate(coverDetails.coverstartDate), coverDetails.cover2_startDate ? formatDate(coverDetails.cover2_startDate) : '', numberOfCovers)}
          ${generateTableRow('End Date', formatDate(coverDetails.coverendDate), coverDetails.cover2_endDate ? formatDate(coverDetails.cover2_endDate) : '', numberOfCovers)}
          ${generateTableRow('Weightage(%)', coverDetails.weightage, coverDetails.cover2_weightage, numberOfCovers)}
          ${generateTableRow('Cover Period Type', coverDetails.coverPeriodType, coverDetails.cover2_coverperiodtype, numberOfCovers)}
          ${generateTableRow('Method of Threshold Calculation', MethodofThreshold, coverDetails.cover2_MethodofThresholdCalc, numberOfCovers)}
          ${generateTableRow('Weather Trigger', selectedIUCover1_weathertrigger, coverDetails.cover2_WeatherTrigger, numberOfCovers)}
          ${generateTableRow('Weather Exit', selectedIUCover1_weatherexit, coverDetails.cover2_WeatherExit, numberOfCovers)}
          ${generateTableRow('Minimum Payout (%)', coverDetails.coverMinPayout, coverDetails.cover2_minimumPayout, numberOfCovers)}
          ${generateTableRow('Maximum Payout (%)', coverDetails.coverMaxPayout, coverDetails.cover2_maximumPayout, numberOfCovers)}
          ${coverDetails.coverPeriodType === 'Full Phase' ? generateTableRow('Phase Calculation', coverDetails.phaseCalculation, coverDetails.cover2_phaseCalculation, numberOfCovers) : ''}
          ${coverDetails.coverPeriodType === 'Block' ?
            `
            ${generateTableRow('Block Size(days)', coverDetails.blockSize, coverDetails.cover2_blockSize, numberOfCovers)}
            ${generateTableRow('Risk Interval(days)', coverDetails.riskInterval, coverDetails.cover2_riskInterval, numberOfCovers)}
            ${generateTableRow('Within Block Calculation', coverDetails.withinBlockCalc, coverDetails.cover2_withinBlockCalc, numberOfCovers)}
            ${generateTableRow('Across Block Calculation', coverDetails.acrossBlockCalc, coverDetails.cover2_acrossBlockCalc, numberOfCovers)}
            ` : ''}
          ${coverDetails.acrossBlockCalc === 'Count' ?
            `
            ${generateTableRow('Method of Count Calculation', blockCountCalc, coverDetails.cover2_methodOfCountCalculation, numberOfCovers)}
            ${generateTableRow('Block Count Trigger', blockCountTrigger, coverDetails.cover2_blockCountTrigger, numberOfCovers)}
            ${generateTableRow('Block Count Exit', blockCountExit, coverDetails.cover2_blockCountExit, numberOfCovers)}
            ` : ''}
          ${coverDetails.coverPeriodType === 'Risky Days' ?
            `
            ${generateTableRow('Risky Day Calculation', coverDetails.riskyDayCalculation, coverDetails.cover2_riskyDayCalculation, numberOfCovers)}
            ${generateTableRow('Risky Days Trigger', coverDetails.riskyDaysTrigger, coverDetails.cover2_riskyDaysTrigger, numberOfCovers)}
            ${generateTableRow('Risky Days Exit', coverDetails.riskyDaysExit, coverDetails.cover2_riskyDaysExit, numberOfCovers)}
            ` : ''}
        </tbody>
      </table>
      <!-- Third Section -->
      <!-- Summary for Premium Parameters in IU Section -->
      <div style=" margin-bottom: 10px;page-break-before: always;;">
        <h3>Summary for Premium Calculation for Bangladesh, ${SelectedIU}</h3>
      </div>
      <div>
        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
          <thead>
            <tr>
              <th style="border: 1px solid #B8B8B8; padding: 8px; text-align: left; font-weight: bold; background-color: lightgrey;">Pure Premium (%)</th>
              <th style="border: 1px solid #B8B8B8; padding: 8px; text-align: left; font-weight: bold; background-color: lightgrey;">Gross Premium(%)</th>
            </tr>
          </thead>
          <tbody>
            ${generatePremiumSummaryTableRow(selectedIUpurepremium, selectedIUcommercial_premium)}
          </tbody>
        </table>
      </div>
      ${IUAnalysisChart(SelectedIU)}
    </div>`
  );
}

function generateTableRow(field, cover1Value, cover2Value, numberOfCovers) {
  return `
    <tr>
      <td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">${field}</td>
      ${numberOfCovers >= 1 ? `<td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">${cover1Value || ''}</td>` : ''}
      ${numberOfCovers >= 2 ? `<td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">${cover2Value || ''}</td>` : ''}
      ${numberOfCovers >= 3 ? `<td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;"></td>` : ''}
      ${numberOfCovers >= 4 ? `<td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;"></td>` : ''}
    </tr>
  `;
}

function generatePremiumSummaryTableRow(cover4Value, grossValue) {
  return `
    <tr>
      <td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">${cover4Value || ''}</td>
      <td style="border: 1px solid #B8B8B8; padding: 8px; text-align: left;">${grossValue || ''}</td>
    </tr>
  `;
}

function IUAnalysisChart(SelectedIU) {
  return `
    <div style="background-color: white; padding: 7px; margin-bottom: 20px;">
      <!-- Placeholder for IU Analysis Chart -->
      <h4>Historical Payout Analysis for Product</h4>
    </div>
  `;
}


function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: '2-digit' };
  return date.toLocaleDateString('en-US', options);
}

export default getBimodalContent;
