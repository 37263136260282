import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown';
import highchartsMaps from 'highcharts/modules/map';
import HighchartsReact from 'highcharts-react-official';

// Initialize the highcharts-maps module
highchartsMaps(Highcharts);
drilldown(Highcharts);
const RiskMap = ({ countryName }) => {
    useEffect(() => {
        let chartInstance;
        (async () => {
            let mapKey = '';
            if (countryName === 'Tanzania') {
                mapKey = 'tz';
            } else if (countryName === 'Zambia') {
                mapKey = 'zm';
            } else if (countryName === 'Sudan') {
                mapKey = 'sd';
            } else if (countryName === 'Kenya') {
                mapKey = 'ke';
            } else if (countryName === 'Bangladesh') {
                mapKey = 'bd';
            } else {
                // Default to US map
                mapKey = 'us';
            }

            const topology = await fetch(
                `https://code.highcharts.com/mapdata/countries/${mapKey}/${mapKey}-all.topo.json`
            ).then(response => response.json());

            const data = Highcharts.geojson(topology);

            const mapView = topology.objects.default['hc-recommended-mapview'];

            // Set drilldown pointers
            data.forEach((d, i) => {
                d.drilldown = d.properties['hc-key'];
                d.value = i; // Non-random bogus data
            });

            // Instantiate the map
            chartInstance = Highcharts.mapChart('riskMapContainer', {
                chart: {
                    events: {
                        drilldown: drilldown,
                        afterDrillUp: afterDrillUp
                    }
                },
                title: {
                    text: ''
                },
                colorAxis: {
                    min: 0,
                    minColor: '#E6E7E8',
                    maxColor: '#005645'
                },
                mapView: mapView,
                mapNavigation: {
                    enabled: true,
                    buttonOptions: {
                        verticalAlign: 'bottom'
                    }
                },
                plotOptions: {
                    map: {
                        states: {
                            hover: {
                                color: '#EEDD66'
                            }
                        }
                    }
                },
                series: [{
                    data: data,
                    name: countryName,
                    dataLabels: {
                        enabled: true,
                        format: '{point.properties.postal-code}'
                    },
                    custom: {
                        mapView: mapView
                    }
                }],
                drilldown: {
                    activeDataLabelStyle: {
                        color: '#FFFFFF',
                        textDecoration: 'none',
                        textOutline: '1px #000000'
                    },
                    breadcrumbs: {
                        floating: true
                    },
                    drillUpButton: {
                        relativeTo: 'spacingBox',
                        position: {
                            x: 0,
                            y: 60
                        }
                    }
                }
            });
        })();

        // Clean-up function
        return () => {
            if (chartInstance) {
                chartInstance.destroy(); // Destroy chart instance on component unmount
            }
        };
    }, [countryName]);


    const drilldown = async (e) => {
        if (!e.seriesOptions) {
            const chart = e.target,
                mapKey = `${e.point.drilldown}`;
    
            // Handle error, the timeout is cleared on success
            let fail = setTimeout(() => {
                if (!Highcharts.maps[mapKey]) {
                    chart.showLoading(`
                        <i class="icon-frown"></i>
                        Failed loading ${e.point.name}
                    `);
                    fail = setTimeout(() => {
                        chart.hideLoading();
                    }, 1000);
                }
            }, 3000);
    
            // Show the Font Awesome spinner
            chart.showLoading('<i class="icon-spinner icon-spin icon-3x"></i>');
    
            // Load the drilldown map
            const topology = await fetch(
                `https://code.highcharts.com/mapdata/countries/us/${mapKey}-all.topo.json`
            ).then(response => response.json());
    
            const data = Highcharts.geojson(topology);
    
            // Set a non-random bogus value
            data.forEach((d, i) => {
                d.value = i;
            });
    
            // Apply the recommended map view if any
            chart.mapView.update(topology.objects.default['hc-recommended-mapview']);

    
            // Hide loading and add series
            chart.hideLoading();
            clearTimeout(fail);
            chart.addSeriesAsDrilldown(e.point, {
                name: e.point.name,
                data: data,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                }
            });
        }
    };
    

    const afterDrillUp = (e) => {
        if (e.seriesOptions.custom && e.seriesOptions.custom.mapView) {
          // Update mapView with only necessary properties
          e.target.mapView.update({
            insets: undefined,
            padding: 0,
          });
        }
      };

    return (
        <div id="riskMapContainer" style={{ width: '100%', height: '500px' }}>
        </div>
    );
};

export default RiskMap;
