import React, { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
} from "@mui/material";

const DefinitionModal = ({ showModal, setShowModal }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const closeModal = () => {
    setShowModal(false);
  };

  const rows = [
    {
      id: 1,
      datasetName: "Chirps 5km Daily Rainfall",
      source:
        "Climate Hazards Group InfraRed Precipitation with Station data (CHIRPS)",
      frequency: "Daily",
      spatialResolution: " 0.05° (∼5.3 km)",
    },
    {
      id: 2,
      datasetName: "Chirps 5km Monthly Rainfall",
      source:
        "Climate Hazards Group InfraRed Precipitation with Station data (CHIRPS)",
      frequency: "Monthly",
      spatialResolution: " 0.05° (∼5.3 km)",
    },
    {
      id: 3,
      datasetName: "ARC2 10km Daily Rainfall",
      source: "Africa Rainfall Climatology version 2 (ARC2)",
      frequency: "Daily Rainfall",
      spatialResolution: "0.1  degree × 0.1 degree 11km",
    },
    {
      id: 4,
      datasetName: "TAMSAT Daily Rainfall",
      source:
        "(Tropical Applications of Meteorology using SATellite data and ground-based observations",
      frequency: "Daily Rainfall",
      spatialResolution: "0.0375 x 0.0375 degree spatial resolution (~4km)",
    },
    {
      id: 5,
      datasetName: "TAMSAT Monthly Rainfall",
      source:
        "(Tropical Applications of Meteorology using SATellite data and ground-based observations",
      frequency: "Monthly Rainfall",
      spatialResolution: "0.0375 x 0.0375 degree spatial resolution (~4km)",
    },
    {
      id: 6,
      datasetName: "GSMAP Daily Rainfall",
      source: "Global Satellite Mapping of Precipitation",
      frequency: "Daily Rainfall",
      spatialResolution: " 0.1 x 0.1 degree ~11 km",
    },
    {
      id: 7,
      datasetName: "GSMAP Monthly Rainfall",
      source: "Global Satellite Mapping of Precipitation",
      frequency: "Monthly Rainfall",
      spatialResolution: " 0.1 x 0.1 degree ~11 km",
    },
    {
      id: 8,
      datasetName: "ECMWF/ERA5 Daily Rainfall",
      source: "European Centre for Medium-Range Weather Forecasts",
      frequency: "Daily Rainfall",
      spatialResolution: "0.1° x 0.1°; Native resolution is 9 km.",
    },
    {
      id: 9,
      datasetName: "ECMWF/ERA5 Monthly Rainfall",
      source: "European Centre for Medium-Range Weather Forecasts",
      frequency: "Monthly Rainfall",
      spatialResolution: "0.1° x 0.1°; Native resolution is 9 km.",
    },
    {
      id: 10,
      datasetName: "ECMWF/ERA5 Daily Temperature",
      source: "European Centre for Medium-Range Weather Forecasts",
      frequency: "Daily Temperature",
      spatialResolution: "0.1° x 0.1°; Native resolution is 9 km.",
    },
    {
      id: 11,
      datasetName: "ECMWF/ERA5 Monthly Temperature",
      source: "European Centre for Medium-Range Weather Forecasts",
      frequency: "Monthly Temperature",
      spatialResolution: "0.1° x 0.1°; Native resolution is 9 km.",
    },
    {
      id: 12,
      datasetName: "SENTINEL-2  10 Day interval NDVI",
      source: "Sentinel-2 satellite",
      frequency: "10 Day interval NDVI",
      spatialResolution: "10m",
    },
    {
      id: 13,
      datasetName: "MODIS 5 Day Interval NDVI",
      source: "Moderate Resolution Imaging Spectroradiometer",
      frequency: "5 Day Interval NDVI",
      spatialResolution: "250m",
    },
  ];

  const filteredRows = rows.filter((row) =>
    row.datasetName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: "19px",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
          onClick={closeModal}
        >
          <div
            style={{
              width: "80vw",
              height: "75vh",
              backgroundColor: "white",
              borderRadius: "8px",
              position: "relative",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "rgba(41, 41, 5, 0.03)",
                padding: "10px 24px",
                borderRadius: "8px 8px 0 0",
              }}
            >
              <div>
                <h1
                  style={{
                    color: "#21201C",
                    fontFamily: "Inter-Medium, Helvetica",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "20px",
                    letterSpacing: "-0.04px",
                    margin: 0,
                  }}
                >
                  Definitions & Standards
                </h1>
              </div>
              <button
                style={{
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "transparent",
                  fontSize: "20px",
                }}
                onClick={closeModal}
              >
                &times;
              </button>
            </div>
            <div
              style={{
                padding: "16px 16px",
                height: "71vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="searchbar1"
                style={{ marginBottom: "10px", width: "100%" }}
              >
                <div
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    width: "40%",
                  }}
                >
                  <IoSearchOutline
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#777",
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Search Dataset"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{
                      paddingLeft: "30px",
                      width: "100%",
                      height: "36px",
                      fontSize: "14px",
                      borderRadius: "8px",
                      border: "1px solid rgba(26, 26, 0, 0.16)",
                    }}
                  />
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>
                        Dataset Name
                      </TableCell>
                      <TableCell style={{ fontWeight: 700 }}>Source</TableCell>
                      <TableCell style={{ fontWeight: 700 }}>
                        Frequency
                      </TableCell>
                      <TableCell style={{ fontWeight: 700 }}>
                        Spatial Resolution
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.datasetName}</TableCell>
                          <TableCell>{row.source}</TableCell>
                          <TableCell>{row.frequency}</TableCell>
                          <TableCell>{row.spatialResolution}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination */}
              <TablePagination
                rowsPerPageOptions={[4, 8, 12, 16]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Items per page:"
                nextIconButton={
                  <IconButton
                    onClick={() => setPage((prevPage) => prevPage + 1)}
                    disabled={
                      page >= Math.ceil(filteredRows.length / rowsPerPage) - 1
                    }
                  >
                    Next
                  </IconButton>
                }
                backIconButton={
                  <IconButton
                    onClick={() => setPage((prevPage) => prevPage - 1)}
                    disabled={page === 0}
                  >
                    Previous
                  </IconButton>
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DefinitionModal;
