import React from "react";
import "../../PricingAnalytics.css";
import PricingTable from "./PricingTable";

const PricingAnalytics = () => {
  return (
    <div
      className="pricing-analytics"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="download-pannel">
        <div
          style={{
            textAlign: "left",
            fontSize: "28px",
            fontWeight: 500,
            lineHeight: "28px",
            letterSpacing: "-0.04px",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "25px",
              color: "rgba(247, 104, 8, 1)",
              marginBottom: "10px",
            }}
          ></div>
          Product Listing
          <div
            style={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "25px",
              color: "rgba(7, 6, 0, 0.58)",
              marginTop: "10px",
            }}
          ></div>
        </div>
      </div>
      <PricingTable />
    </div>
  );
};

export default PricingAnalytics;
