import React, { useRef, useEffect } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DoubleGraph = ({ data1, data2 }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    if (data1.length > 0 && data2.length > 0) {
      const filteredData1 = data1.filter(item => item.dateString && item.rainfall !== null);
      const filteredData2 = data2.filter(item => item.dateString && item.rainfall !== null);

      const chartOptions = {
        chart: {
          zoomType: 'x', 
        },
        credits:false,
        title: {
          text: '',
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: '',
          },
        },
        yAxis: {
          title: {
            text: 'Rainfall (mm)',
          },
        },
        tooltip: {
          formatter: function () {
            return '<b>' + Highcharts.dateFormat('%B %e', new Date(this.x)) + '</b><br/>' +
                   this.series.name + ': ' + this.y;
          }
        },
        series: [{
          name: 'Current',
          type: 'area',
          data: filteredData1.map(item => [new Date(item.dateString).getTime(), item.rainfall]),
          tooltip: {
            valueSuffix: ' mm',
          },
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            opacity: 0.2,
            stops: [
              [0, '#EBBC00'],
              [1, 'rgba(230, 184, 82, 0.40)']
            ]
          },
        }, {
          name: 'Historical',
          type: 'area',
          data: filteredData2.map(item => [new Date(item.dateString).getTime(), item.rainfall]),
          tooltip: {
            valueSuffix: ' mm',
          },
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            opacity: 0.2,
            stops: [
              [0, '#01619E'],
              [1, 'rgba(1, 97, 158, 0.30)']
            ]
          },
        }],
      };

      Highcharts.chart('chart-container', chartOptions);
    } else {
      // Render a message when there is no data available
      document.getElementById('chart-container').innerHTML = "No Data Available for this Dataset";
    }
  }, [data1, data2]);

  return <div ref={chartRef} id="chart-container" />;
};

export default DoubleGraph;
