import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import '../../PricingAnalytics.css';
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from '@mui/material/MenuItem';
import { TextField, Select, FormControl, Radio } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import { changeEditPolicy, changeNewIUList, updateCoverDetails } from "../../redux/action";
import { withStyles, makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
const StyledTableCell = withStyles({
  root: {
    padding: '7px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'center',
    borderBottom: '2px solid #FFCCA7',
  },
})(TableCell);

const useStyles = makeStyles({
  slider: {
    color: 'rgba(247, 104, 8, 1)',
    width: '100%',
    height: '6px',
    outline: 'none',
    opacity: '0.7',
    transition: 'opacity .2s',
    borderRadius: '10px',
    marginTop: '20px',
  },
  headerCell: {
    padding: '10px 20px',
    width: '200px'
  },

  tableRow: {
    height: '30px',
  },
  textField: {
    '& .MuiInputBase-root': {
      height: '25px',
      fontSize: '16px',
    },
  },
  tableContainer: {
    maxHeight: '320px',
  },
  menuItem: {
    fontSize: '16px',
  },
});


const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: 5,
    width: '90%'
  },
  container2: {
    display: "flex",
    justifyContent: "space-between",
    padding: 5,
    width: '60%'
  },
  container3: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    width: '100%'
  },
  container4: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    width: '33%'
  },
  container5: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    width: '66%'
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginRight: 50,
  },
  fieldContainer1: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginRight: 50,
    width: '15%'
  },
  heading: {
    color: "black",
    marginBottom: 5,
    textAlign: 'left',
    fontSize: '16px',
    lineHeight: '26px',
    FontFace: 'Inter'
  },
  select: {
    borderRadius: 5,
    height: 40,
    fontSize: '16px'

  },
  textField: {
    borderRadius: 5,
    height: 30
  },
  hr: {
    width: '100%',
    border: 'none',
    borderTop: '1px solid #ccc',
    margin: '20px 0',
  },
  slider: {
    width: '100%',
    height: '6px',
    outline: 'none',
    opacity: '0.7',
    transition: 'opacity .2s',
    borderRadius: '10px',
    marginTop: '20px',
  },
  sliderValue: {
    textAlign: 'center',
    padding: '6px',
    borderRadius: '5px',
    width: '60px',
    border: '1px solid black'
  },
};

const OrangeRadio = styled(Radio)({
  '&.Mui-checked': {
    color: 'rgba(247, 104, 8, 1)',
  },
});

const Cover2 = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const coverDetails = useSelector((state) => state.coverDetails);
  const IUList = useSelector((state) => state.IUList);
  const [error, setError] = useState('');
  const [triggererror, settriggererror] = useState('');
  const [dateError, setDateError] = useState('');
  const NewIUList = useSelector((state) => state.NewIUList);
  const policyDetails = useSelector((state) => state.policyDetails);

  const handleChange = (field, value) => {
    dispatch(updateCoverDetails({ ...coverDetails, [field]: value }));
  };
  const [columns, setColumns] = useState([]);

  useEffect(() => {

    if (IUList.length > 0) {
      // Determine the number of levels in the IUList
      const highestLevel = Object.keys(IUList[0]).filter(key => key.startsWith('level')).length;
      const columnsMap = {
        2: ['level1', 'level2'],
        3: ['level2', 'level3'],
        4: ['level3', 'level4']
      };

      // Set columns based on the highest level
      setColumns(columnsMap[highestLevel] || []);

      const newRows = IUList.map(item => {
        // Determine which level keys are present
        const levels = ['level1', 'level2', 'level3', 'level4'];
        const availableLevels = levels.filter(level => item[level]);

        // Map cover values based on available levels
        const cover1Values = props.cover1rows.find(row => {
          return availableLevels.every(level => row[level] === item[level]);
        }) || {};

        return {
          level1: item.level1,
          level2: item.level2,
          level3: item.level3,
          level4: item.level4,
          cover_1_trigger: parseInt(cover1Values.cover_1_trigger), // Default value if not present
          cover_1_exit: parseInt(cover1Values.cover_1_exit), // Default value if not present
          cover_2_trigger: item.cover_2_trigger ?? parseInt(coverDetails.cover2_WeatherTrigger), // Default value if not present
          cover_2_exit: item.cover_2_exit ?? parseInt(coverDetails.cover2_WeatherExit),
        };
      });

      props.setcover2Rows(newRows);
      dispatch(changeNewIUList(newRows));
    }
  }, [IUList, coverDetails.cover2_WeatherTrigger, coverDetails.cover2_WeatherExit]);

  const columnHeaders = {
    level1: 'Division',
    level2: 'Zila',
    level3: 'Upazila',
    level4: 'Union'
  };
  const handleCellChange = (index, field, value) => {
    const newRows = [...props.cover2rows];
    newRows[index][field] = field === 'cover_2_trigger' || field === 'cover_2_exit' ? parseInt(value, 10) : value;
    props.setcover2Rows(newRows);
    dispatch(changeNewIUList(newRows));
  };
  useEffect(() => {
    if (policyDetails && policyDetails.length > 0) {
      const roundToZero = (value) => Math.round(value * 100);
  
      const conditions = {
        cover2Name: coverDetails.cover2Name !== (policyDetails[0] && policyDetails[0].cover_2_name),
        cover2_riskCovered: coverDetails.cover2_riskCovered !== (policyDetails[0] && policyDetails[0].cover_2_risk_type),
        cover2_datasets: coverDetails.cover2_datasets !== (policyDetails[0] && policyDetails[0].cover_2_dataset_type),
        cover2_startDate: coverDetails.cover2_startDate !== (policyDetails[0] && policyDetails[0].cover_2_start_date),
        cover2_endDate: coverDetails.cover2_endDate !== (policyDetails[0] && policyDetails[0].cover_2_end_date),
        cover2_weightage: (coverDetails.cover2_weightage) !== (policyDetails[0] && roundToZero(policyDetails[0].cover_2_weightage)),
        cover2_maximumPayout: (coverDetails.cover2_maximumPayout) !== (policyDetails[0] && roundToZero(policyDetails[0].cover_2_max_payout)),
        cover2_minimumPayout: (coverDetails.cover2_minimumPayout) !== (policyDetails[0] && roundToZero(policyDetails[0].cover_2_min_payout)),
        cover2_coverperiodtype: coverDetails.cover2_coverperiodtype !== (policyDetails[0] && policyDetails[0].cover_2_period_type),
        cover2_phaseCalculation: coverDetails.cover2_phaseCalculation !== (policyDetails[0] && policyDetails[0].cover_2_phase_calculation_type),
        cover2_blockSize: coverDetails.cover2_blockSize !== coverDetails.cover2_coverperiodtype=== 'Block' && policyDetails[0] && policyDetails[0].cover_2_block_size !== coverDetails.cover2_blockSize ,
        cover2_MethodofThresholdCalc: coverDetails.cover2_MethodofThresholdCalc !== (policyDetails[0] && policyDetails[0].cover_2_threshold_calculation_method),
        cover2_riskInterval: coverDetails.cover2_riskInterval !== coverDetails.cover2_coverperiodtype=== 'Block' && policyDetails[0] && policyDetails[0].cover_2_cover_2_risk_interval !== coverDetails.cover2_riskInterval ,
        cover2_WeatherTrigger: coverDetails.cover2_WeatherTrigger !== (policyDetails[0] && policyDetails[0].cover_2_trigger),
        cover2_WeatherExit: coverDetails.cover2_WeatherExit !== (policyDetails[0] && policyDetails[0].cover_2_exit),
        cover2_acrossBlockCalc: coverDetails.cover2_coverperiodtype=== 'Block' && policyDetails[0] && policyDetails[0].cover_2_across_block_method !== coverDetails.cover2_acrossBlockCalc,
        cover2_withinBlockCalc: coverDetails.cover2_coverperiodtype === 'Block' && policyDetails[0] && policyDetails[0].cover_2_within_block_method !== coverDetails.cover2_withinBlockCalc,
      };
  
  
      if (Object.values(conditions).some(condition => condition)) {
        dispatch(changeEditPolicy(true));
      } else {
        dispatch(changeEditPolicy(false));
      }
    }
  }, [
    coverDetails,
    policyDetails
  ]);
  

  useEffect(() => {
    if (policyDetails && policyDetails.length > 0) {
      const policy = policyDetails[0];
      const initialCoverDetails = {
        cover2Name: policyDetails[0].cover_2_name || '',
        cover2_riskCovered: policyDetails[0].cover_2_risk_type || '',
        cover2_datasets: policyDetails[0].cover_2_dataset_type || '',
        cover2_startDate: policyDetails[0].cover_2_start_date || '',
        cover2_endDate: policyDetails[0].cover_2_end_date || '',
        cover2_weightage: Math.round((policyDetails[0].cover_2_weightage) * 100) || '',
        cover2_maximumPayout: Math.round((policyDetails[0].cover_2_max_payout) * 100) || '',
        cover2_minimumPayout: Math.round((policyDetails[0].cover_2_min_payout) * 100) || '',
        cover2_coverperiodtype: policyDetails[0].cover_2_period_type || '',
        cover2_phaseCalculation: policyDetails[0].cover_2_phase_calculation_type || '',
        cover2_blockSize: policyDetails[0].cover_2_block_size || '',
        cover2_MethodofThresholdCalc: policyDetails[0].cover_2_threshold_calculation_method || '',
        cover2_riskInterval: policyDetails[0].cover_2_risk_interval || '',
        cover2_WeatherTrigger: policyDetails[0].cover_2_trigger || '',
        cover2_WeatherExit: policyDetails[0].cover_2_exit || '',
        cover2_acrossBlockCalc: policyDetails[0].cover_2_across_block_method || '',
        cover2_withinBlockCalc: policyDetails[0].cover_2_within_block_method || '',
      };
      dispatch(updateCoverDetails(initialCoverDetails));
      const newIUList = IUList.map((item, index) => {
        const policy = policyDetails[index];

        if (policy) {
          return {
            ...item,
            level1: policy.level1,
            level2: policy.level2,
            level3: policy.level3,
            level4: policy.level4,
            cover_1_trigger: parseInt(policy.cover_1_trigger, 10),
            cover_1_exit: parseInt(policy.cover_1_exit, 10),
            cover_2_trigger: parseInt(policy.cover_2_trigger, 10),
            cover_2_exit: parseInt(policy.cover_2_exit, 10)
          };
        }

        return item;
      });
      props.setcover2Rows(newIUList);
      dispatch(changeNewIUList(newIUList));
    }
  }, [JSON.stringify(policyDetails), JSON.stringify(IUList)]);

  const getDatasets = () => {
    switch (coverDetails.cover2_riskCovered) {
      case 'Excess Rainfall':
      case 'Deficit Rainfall':
        return [
          <MenuItem key="Chirps Daily Rainfall" value="Chirps Daily Rainfall">Chirps Daily Rainfall</MenuItem>,
          // <MenuItem key="Chirps Monthly Rainfall" value="Chirps Monthly Rainfall">Chirps Monthly Rainfall</MenuItem>,
          <MenuItem key="Grid Chirps Daily Rainfall" value="Grid Chirps Daily Rainfall">Grid Chirps Daily Rainfall</MenuItem>,
          <MenuItem key="Tamsat Daily Rainfall" value="Tamsat Daily Rainfall">Tamsat Daily Rainfall</MenuItem>,
          // <MenuItem key="Tamsat Monthly Rainfall" value="Tamsat Monthly Rainfall">Tamsat Monthly Rainfall</MenuItem>,
          <MenuItem key="Gsmap Daily Rainfall" value="Gsmap Daily Rainfall">Gsmap Daily Rainfall</MenuItem>,
          // <MenuItem key="Gsmap Monthly Rainfall" value="Gsmap Monthly Rainfall">Gsmap Monthly Rainfall</MenuItem>,
          <MenuItem key="Era5 Daily Rainfall" value="Era5 Daily Rainfall">Era5 Daily Rainfall</MenuItem>,
          // <MenuItem key="Era5 Monthly Rainfall" value="Era5 Monthly Rainfall">Era5 Monthly Rainfall</MenuItem>,
          // <MenuItem key="Arc2 Monthly Rainfall" value="Arc2 Monthly Rainfall">Arc2 Monthly Rainfall</MenuItem>,
          <MenuItem key="Arc2 Daily Rainfall" value="Arc2 Daily Rainfall">Arc2 Daily Rainfall</MenuItem>
        ];
      case 'High Temperature':
      case 'Low Temperature':
        return [
          <MenuItem key="Era5 Daily Temperature" value="Era5 Daily Temperature">Era5 Daily Temperature</MenuItem>,
          <MenuItem key="Era5 Monthly Temperature" value="Era5 Monthly Temperature">Era5 Monthly Temperature</MenuItem>
        ];
      case 'Low Vegetation':
        return [
          <MenuItem key="Sentinel Ndvi" value="Sentinel Ndvi">Sentinel-2 5 Day interval NDVI</MenuItem>,
          <MenuItem key="Modis Eviirs Ndvi" value="Modis Eviirs Ndvi">Modis 16 Day Interval NDVI</MenuItem>
        ];
      default:
        return null;
    }
  };

  useEffect(() => {
    if (coverDetails.cover2_riskCovered === 'Low Vegetation' || coverDetails.cover2_riskCovered === 'Deficit Rainfall') {
      if (parseFloat(coverDetails.cover2_WeatherExit) >= parseFloat(coverDetails.cover2_WeatherTrigger)) {
        settriggererror('Exit should be less than Trigger');
      } else {
        settriggererror('');
      }
    } else {
      if (parseFloat(coverDetails.cover2_WeatherTrigger) >= parseFloat(coverDetails.cover2_WeatherExit)) {
        settriggererror('Exit should be greater than trigger');
      } else {
        settriggererror('');
      }
    }
  }, [coverDetails.cover2_WeatherTrigger, coverDetails.cover2_WeatherExit, coverDetails.cover2_riskCovered]);
  useEffect(() => {
    if (new Date(coverDetails.cover2_startDate) > new Date(coverDetails.cover2_endDate)) {
      setDateError('Start date cannot be greater than end date');
    } else {
      setDateError('');
    }
  }, [coverDetails.cover2_startDate, coverDetails.cover2_endDate]);

  const shouldDisableWeatherExit = coverDetails.cover2_coverperiodtype === 'Risky Days' || (coverDetails.cover2_coverperiodtype === 'Block' && coverDetails.cover2_acrossBlockCalc === 'Count');
  return (
    <Accordion style={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Cover 2</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <div style={styles.container3} >
            <div style={styles.fieldContainer}>
              <div style={styles.heading}>Cover Name</div>
              <TextField
                fullWidth
                id="coverName"
                size="small"
                style={styles.textField}
                value={coverDetails.cover2Name || ''}
                onChange={(e) => handleChange('cover2Name', e.target.value)}
              />
            </div>
            <div style={styles.fieldContainer}>
              <div style={styles.heading}>Risk Covered</div>
              <FormControl fullWidth>
                <Select
                  labelId="typeOfProductLabel"
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={styles.select}
                  value={coverDetails.cover2_riskCovered || ''}
                  onChange={(e) => handleChange('cover2_riskCovered', e.target.value)}
                >
                  <MenuItem value="Excess Rainfall" className={classes.menuItem}> Excess Rainfall</MenuItem>
                  <MenuItem value="Deficit Rainfall" className={classes.menuItem}> Deficit Rainfall</MenuItem>
                  <MenuItem value="Low Temperature" className={classes.menuItem}>Low Temperature</MenuItem>
                  <MenuItem value="High Temperature" className={classes.menuItem}>High Temperature</MenuItem>
                  <MenuItem value="Low Vegetation" className={classes.menuItem}> Low Vegetation</MenuItem>

                </Select>
              </FormControl>
            </div>
            <div style={styles.fieldContainer}>
              <div style={styles.heading}>Datasets</div>
              <FormControl fullWidth>
                <Select
                  labelId="typeOfProductLabel"
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={styles.select}
                  value={coverDetails.cover2_datasets || ''}
                  onChange={(e) => handleChange('cover2_datasets', e.target.value)}
                >
                  {getDatasets()}
                </Select>
              </FormControl>

            </div>
          </div>
          <div style={styles.container3} >


            <div style={styles.fieldContainer}>
              <div style={styles.heading}>Start Date</div>
              <TextField
                id="startDate"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={coverDetails.cover2_startDate || ''}
                onChange={(e) => handleChange('cover2_startDate', e.target.value)}
                error={!!dateError}
                helperText={dateError}
              />
            </div>
            <div style={styles.fieldContainer}>
              <div style={styles.heading}>End Date</div>
              <TextField
                id="endDate"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={coverDetails.cover2_endDate || ''}
                onChange={(e) => handleChange('cover2_endDate', e.target.value)}
                error={!!dateError}
                helperText={dateError} />
            </div>
            <div style={styles.fieldContainer}>
              <div style={styles.heading}>Weightge(%)</div>
              <TextField
                fullWidth
                id="productType"
                size="small"
                style={styles.textField}
                value={coverDetails.cover2_weightage || ''}
                type="number"
                step="0.01"
                onChange={(e) => handleChange('cover2_weightage', e.target.value)}
              />
            </div>
          </div>
          <hr style={styles.hr} />

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={styles.container4} >
              <div style={styles.fieldContainer}  >
                <div style={styles.heading}>Cover Period Type</div>
                <FormControl fullWidth>
                  <Select
                    labelId="typeOfProductLabel"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={styles.select}
                    value={coverDetails.cover2_coverperiodtype || ''}
                    onChange={(e) => handleChange('cover2_coverperiodtype', e.target.value)}
                  >
                    <MenuItem value="Full Phase">Full Phase</MenuItem>
                    <MenuItem value="Block">Block</MenuItem>
                    {/* <MenuItem value="Risky Days"> Risky Days</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
            </div>
            {coverDetails.cover2_coverperiodtype === 'Full Phase' && (
              <div style={styles.container4} >
                <div style={styles.fieldContainer}  >
                  <div style={styles.heading}>Phase Calculation</div>
                  <FormControl fullWidth>
                    <Select
                      labelId="typeOfProductLabel"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      style={styles.select}
                      value={coverDetails.cover2_phaseCalculation || ''}
                      onChange={(e) => handleChange('cover2_phaseCalculation', e.target.value)}
                    >
                      <MenuItem value="Minimum">Minimum</MenuItem>
                      <MenuItem value="Maximum">Maximum</MenuItem>
                      <MenuItem value="Average"> Average</MenuItem>
                      <MenuItem value="Cumulative"> Cumulative</MenuItem>

                    </Select>
                  </FormControl>
                </div>
              </div>
            )}
            {coverDetails.cover2_coverperiodtype === 'Risky Days' && (
              <>
                <div style={styles.container4} >
                  <div style={styles.fieldContainer}  >
                    <div style={styles.heading}>Risky Day Calculation</div>
                    <FormControl fullWidth>
                      <Select
                        labelId="typeOfProductLabel"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        style={styles.select}
                      >

                        <MenuItem value="Manual"> Manual</MenuItem>
                        <MenuItem value="Percentile"> Percentile</MenuItem>

                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div style={styles.container4} >

                  <div style={styles.fieldContainer1}>
                    <div style={styles.heading}>Risky Days Trigger  </div>
                    <TextField
                      fullWidth
                      id="productType"
                      size="small"
                      style={styles.textField}
                    />
                  </div>
                  <div style={styles.fieldContainer1}>
                    <div style={styles.heading}>Risky Days Exit</div>
                    <TextField
                      fullWidth
                      id="productType"
                      size="small"
                      style={styles.textField}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          {coverDetails.cover2_coverperiodtype === 'Block' && (
            <>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={styles.container4} >

                  <div style={styles.fieldContainer1}>
                    <div style={styles.heading}>Block Size </div>
                    <TextField
                      fullWidth
                      id="productType"
                      size="small"
                      style={styles.textField}
                      value={coverDetails.cover2_blockSize || ''}
                      type="number"
                      onChange={(e) => handleChange('cover2_blockSize', e.target.value)}
                    />
                  </div>
                  <div style={styles.fieldContainer1}>
                    <div style={styles.heading}>Risk Interval</div>
                    <TextField
                      fullWidth
                      id="productType"
                      size="small"
                      style={styles.textField}
                      value={coverDetails.cover2_riskInterval || ''}
                      type="number"
                      step="0.01"
                      onChange={(e) => handleChange('cover2_riskInterval', e.target.value)}
                    />
                  </div>
                </div>
                <div style={styles.container4} >

                  <div style={styles.fieldContainer}>
                    <div style={styles.heading}>Within Block Calculation </div>
                    <FormControl fullWidth>
                      <Select
                        labelId="typeOfProductLabel"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        style={styles.select}
                        value={coverDetails.cover2_withinBlockCalc || ''}
                        onChange={(e) => handleChange('cover2_withinBlockCalc', e.target.value)}

                      >
                        <MenuItem value="Minimum">Minimum</MenuItem>
                        <MenuItem value="Maximum">Maximum</MenuItem>
                        <MenuItem value="Average"> Average</MenuItem>
                        <MenuItem value="Cumulative"> Cumulative</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div style={styles.container4} >

                  <div style={styles.fieldContainer}>
                    <div style={styles.heading}>Across Block Calculation  </div>
                    <FormControl fullWidth>
                      <Select
                        labelId="typeOfProductLabel"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        style={styles.select}
                        value={coverDetails.cover2_acrossBlockCalc || ''}
                        onChange={(e) => handleChange('cover2_acrossBlockCalc', e.target.value)}
                      >
                        {/* <MenuItem value="Count"> Count</MenuItem> */}
                        <MenuItem value="Minimum">Minimum</MenuItem>
                        <MenuItem value="Maximum">Maximum</MenuItem>
                        <MenuItem value="Average"> Average</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              {coverDetails.cover2_acrossBlockCalc === 'Count' && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={styles.container4} >

                    <div style={styles.fieldContainer}>
                      <div style={styles.heading}>Block Count Calculation</div>
                      <FormControl fullWidth>
                        <Select
                          labelId="typeOfProductLabel"
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          style={styles.select}
                          value={coverDetails.cover2_BlockCountCalc || ''}
                          onChange={(e) => handleChange('cover2_BlockCountCalc', e.target.value)}
                        >
                          <MenuItem value="Manual">Manual</MenuItem>
                          <MenuItem value="Percentile">Percentile</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div style={styles.container4} >

                    <div style={styles.fieldContainer1}>
                      <div style={styles.heading}>Block Count Trigger  </div>
                      <TextField
                        fullWidth
                        id="productType"
                        size="small"
                        style={styles.textField}
                        value={coverDetails.cover2_BlockCountTrigger || ''}
                        onChange={(e) => handleChange('cover2_BlockCountTrigger', e.target.value)}
                      />
                    </div>
                    <div style={styles.fieldContainer1}>
                      <div style={styles.heading}>Block Count Exit</div>
                      <TextField
                        fullWidth
                        id="productType"
                        size="small"
                        style={styles.textField}
                        value={coverDetails.cover2_BlockCountExit || ''}
                        onChange={(e) => handleChange('cover2_BlockCountExit', e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <hr style={styles.hr} />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={styles.container4} >

              <div style={styles.fieldContainer}>
                <div style={styles.heading}>Method of Threshold Calculation</div>
                <FormControl fullWidth>
                  <Select
                    labelId="typeOfProductLabel"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={styles.select}
                    value={coverDetails.cover2_MethodofThresholdCalc || ''}
                    onChange={(e) => handleChange('cover2_MethodofThresholdCalc', e.target.value)}
                  >
                    <MenuItem value="Manual">Manual</MenuItem>
                    <MenuItem value="Percentile">Percentile</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div style={styles.container4} >

              <div style={styles.fieldContainer1}>
                <div style={styles.heading}>Weather Trigger</div>
                <TextField
                  fullWidth
                  id="productType"
                  size="small"
                  style={styles.textField}
                  type="number"
                  step="0.01"
                  value={coverDetails.cover2_WeatherTrigger || ''}
                  onChange={(e) => handleChange('cover2_WeatherTrigger', e.target.value)}
                />
              </div>
              <div style={styles.fieldContainer1}>
                <div style={styles.heading}>Weather Exit</div>
                <TextField
                  fullWidth
                  id="productType"
                  size="small"
                  style={styles.textField}
                  type="number"
                  step="0.01"
                  value={coverDetails.cover2_WeatherExit || ''}
                  onChange={(e) => handleChange('cover2_WeatherExit', e.target.value)}
                  error={!!triggererror}
                  helperText={triggererror}
                />
              </div>
            </div>
          </div>
          <div style={{ padding: '5px', marginTop: '30px', marginBottom: '10px' }} >
            {(IUList.length > 0) &&
              <TableContainer component={Paper} className={(props.cover2rows).length > 5 ? classes.tableContainer : ''}>
                <Table style={{ borderSpacing: '0 5px' }}>
                  <TableHead>
                    <TableRow className={classes.headerRow} style={{ backgroundColor: '#FFF1E7', height: 12, lineHeight: '10px' }}>
                      {columns.map(column => (
                        <StyledTableCell key={column} className={classes.headerCell} style={{ height: '12px' }}>
                          {columnHeaders[column]}
                        </StyledTableCell>
                      ))}
                      <StyledTableCell style={{}}>Weather Trigger</StyledTableCell>
                      <StyledTableCell style={{}}>Weather Exit</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.cover2rows.map((row, index) => (
                      <TableRow key={index}>
                        {columns.map(column => (
                          <StyledTableCell key={column} style={{}}>
                            <Typography variant="body2" className="cellText">
                              {row[column]}
                            </Typography>
                          </StyledTableCell>
                        ))}

                        <StyledTableCell style={{}}>
                          <TextField
                            value={row.cover_2_trigger}
                            onChange={(e) => handleCellChange(index, 'cover_2_trigger', e.target.value)}
                            variant="outlined"
                            size="small"
                            type="number"
                            step="0.01"
                            className="textField"
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            value={row.cover_2_exit}
                            onChange={(e) => handleCellChange(index, 'cover_2_exit', e.target.value)}
                            variant="outlined"
                            size="small"
                            type="number"
                            step="0.01"
                            disabled={shouldDisableWeatherExit}
                            className={shouldDisableWeatherExit ? classes.disabledField : 'textField'}
                          />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </div>
          <div style={styles.container5} >
            <div style={styles.fieldContainer} >
              <div style={styles.heading}>Minimum Payout (%)</div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Slider
                  min={0}
                  max={56}
                  value={coverDetails.cover2_minimumPayout || 0}
                  onChange={(e, value) => handleChange('cover2_minimumPayout', value)}
                  classes={{ root: classes.slider }}
                  style={{ width: '100%' }}
                />
                <div style={{ marginLeft: '20px', ...styles.sliderValue }}>{coverDetails.cover2_minimumPayout ? coverDetails.cover2_minimumPayout : 0}</div>
              </div>
            </div>
            <div style={styles.fieldContainer} >
              <div style={styles.heading}>Maximum Payout (%)</div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Slider
                  min={0}
                  max={56}
                  value={coverDetails.cover2_maximumPayout || 0}
                  onChange={(e, value) => handleChange('cover2_maximumPayout', value)}
                  classes={{ root: classes.slider }}
                  style={{ width: '100%' }}
                />
                <div style={{ marginLeft: '20px', ...styles.sliderValue }}>{coverDetails.cover2_maximumPayout ? coverDetails.cover2_maximumPayout : 0}</div>
              </div>
            </div>

          </div>

        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Cover2;
