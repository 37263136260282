import { combineReducers } from "redux";
import { changeStateNameReducer } from "./reducers/changeStateNameReducer";
import { changeDistrictNameReducer } from "./reducers/changeDistrictNameReducer";
import { changeCountryNameReducer } from "./reducers/changeCountryNameReducer";
import { changeLevel3NameReducer } from "./reducers/changeLevel3NameReducer";
import { changeLevel4NameReducer } from "./reducers/changeLevel4NameReducer";
import { changeLevel1NameReducer } from "./reducers/changeLevel1NameReducer";
import { changeLevel2NameReducer } from "./reducers/changeLevel2NameReducer";
import { changeIndexNameReducer } from "./reducers/changeIndexNameReducer";
import { changeLandcoverswitchReducer } from "./reducers/changeLandcoverswitchReducer";
import { changeNdvimodisswitchReducer } from "./reducers/changeNdvimodiswitchReducer";
import { changeZoomReducer } from "./reducers/changeZoomReducer";
import { changegetStartYearsReducer } from "./reducers/changegetStartYearsReducer";
import {  changegetStartMonthReducer } from "./reducers/changegetStartMonthReducer";
import { changeModisNDVILayerReducer } from "./reducers/changeModisNDVILayerReducer";
import { changeSentinel2NDVILayerReducer } from "./reducers/changeSentinel2NDVILayerReducer";
import { changeLandcoverLayerReducer } from "./reducers/changeLandcoverLayerReducer";
import { changeWeathervariableReducer } from "./reducers/changeWeathervariableReducer";
import { changegetEndYearsReducer } from "./reducers/changegetEndYearsReducer";
import { changegetEndMonthReducer } from "./reducers/changegetEndMonthReducer";
import { changefrequencyReducer } from "./reducers/changefrequencyReducer";
import { changeCombinationReducer } from "./reducers/changeCombinationReducer";
import { changeTempDatasetReducer } from "./reducers/changeTempDatasetReducer ";
import { changeRainfallDatasetReducer } from "./reducers/changeRainfallDatasetReducer ";
import { changeVegetationDatasetReducer } from "./reducers/changeVegetationDatasetReducer";
import { changeCompareStateReducer } from "./reducers/changeCompareStateReducer";
import { changePixelIDReducer } from "./reducers/changePixelIDReducer";
import {changePixelNameReducer} from "./reducers/changePixelNameReducer";
import { changePixel_idReducer } from "./reducers/changePixel_idReducer";
import { changeLayerNameReducer } from "./reducers/changeLayerNameReducer";
import { changegetlocationbysearchReducer } from "./reducers/changegetlocationbysearchReducer";
import { changeshowModisNDVIDotReducer } from "./reducers/changeshowModisNDVIDotReducer";
import { changeshowSentinel2NDVIDotReducer } from "./reducers/changeshowSentinelNDVIDotReducer";
import { changeshowManualReducer } from "./reducers/changeshowManualReducer";
import { changeStepReducer } from "./reducers/changeStepReducer";
import { changeshowuploadsiteReducer } from "./reducers/changeshowuploadsiteReducer";
import { changeacrossBlockCalcReducer } from "./reducers/changeacrossBlockCalcReducer";
import { changecoverNameReducer } from "./reducers/changecoverNameReducer";
import { changeriskCoveredReducer } from "./reducers/changeriskCoveredReducer";
import { changedatasetsReducer } from "./reducers/changedatasetsReducer";
import { changecoverstartDateReducer } from "./reducers/changecoverstartDateReducer";
import { changecoverendDateReducer } from "./reducers/changecoverendDateReducer";
import { changeSelectedDistrictReducer} from "./reducers/changeSelectedDistrictsReducer";
import { changeweightageReducer } from "./reducers/changeweightageReducer";
import { changecoverPeriodTypeReducer } from "./reducers/changecoverPeriodTypeReducer";
import { changephaseCalculationReducer } from "./reducers/changephaseCalculationReducer";
import { changeTypeOfProductReducer } from "./reducers/changeTypeOfProductReducer";
import { changeProductNameReducer } from "./reducers/changeProductNameReducer ";
import { changeNumberOfCoversReducer } from "./reducers/changeNumberOfCoversReducer";
import { changeDeductibleTypeReducer } from "./reducers/changeDeductibleTypeReducer";
import { changeDeductiblePercentageReducer } from "./reducers/changeDeductiblePercentageReducer";
import { changePricingMethodReducer } from "./reducers/changePricingMethodReducer";
import { changeweatherTriggerReducer } from "./reducers/changeweatherTriggerReducer";
import { changeweatherExitReducer } from "./reducers/changeweatherExitReducer";
import { changecoverMaxPayoutReducer } from "./reducers/changecoverMaxPayoutReducer";
import { changeTargetLossRatioValueReducer } from "./reducers/changeTargetLossRatioValueReducer";
import { changeTargetLossRatioselectionReducer } from "./reducers/changeTargetLossRatioselectionReducer";
import { changecommercialpremiumRateTypeReducer } from "./reducers/changecommercialpremiumRateTypeReducer";
import { changeIULevel4Reducer } from "./reducers/changeIULevel4Reducer";
import { changeIULevel1Reducer } from "./reducers/changeIULevel1Reducer";
import { changeIULevel2Reducer } from "./reducers/changeIULevel2Reducer";
import { changeIULevel3Reducer } from "./reducers/changeIULevel3Reducer";
import { changeIUListReducer } from "./reducers/changeIUListReducer";
import { changeMethodofThresholdReducer } from "./reducers/changeMethodofThresholdReducer";
import { changecoverMinPayoutReducer } from "./reducers/changecoverMinPayoutReducer";
import { changeblockSizeReducer } from "./reducers/changeblockSizeReducer";
import { changewithinBlockCalcReducer } from "./reducers/changewithinBlockCalcReducer";
import { changeblockCountCalcReducer } from "./reducers/changeblockCountCalcReducer";
import { changeblockCountTriggerReducer } from "./reducers/changeblockCountTriggerReducer";
import { changeblockCountExitReducer } from "./reducers/changeblockCountExitReducer";
import { changeriskIntervalReducer } from "./reducers/changeriskIntervalReducer";
import { changeinsurerMarginReducer } from "./reducers/changeinsurerMarginReducer";
import { changeproductDevelopmentFeeReducer } from "./reducers/changeproductDevelopmentFeeReducer";
import { changebrokerFeeReducer } from "./reducers/changebrokerFeeReducer";
import { changewithholdingTaxReducer } from "./reducers/changewithholdingTaxReducer";
import { changePremiumLevyReducer } from "./reducers/changePremiumLevyReducer";
import { changeAllcoverDetailsReducer } from "./reducers/changeAllcoverDetailsReducer";
import { changeInsurerReducer } from "./reducers/changeInsurerReducer";
import { changeValueChainReducer } from "./reducers/changeValueChainReducer";
import { changeAggregatorReducer } from "./reducers/changeAggregatorReducer";
import { changeCurrencyReducer } from "./reducers/changeCurrencyReducer";
import { changeSumInsuredReducer } from "./reducers/changeSumInsuredReducer";
import { changemethodofSelectionReducer } from "./reducers/changemethodofSelectionReducer";
import { changeStatesReducer } from "./reducers/changeStatesReducer";
import { changeSelectedStatesReducer } from "./reducers/changeSelectedStatesReducer";
import { changeDistrictReducer } from "./reducers/changeDistrictsReducer";
import { changeMaxPremiumReducer } from "./reducers/changeMaxPremiumReducer";
import { changeMinPremiumReducer } from "./reducers/changeMinPremiumReducer";
import { changeMeanPremiumReducer } from "./reducers/changeMeanPremiumReducer";
import { changeCommercialPremiumReducer } from "./reducers/changeCommercialPremiumReducer";
import { changeCommercialMaxPremiumReducer } from "./reducers/changeCommercialMaxPremiumReducer";
import { changeTotalIUReducer } from "./reducers/changeTotalIUReducer";
import { changeCommercialMinPremiumReducer } from "./reducers/changeCommercialMinPremiumReducer";
import { changeIUAnalysisPremiumReducer } from "./reducers/changeIUAnalysisPremiumReducer";
import { changeIUAnalysisCommercialReducer } from "./reducers/changeIUAnalysisCommercialReducer";
import { changeNewIUListReducer } from "./reducers/changeNewIUListReducer";
import { changeSelectedIUReducer } from "./reducers/changeSelectedIUReducer";
import { changeSavedPolicyDetailsReducer } from "./reducers/changeSavedPolicyDetailsReducer";
import { changeEditPolicyReducer } from "./reducers/changeEditPolicyReducer";
import { changeIUListReducer2 } from "./reducers/changeIUListReducer2";
import { changeIUListDownloadReducer } from "./reducers/changeIUListDownloadReducer";
import { changeEditingStateReducer } from "./reducers/changeEditingStateReducer";


const rootReducer = combineReducers({
  stateName: changeStateNameReducer,
  districtName: changeDistrictNameReducer,
  countryName :changeCountryNameReducer,
  level1Data :changeLevel1NameReducer,
  level2Data :changeLevel2NameReducer,
  level3Data :changeLevel3NameReducer,
  level4Data :changeLevel4NameReducer,
  indexName :changeIndexNameReducer,
  landcoverswitch :changeLandcoverswitchReducer,
  ndvimodisswitch :changeNdvimodisswitchReducer,
  zoom:changeZoomReducer,
  getStartYears:changegetStartYearsReducer,
  getEndYears:changegetEndYearsReducer,
  getStartMonth:changegetStartMonthReducer,
  getEndMonth:changegetEndMonthReducer,
  modisNdviLayer: changeModisNDVILayerReducer,
  sentinel2NdviLayer: changeSentinel2NDVILayerReducer,
  landcoverLayer: changeLandcoverLayerReducer,
  getWeatherVariable:changeWeathervariableReducer,
  frequency : changefrequencyReducer,
  combination : changeCombinationReducer,
  TempDataset:changeTempDatasetReducer,
  RainfallDataset:changeRainfallDatasetReducer,
  VegetationDataset :changeVegetationDatasetReducer,
  compareState : changeCompareStateReducer,
  pixelID : changePixelIDReducer,
  pixelName : changePixelNameReducer,
  pixel_id : changePixel_idReducer,
  layerName: changeLayerNameReducer,
  searchLocation:changegetlocationbysearchReducer,
  showModisNDVIDot : changeshowModisNDVIDotReducer,
  showSentinel2NDVIDot : changeshowSentinel2NDVIDotReducer,
  showManual: changeshowManualReducer,
  step: changeStepReducer,
  showuploadsite:changeshowuploadsiteReducer,
  acrossBlockCalc : changeacrossBlockCalcReducer,
  coverName: changecoverNameReducer,
  riskCovered: changeriskCoveredReducer,
  datasets: changedatasetsReducer,
  coverstartDate: changecoverstartDateReducer,
  coverendDate:changecoverendDateReducer,
  weightage:changeweightageReducer,
  coverPeriodType:changecoverPeriodTypeReducer,
  phaseCalculation:changephaseCalculationReducer,
  typeOfProduct:changeTypeOfProductReducer,
  productName:changeProductNameReducer,
  numberOfCovers:changeNumberOfCoversReducer,
  deductibleType:changeDeductibleTypeReducer,
  deductiblePercentage:changeDeductiblePercentageReducer,
  pricingMethod:changePricingMethodReducer,
  weatherTrigger:changeweatherTriggerReducer,
  weatherExit:changeweatherExitReducer,
  coverMaxPayout:changecoverMaxPayoutReducer,
  targetLossRatioValue:changeTargetLossRatioValueReducer,
  targetLossRatioselection:changeTargetLossRatioselectionReducer,
  commercialpremiumRateType: changecommercialpremiumRateTypeReducer,
  IULevel4: changeIULevel4Reducer,
  IULevel1: changeIULevel1Reducer,
  IULevel2: changeIULevel2Reducer,
  IULevel3: changeIULevel3Reducer,
  IUList:changeIUListReducer,
  MethodofThreshold:changeMethodofThresholdReducer,
  coverMinPayout:changecoverMinPayoutReducer,
  blockSize:changeblockSizeReducer,
  riskInterval: changeriskIntervalReducer,
  withinBlockCalc: changewithinBlockCalcReducer,
  blockCountCalc: changeblockCountCalcReducer,
  blockCountTrigger: changeblockCountTriggerReducer,
  blockCountExit: changeblockCountExitReducer,
  insurerMargin : changeinsurerMarginReducer,
  productDevelopmentFee: changeproductDevelopmentFeeReducer,
  brokerFee:changebrokerFeeReducer,
  withholdingTax:changewithholdingTaxReducer,
  PremiumLevy:changePremiumLevyReducer,
  coverDetails: changeAllcoverDetailsReducer,
  insurer:changeInsurerReducer,
  valueChain:changeValueChainReducer,
  aggregator:changeAggregatorReducer,
  currency:changeCurrencyReducer,
  sumInsured:changeSumInsuredReducer,
  methodofSelection:changemethodofSelectionReducer,
  states:changeStatesReducer,
  selectedStates:changeSelectedStatesReducer,
  districts:changeDistrictReducer,
  selectedDistricts:changeSelectedDistrictReducer,
  MaxPremium:changeMaxPremiumReducer,
  MinPremium:changeMinPremiumReducer,
  MeanPremium: changeMeanPremiumReducer,
  CommercialPremium :changeCommercialPremiumReducer,
  CommercialMaxPremium:changeCommercialMaxPremiumReducer,
  CommercialMinPremium:changeCommercialMinPremiumReducer,
  TotalIU: changeTotalIUReducer,
  IUAnalysisPremium:changeIUAnalysisPremiumReducer,
  IUAnalysisCommercial:changeIUAnalysisCommercialReducer,
  NewIUList:changeNewIUListReducer,
  SelectedIU:changeSelectedIUReducer,
  policyDetails:changeSavedPolicyDetailsReducer,
  EditPolicy:changeEditPolicyReducer,
  IUList2:changeIUListReducer2,
  IUListDownload:changeIUListDownloadReducer,
  EditingState:changeEditingStateReducer
});

export default rootReducer;
