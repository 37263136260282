import React from 'react';
import { makeStyles } from '@mui/styles';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Paper } from '@mui/material';

const useStyles = makeStyles({
  yellowBackground: {
    background: '#E6EFF5',
  },
});

const LatencyTable = ({ data }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.yellowBackground}  style={{borderRight: '1px solid #dddddd'}}>Dataset Name</TableCell>
            <TableCell className={classes.yellowBackground} style={{borderRight: '1px solid #dddddd'}}>Latency (in days)</TableCell>
            <TableCell className={classes.yellowBackground} >Length (in days)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((entry, index) => (
            <TableRow key={index}>
              <TableCell  style={{borderRight: '1px solid #dddddd'}}>{entry[0]}</TableCell>
              <TableCell style={{borderRight: '1px solid #dddddd'}}>{entry[1]}</TableCell>
              <TableCell >{entry[2]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LatencyTable;
