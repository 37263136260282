import React, { useState} from 'react';
import '../../login.css'; 
import { Loginmain } from './Loginmain';
import { Passwordreset } from './Passwordreset';
import { Forgetpass } from './Forgetpass';
import { NewPassword } from './NewPassword';
import { Success } from './Success';


const Login = (props) => {
  const [loginscreen, setLoginscreen] = useState(true);
  const [ForgetPasswordComp, setForgetPasswordComp] = useState(false);
  const [resetPasswordComp, setResetPasswordComp] = useState(false);
  const [newPasswordComp, setNewPasswordComp] = useState(false);
  const [successComp, setsuccessComp] = useState(false);

  return (
    <>
      <div className="login-container">
        <div className="login-left">
          <img src="loginbackground.png" alt="Login" />
        </div>
        {loginscreen && !ForgetPasswordComp && !resetPasswordComp && !newPasswordComp  && (
          <Loginmain 
          email={props.email}
          setEmail={props.setEmail}
          ForgetPasswordComp={ForgetPasswordComp} setForgetPasswordComp={setForgetPasswordComp} 
          loginscreen={loginscreen}
          setLoginscreen={setLoginscreen}
          mainscreen={props.mainscreen}
          setMainscreen={props.setMainscreen}
          />
        )}
        {ForgetPasswordComp && !resetPasswordComp && !newPasswordComp && (
         <Forgetpass
         email={props.email}
         setEmail={props.setEmail}
         ForgetPasswordComp={ForgetPasswordComp} 
         setForgetPasswordComp={setForgetPasswordComp}
         resetPasswordComp={resetPasswordComp}
         setResetPasswordComp={setResetPasswordComp}
         loginscreen={loginscreen}
         setLoginscreen={setLoginscreen}
         setNewPasswordComp={setNewPasswordComp}
         newPasswordComp={newPasswordComp}/>
        )}
        {resetPasswordComp && !newPasswordComp && (
        <Passwordreset
        email={props.email}
        setEmail={props.setEmail}
        ForgetPasswordComp={ForgetPasswordComp} 
        setForgetPasswordComp={setForgetPasswordComp}
        resetPasswordComp={resetPasswordComp}
        setResetPasswordComp={setResetPasswordComp}
        loginscreen={loginscreen}
        setLoginscreen={setLoginscreen}
        setNewPasswordComp={setNewPasswordComp}
        newPasswordComp={newPasswordComp}/>
        )}
        {newPasswordComp && !successComp && (
          <NewPassword
          successComp={successComp}
          setsuccessComp={setsuccessComp}/>
        )}
         {successComp && (
          <Success
          loginscreen={loginscreen}
          setLoginscreen={setLoginscreen}/>
        )}
      </div>
    </>
  );
};

export default Login;
