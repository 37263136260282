import React, { useState, useEffect } from "react";
import "../../styles.css";
// import { Auth } from "aws-amplify";
import Datasetsfordownload from "./Datasetsfordownload";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FaCaretDown } from "react-icons/fa";
import Timeperiodselect from "./Timeperiodselect";
import { IoIosMenu } from "react-icons/io";
import DefinitionModal from "./Definitionmodal";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { changeIUListdownload } from "../../redux/action";

const countrynames = ["Tanzania", "Zambia", "Sudan", "Bangladesh", "Kenya"];

const Download = () => {
  const [selectAllStates, setSelectAllStates] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectAllDistrict, setselectAllDistrict] = useState(false);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [stateDisabled, setStateDisabled] = useState(true);
  const [countrySearch, setCountrySearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [stateSearch, setStateSearch] = useState("");
  const [districtSearch, setDistrictSearch] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [selectedDatasets, setSelectedDatasets] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [level2_values, setlevel2_values] = useState({});
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 10);
  const [loading, setLoading] = useState(false);
  const [level4, setLevel4] = useState([]);
  const [selectedLevel4, setSelectedLevel4] = useState([]);
  const [selectAllLevel4, setSelectAllLevel4] = useState(false);
  const [level5, setLevel5] = useState([]);
  const [selectedLevel5, setSelectedLevel5] = useState([]);
  const [selectAllLevel5, setSelectAllLevel5] = useState(false);
  const IUListDownload = useSelector((state) => state.IUListDownload);
  const dispatch = useDispatch();

  useEffect(() => {
    async function genToken() {
      // const {
      //   accessToken: { jwtToken },
      // } = await Auth.currentSession();
      const newLevel2Values = {};
      selectedStates.forEach((state) => {
        newLevel2Values[state] = selectedDistricts.filter((district) =>
          districts[state]?.includes(district)
        );
      });
      setlevel2_values(newLevel2Values);
      let levelName = "";
      if (selectedCountry === "Tanzania") {
        levelName = "state";
      } else if (selectedCountry === "Zambia") {
        levelName = "province";
      } else if (selectedCountry === "Bangladesh") {
        levelName = "division";
      } else if (selectedCountry === "Sudan") {
        levelName = "state";
      } else if (selectedCountry === "Kenya") {
        levelName = "county";
      }

      if (selectedCountry != null) {
        fetch(
          `/api/explore/selectLocation?country=${selectedCountry}&level=${levelName}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setStates(data);
            setStateDisabled(false);

            if (
              selectedStates != null &&
              (selectedCountry === "Tanzania" ||
                selectedCountry === "Zambia" ||
                selectedCountry === "Bangladesh" ||
                selectedCountry === "Kenya" ||
                selectedCountry == "Sudan")
            ) {
              let level2Name = " ";
              if (
                selectedCountry === "Tanzania" ||
                selectedCountry === "Zambia" ||
                selectedCountry == "Sudan"
              ) {
                level2Name = "district";
              } else if (selectedCountry === "Bangladesh") {
                level2Name = "zila";
              } else if (selectedCountry === "Kenya") {
                level2Name = "constituency";
              }
              fetch(`/api/downloads/getMultipleLevel2Values`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
                body: JSON.stringify({
                  country: selectedCountry,
                  level1: selectedStates,
                }),
              })
                .then((response) => response.json())
                .then((data) => {
                  setDistricts(data);
                });
              if (
                selectedDistricts !== null &&
                (selectedCountry === "Tanzania" ||
                  selectedCountry === "Zambia" ||
                  selectedCountry === "Bangladesh" ||
                  selectedCountry === "Kenya" ||
                  selectedCountry === "Sudan")
              ) {
                let level3Name = "";
                if (
                  selectedCountry === "Tanzania" ||
                  selectedCountry === "Kenya" ||
                  selectedCountry === "Sudan"
                ) {
                  level3Name = "ward";
                } else if (selectedCountry === "Zambia") {
                  level3Name = "block";
                } else if (selectedCountry === "Bangladesh") {
                  level3Name = "upazila";
                }
                fetch(`/api/downloads/getMultipleLevel3Values`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // Authorization: jwtToken,
                  },
                  body: JSON.stringify({
                    country: selectedCountry,
                    level1: selectedStates,
                    level2: selectedDistricts,
                  }),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    setLevel4(data);
                    if (
                      selectedLevel4 != null &&
                      (selectedCountry === "Zambia" ||
                        selectedCountry === "Bangladesh")
                    ) {
                      let level4Name = "";
                      if (selectedCountry === "Zambia") {
                        level4Name = "camp";
                      } else if (selectedCountry === "Bangladesh") {
                        level4Name = "union";
                      }
                      fetch(`/api/downloads/getMultipleLevel4Values`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          // Authorization: jwtToken,
                        },
                        body: JSON.stringify({
                          country: selectedCountry,
                          level1: selectedStates,
                          level2: selectedDistricts,
                          level3: selectedLevel4,
                        }),
                      })
                        .then((response) => response.json())
                        .then((data) => {
                          setLevel5(data);
                        });
                    }
                  });
              }
            }
          });
      }

      // });
    }
    genToken();
  }, [
    selectedCountry,
    selectedLevel4,
    selectedLevel5,
    selectedDistricts,
    selectedStates,
  ]);
  useEffect(() => {
    let list = [];
    if (selectedLevel5.length > 0) {
      list = selectedLevel5.map((level5Option) => {
        const level4Option = Object.keys(level5).find((key) =>
          level5[key]?.includes(level5Option)
        );
        const district = Object.keys(level4).find((key) =>
          level4[key]?.includes(level4Option)
        );
        const state = Object.keys(districts).find((key) =>
          districts[key]?.includes(district)
        );
        return {
          level1: state,
          level2: district,
          level3: level4Option,
          level4: level5Option,
        };
      });
    } else if (selectedLevel4.length > 0) {
      list = selectedLevel4.map((level4Option) => {
        const district = Object.keys(level4).find((key) =>
          level4[key]?.includes(level4Option)
        );
        const state = Object.keys(districts).find((key) =>
          districts[key]?.includes(district)
        );

        return {
          level1: state,
          level2: district,
          level3: level4Option,
        };
      });
    } else if (selectedDistricts.length > 0) {
      list = selectedDistricts.map((district) => {
        const state = Object.keys(districts).find((key) =>
          districts[key]?.includes(district)
        );

        return {
          level1: state,
          level2: district,
        };
      });
    } else if (selectedStates.length > 0) {
      list = selectedStates.map((state) => ({
        level1: state,
      }));
    }
    dispatch(changeIUListdownload(list));
  }, [
    selectedCountry,
    selectedLevel4,
    selectedLevel5,
    selectedDistricts,
    selectedStates,
  ]);

  const updateSelectedDatasets = () => {
    const datasetMapping = {
      "Chirps 5km Daily Rainfall": "Chirps Daily Rainfall",
      "Chirps 5km Monthly Rainfall": "Chirps Monthly Rainfall",
      "ARC2 10km Daily Rainfall": "Arc2 Daily Rainfall",
      "TAMSAT Daily Rainfall": "Tamsat Daily Rainfall",
      "TAMSAT Monthly Rainfall": "Tamsat Monthly Rainfall",
      "MODIS 10 Day Interval NDVI": "Modis Eviirs Ndvi",
      "ECMWF/ERA5 Daily Rainfall": "Era5 Daily Rainfall",
      "ECMWF/ERA5 Monthly Rainfall": "Era5 Monthly Rainfall",
      "ECMWF/ERA5 Daily Temperature": "Era5 Daily Temperature",
      "ECMWF/ERA5 Monthly Temperature": "Era5 Monthly Temperature",
      "GSMAP Daily Rainfall": "Gsmap Daily Rainfall",
      "GSMAP Monthly Rainfall": "Gsmap Monthly Rainfall",
      "MODIS 16 Day Interval NDVI": "Modis Eviirs Ndvi",
      "SENTINEL-2 5 Day interval NDVI": "Sentinel2 Ndvi",
      "Grid Chirps Daily Rainfall": "Grid Chirps Daily Rainfall",
    };

    const updatedDatasets = selectedDatasets.map((dataset) => {
      if (datasetMapping.hasOwnProperty(dataset)) {
        return datasetMapping[dataset];
      } else {
        return dataset;
      }
    });
    return updatedDatasets;
  };
  const updatedDatasets = updateSelectedDatasets();
  const handleDatasetSelection = (selected) => {
    setSelectedDatasets(selected);
  };
  const handleCountrySearch = (e) => {
    setCountrySearch(e.target.value);
  };
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setSelectedStates([]);
    setSelectedDistricts([]);
    setSelectedLevel4([]);
    setSelectedLevel5([]);
  };

  const handleStateSearch = (e) => {
    setStateSearch(e.target.value);
  };
  const handleStateCheckboxChange = (state) => {
    const currentIndex = selectedStates.indexOf(state);
    const newSelectedStates = [...selectedStates];

    if (currentIndex === -1) {
      if (newSelectedStates.length >= 1) {
        toast.error(
          <div>
            <div
              style={{
                fontSize: "14px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Maximum State Selected
            </div>

            <div
              style={{ marginTop: "5px", fontSize: "12px", fontWeight: 400 }}
            >
              Up to 1 state can be displayed for any selected location
            </div>
          </div>
        );
        return;
      }
      newSelectedStates.push(state);
    } else {
      newSelectedStates.splice(currentIndex, 1);
    }

    setSelectedStates(newSelectedStates);
  };

  const handleDistrictSearch = (e) => {
    setDistrictSearch(e.target.value);
  };
  const handleDistrictCheckboxChange = (district) => {
    const currentIndex = selectedDistricts.indexOf(district);
    const newSelectedDistricts = [...selectedDistricts];

    if (currentIndex === -1) {
      if (newSelectedDistricts.length >= 1) {
        toast.error(
          <div>
            <div
              style={{
                fontSize: "14px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Maximum District Selected
            </div>

            <div
              style={{ marginTop: "5px", fontSize: "12px", fontWeight: 400 }}
            >
              Up to 1 District can be displayed for any selected location
            </div>
          </div>
        );
        return;
      }
      newSelectedDistricts.push(district);
    } else {
      newSelectedDistricts.splice(currentIndex, 1);
    }

    setSelectedDistricts(newSelectedDistricts);
  };

  const filteredCountries = countrynames.filter((country) =>
    country.toLowerCase().includes(countrySearch.toLowerCase())
  );
  const filteredStates = states.filter((state) =>
    state.toLowerCase().includes(stateSearch.toLowerCase())
  );

  const handleSelectAll = () => {
    toast.error(
      <div>
        <div
          style={{ fontSize: "14px", marginBottom: "5px", fontWeight: "bold" }}
        >
          Maximum States Selected
        </div>

        <div style={{ marginTop: "5px", fontSize: "12px", fontWeight: 400 }}>
          You can not select all states for selected location
        </div>
      </div>
    );
    return;
    // setSelectAllStates(!selectAllStates);
    // if (!selectAllStates) {
    //   setSelectedStates(states);
    // } else {
    //   setSelectedStates([]);
    // }
  };
  const handleSelectAllDistrict = () => {
    toast.error(
      <div>
        <div
          style={{ fontSize: "14px", marginBottom: "5px", fontWeight: "bold" }}
        >
          Maximum District Selected
        </div>

        <div style={{ marginTop: "5px", fontSize: "12px", fontWeight: 400 }}>
          Up to 1 District can be displayed for any selected location
        </div>
      </div>
    );
    return;
    // setselectAllDistrict(!selectAllDistrict);

    // if (!selectAllDistrict) {
    //   // If checkbox is checked, select all districts
    //   const allDistricts = selectedStates.reduce((acc, state) => {
    //     if (districts[state]) {
    //       acc.push(...districts[state]);
    //     }
    //     return acc;
    //   }, []);
    //   setSelectedDistricts(allDistricts);
    // } else {
    //   // If checkbox is unchecked, clear all selected districts
    //   setSelectedDistricts([]);
    // }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSelectAllFromMenu = () => {
    setSelectedStates(states);
    setselectAllDistrict(districts);
    setSelectAllStates(true);
    setselectAllDistrict(true);
    handleMenuClose();
  };

  const handleLevel4CheckboxChange = (level4Option) => {
    const newSelectedLevel4 = selectedLevel4.includes(level4Option)
      ? selectedLevel4.filter((l) => l !== level4Option)
      : [...selectedLevel4, level4Option];
    setSelectedLevel4(newSelectedLevel4);
  };

  const handleLevel5CheckboxChange = (level5Option) => {
    const newSelectedLevel5 = selectedLevel5.includes(level5Option)
      ? selectedLevel5.filter((l) => l !== level5Option)
      : [...selectedLevel5, level5Option];
    setSelectedLevel5(newSelectedLevel5);
  };

  const handleSelectAllLevel4 = () => {
    if (!selectAllLevel4) {
      const allLevel4 = selectedDistricts.reduce(
        (acc, district) => acc.concat(level4[district] || []),
        []
      );
      setSelectedLevel4(allLevel4);
    } else {
      setSelectedLevel4([]);
    }
    setSelectAllLevel4(!selectAllLevel4);
  };

  const handleSelectAllLevel5 = () => {
    if (!selectAllLevel5) {
      const allLevel5 = selectedLevel4.reduce(
        (acc, level4) => acc.concat(level5[level4] || []),
        []
      );
      setSelectedLevel5(allLevel5);
    } else {
      setSelectedLevel5([]);
    }
    setSelectAllLevel5(!selectAllLevel5);
  };

  const handleClearAllFromMenu = () => {
    setSelectedStates([]); // Clear all selected states
    setSelectAllStates(false); // Update checkbox state
    handleMenuClose();
  };
  //  const handleOutputTypeChange = (selectedOption) => {
  //         setOutputType(selectedOption.value);
  //     };

  //     const handleFileTypeChange = (selectedOption) => {
  //         setFileType(selectedOption.value);
  //     };

  //     const handleThousandSeparatorChange = (selectedOption) => {
  //         setThousandSeparator(selectedOption.value);
  //     };

  //     const handleOutputFormatChange = (selectedOption) => {
  //       setOutputFormat(selectedOption.value);
  //   };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid blue" : "1px solid black",
      boxShadow: state.isFocused ? "0 0 0 1px blue" : "none",
    }),
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleDataDownload = () => {
    async function genToken() {
      setLoading(true);

      fetch(`/api/downloads/datasetDownload`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          country: selectedCountry,
          file_type: "csv",
          iu_list: IUListDownload,
          datasets: updatedDatasets,
          months: selectedMonths,
          years: selectedYears.map((element) => element.toString()),
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          if (
            !contentDisposition ||
            !contentDisposition.includes("attachment")
          ) {
            throw new Error("Invalid or missing Content-Disposition header");
          }

          const filename = `CDL_${selectedCountry}_${formattedDate}.zip`;

          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          const blob = new Blob([data], { type: "application/zip" });
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();

          window.URL.revokeObjectURL(a.href);

          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
        });
    }

    genToken();
  };

  const isDisabled = !(
    selectedCountry &&
    selectedDatasets.length > 0 &&
    selectedYears.length > 0
  );
  return (
    <div
      style={{
        background: "#F2F2F0",
        display: "flex",
        flexDirection: "row",
        overflowY: "scroll",
      }}
    >
      <div
        className="pannel2"
        style={{ display: "flex", flexDirection: "column", width: "80%" }}
      >
        <div className="download-pannel">
          <div
            style={{
              textAlign: "left",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "28px",
              letterSpacing: "-0.04px",
            }}
          >
            Export Data
            <div
              style={{
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "25px",
                color: "rgba(7, 6, 0, 0.58)",
              }}
            >
              Select required location, dataset, and time period and export
              data.
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <button
              onClick={handleShowModal}
              style={{
                display: "flex",
                fontFamily: "Inter-Medium, Helvetica",
                alignItems: "center",
                backgroundColor: "#EDF4F8)",
                color: "#1C2024",
                border: "1px solid rgba(26, 26, 0, 0.16)",
                borderRadius: "5px",
                padding: "8px 8px",
                cursor: "pointer",
                fontWeight: 500,
              }}
            >
              <IoIosMenu
                style={{ marginRight: "10px", height: "20px", width: "23px" }}
              />
              Definitions and Standards
            </button>
          </div>
        </div>

        <div className="download-pannel2">
          <div
            className="card"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              className="title"
              style={{ display: "flex", flexDirection: "row", padding: 10 }}
            >
              <img
                src="world.png"
                alt="location icon"
                style={{ marginRight: "18px" }}
              />
              Administrative Boundaries
            </div>
            <div
              className="search-row"
              style={{
                marginBottom: "10px",
                display: "flex",
                padding: "10px",
                justifyContent: "space-between",
                width: "60%",
              }}
            >
              <div className="searchbar1" style={{ width: "22%" }}>
                <div style={{ position: "relative", marginRight: "10px" }}>
                  <IoSearchOutline
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#777",
                    }}
                  />
                  <input
                    type="text"
                    value={countrySearch}
                    onChange={handleCountrySearch}
                    placeholder="Select Country"
                    style={{
                      paddingLeft: "30px",
                      width: "240px",
                      height: "36px",
                      fontSize: "14px",
                      borderRadius: "8px",
                      border: "1px solid rgba(26, 26, 0, 0.16)",
                    }}
                  />
                </div>

                <div
                  className="list"
                  style={{ textAlign: "left", padding: "10px", width: "231px" }}
                >
                  {filteredCountries.map((country, index) => (
                    <div
                      key={index}
                      className="list-item"
                      onClick={() => handleCountrySelect(country)}
                      style={{
                        padding: "8px",
                        backgroundColor:
                          selectedCountry === country
                            ? "rgba(255, 108, 3, 0.09)"
                            : "transparent",
                        cursor: "pointer",
                      }}
                    >
                      {country}
                    </div>
                  ))}
                </div>
              </div>

              <div className="searchbar2" style={{ width: "50%" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ position: "relative", marginRight: "4%" }}>
                    <IoSearchOutline
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#777",
                      }}
                    />
                    <input
                      type="text"
                      value={stateSearch}
                      onChange={handleStateSearch}
                      placeholder="Select State"
                      style={{
                        paddingLeft: "30px",
                        width: "160px",
                        height: "36px",
                        fontSize: "14px",
                        borderRadius: "8px",
                        border: "1px solid rgba(26, 26, 0, 0.16)",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      border: "1px solid rgba(26, 26, 0, 0.16)",
                      gap: "4px",
                      borderRadius: "8px",
                      height: "36px",
                      width: "60px",
                      padding: "8px",
                      display: "flex",
                      alignContent: "end",
                    }}
                  >
                    <IconButton style={{ width: "20px", height: "20px" }}>
                      <Checkbox
                        checked={selectAllStates}
                        onClick={handleSelectAll}
                        sx={{
                          color: "rgba(26, 26, 0, 0.16)",
                          "&.Mui-checked": {
                            color: "#F76808",
                          },
                        }}
                      />
                    </IconButton>
                    <IconButton
                      style={{ width: "20px", height: "20px", padding: 0 }}
                      onClick={handleMenuOpen}
                    >
                      <FaCaretDown />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleSelectAllFromMenu}>
                        Select All
                      </MenuItem>
                      <MenuItem onClick={handleClearAllFromMenu}>
                        Clear All
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
                <div
                  className="list"
                  style={{ textAlign: "left", padding: "10px", width: "231px" }}
                >
                  {filteredStates.map((state, index) => (
                    <div
                      key={index}
                      className="list-item"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "5px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id={state}
                        checked={selectedStates.includes(state)}
                        onChange={() => handleStateCheckboxChange(state)}
                        style={{ marginRight: "5px" }}
                      />
                      <label
                        htmlFor={state}
                        style={{
                          cursor: "pointer",
                          color: "black",
                          paddingLeft: "5px",
                        }}
                      >
                        {state}
                      </label>
                    </div>
                  ))}
                </div>
                <ToastContainer
                  position="bottom-center"
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  style={{
                    width: "400px",
                    padding: "16px",
                    textAlign: "initial",
                    color: "#21201C",
                  }}
                />
              </div>
            </div>
            <div
              className="search-row"
              style={{
                marginBottom: "10px",
                display: "flex",
                padding: "10px",
                justifyContent: "space-between",
                width:
                  selectedCountry === "Bangladesh" ||
                  selectedCountry === "Zambia"
                    ? "70%"
                    : "35%",
              }}
            >
              <div className="searchbar3" style={{ width: "15%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ position: "relative", marginRight: "4%" }}>
                    <IoSearchOutline
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#777",
                      }}
                    />
                    <input
                      type="text"
                      value={districtSearch}
                      onChange={handleDistrictSearch}
                      placeholder="Select District"
                      style={{
                        paddingLeft: "30px",
                        width: "160px",
                        height: "36px",
                        fontSize: "14px",
                        borderRadius: "8px",
                        border: "1px solid rgba(26, 26, 0, 0.16)",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      border: "1px solid rgba(26, 26, 0, 0.16)",
                      gap: "4px",
                      borderRadius: "8px",
                      height: "36px",
                      width: "60px",
                      padding: "8px",
                      display: "flex",
                    }}
                  >
                    <IconButton
                      style={{ width: "20px", height: "20px" }}
                      onClick={handleSelectAllDistrict}
                    >
                      <Checkbox
                        checked={selectAllDistrict}
                        disabled={true}
                        onClick={handleSelectAllDistrict}
                        sx={{
                          color: "rgba(26, 26, 0, 0.16)",
                          "&.Mui-checked": {
                            color: "#F76808",
                          },
                        }}
                      />
                    </IconButton>
                    <IconButton
                      style={{ width: "20px", height: "20px", padding: 0 }}
                      onClick={handleMenuOpen}
                    >
                      <FaCaretDown />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleSelectAllDistrict}>
                        Select All
                      </MenuItem>
                      <MenuItem onClick={handleClearAllFromMenu}>
                        Clear All
                      </MenuItem>
                    </Menu>
                  </div>
                </div>

                <div
                  className="list"
                  style={{ textAlign: "left", padding: "10px", width: "231px" }}
                >
                  {selectedStates.map((state) => (
                    <div key={state} style={{ alignItems: "center" }}>
                      {state}
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        {state in districts &&
                          districts[state].map((district, index) => (
                            <li key={index} className="list-item">
                              <input
                                type="checkbox"
                                id={district}
                                checked={selectedDistricts.includes(district)}
                                onChange={() =>
                                  handleDistrictCheckboxChange(district)
                                }
                                style={{
                                  marginRight: "10px",
                                  marginBottom: "15px",
                                }}
                              />
                              <label
                                style={{
                                  cursor: "pointer",
                                  color: "black",
                                  paddingLeft: "5px",
                                  fontSize: "14px",
                                }}
                                htmlFor={district}
                              >
                                {district}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
              <div className="searchbar4" style={{ width: "15%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ position: "relative", marginRight: "4%" }}>
                    <IoSearchOutline
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#777",
                      }}
                    />
                    <input
                      type="text"
                      value={districtSearch}
                      onChange={handleDistrictSearch}
                      placeholder="Select Level 4"
                      style={{
                        paddingLeft: "30px",
                        width: "160px",
                        height: "36px",
                        fontSize: "14px",
                        borderRadius: "8px",
                        border: "1px solid rgba(26, 26, 0, 0.16)",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      border: "1px solid rgba(26, 26, 0, 0.16)",
                      gap: "4px",
                      borderRadius: "8px",
                      height: "36px",
                      width: "60px",
                      padding: "8px",
                      display: "flex",
                    }}
                  >
                    <IconButton
                      style={{ width: "20px", height: "20px" }}
                      onClick={handleSelectAllLevel4}
                    >
                      <Checkbox
                        checked={selectAllLevel4}
                        onClick={handleSelectAllLevel4}
                        sx={{
                          color: "rgba(26, 26, 0, 0.16)",
                          "&.Mui-checked": {
                            color: "#F76808",
                          },
                        }}
                      />
                    </IconButton>
                    <IconButton
                      style={{ width: "20px", height: "20px", padding: 0 }}
                      onClick={handleMenuOpen}
                    >
                      <FaCaretDown />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleSelectAllLevel4}>
                        Select All
                      </MenuItem>
                      <MenuItem onClick={handleClearAllFromMenu}>
                        Clear All
                      </MenuItem>
                    </Menu>
                  </div>
                </div>

                <div
                  className="list"
                  style={{ textAlign: "left", padding: "10px", width: "231px" }}
                >
                  {selectedDistricts.map((district) => (
                    <div key={district} style={{ alignItems: "center" }}>
                      {district}
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        {district in level4 &&
                          level4[district].map((level4Option, index) => (
                            <li key={index} className="list-item">
                              <input
                                type="checkbox"
                                id={level4Option}
                                checked={selectedLevel4.includes(level4Option)}
                                onChange={() =>
                                  handleLevel4CheckboxChange(level4Option)
                                }
                                style={{
                                  marginRight: "10px",
                                  marginBottom: "15px",
                                }}
                              />
                              <label
                                style={{
                                  cursor: "pointer",
                                  color: "black",
                                  paddingLeft: "5px",
                                  fontSize: "14px",
                                }}
                                htmlFor={level4Option}
                              >
                                {level4Option}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
              {selectedCountry == "Zambia" ||
              selectedCountry == "Bangladesh" ? (
                <div className="searchbar5" style={{ width: "15%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ position: "relative", marginRight: "4%" }}>
                      <IoSearchOutline
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "#777",
                        }}
                      />
                      <input
                        type="text"
                        value={districtSearch}
                        onChange={handleDistrictSearch}
                        placeholder="Select Level 5"
                        style={{
                          paddingLeft: "30px",
                          width: "160px",
                          height: "36px",
                          fontSize: "14px",
                          borderRadius: "8px",
                          border: "1px solid rgba(26, 26, 0, 0.16)",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        border: "1px solid rgba(26, 26, 0, 0.16)",
                        gap: "4px",
                        borderRadius: "8px",
                        height: "36px",
                        width: "60px",
                        padding: "8px",
                        display: "flex",
                      }}
                    >
                      <IconButton
                        style={{ width: "20px", height: "20px" }}
                        onClick={handleSelectAllLevel5}
                      >
                        <Checkbox
                          checked={selectAllLevel5}
                          onClick={handleSelectAllLevel5}
                          sx={{
                            color: "rgba(26, 26, 0, 0.16)",
                            "&.Mui-checked": {
                              color: "#F76808",
                            },
                          }}
                        />
                      </IconButton>
                      <IconButton
                        style={{ width: "20px", height: "20px", padding: 0 }}
                        onClick={handleMenuOpen}
                      >
                        <FaCaretDown />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handleSelectAllLevel5}>
                          Select All
                        </MenuItem>
                        <MenuItem onClick={handleClearAllFromMenu}>
                          Clear All
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>

                  <div
                    className="list"
                    style={{
                      textAlign: "left",
                      padding: "10px",
                      width: "231px",
                    }}
                  >
                    {selectedLevel4.map((level4) => (
                      <div key={level4} style={{ alignItems: "center" }}>
                        {level4}
                        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                          {level4 in level5 &&
                            level5[level4].map((level5Option, index) => (
                              <li key={index} className="list-item">
                                <input
                                  type="checkbox"
                                  id={level5Option}
                                  checked={selectedLevel5.includes(
                                    level5Option
                                  )}
                                  onChange={() =>
                                    handleLevel5CheckboxChange(level5Option)
                                  }
                                  style={{
                                    marginRight: "10px",
                                    marginBottom: "15px",
                                  }}
                                />
                                <label
                                  style={{
                                    cursor: "pointer",
                                    color: "black",
                                    paddingLeft: "5px",
                                    fontSize: "14px",
                                  }}
                                  htmlFor={level5Option}
                                >
                                  {level5Option}
                                </label>
                              </li>
                            ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className="card"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              className="title"
              style={{ display: "flex", flexDirection: "row", padding: 8 }}
            >
              <img
                src="datasets.png"
                alt="location icon"
                style={{ marginRight: "8px" }}
              />
              Datasets
            </div>
            <div>
              <Datasetsfordownload
                countryname={selectedCountry}
                selectedDatasets={selectedDatasets}
                setSelectedDatasets={setSelectedDatasets}
                handleDatasetSelection={handleDatasetSelection}
              />
            </div>
          </div>

          <div
            className="card"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              className="title"
              style={{ display: "flex", flexDirection: "row", padding: 8 }}
            >
              <img
                src="Vector.png"
                alt="location icon"
                style={{ marginRight: "8px" }}
              />
              Time Periods
            </div>
            <div style={{ marginLeft: "2%" }}>
              <Timeperiodselect
                selectedYears={selectedYears}
                setSelectedYears={setSelectedYears}
                selectedMonths={selectedMonths}
                setSelectedMonths={setSelectedMonths}
              />
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <DefinitionModal showModal={showModal} setShowModal={setShowModal} />
      )}

      <div
        className="pannel3"
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid rgba(26, 26, 0, 0.16)",
          background: "white",
          width: "23vw",
          right: -20,
          position: "fixed",
          height: "100vh",
        }}
      >
        <div style={{ height: "6.3vh", padding: "12px" }}>
          <div
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "-0.04px",
            }}
          >
            Your Selections
            <div
              style={{
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "25px",
                color: "rgba(7, 6, 0, 0.58)",
              }}
            >
              View all your selections in this panel.
            </div>
          </div>
        </div>

        <div style={{ height: "56vh", padding: " 5px 10px" }}>
          <div
            style={{
              textAlign: "left",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "25px",
              color: "rgba(7, 6, 0, 0.58)",
            }}
          >
            Admin Boundaries
            <div className="selectedboundarypannel3div">
              {selectedCountry ? (
                <div className="selectedboundarypannel3">
                  <div>{selectedCountry}</div>
                </div>
              ) : null}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {selectedStates.map((state, stateIndex) => (
                  <div key={stateIndex} style={{}}>
                    <div
                      className="selectedboundarypannel3"
                      style={{
                        fontWeight: "500",
                        marginBottom: "10px",
                        width: "fit-content",
                        margin: "10px",
                      }}
                    >
                      {state}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      {selectedDistricts
                        .filter((district) =>
                          districts[state]?.includes(district)
                        ) // Filter selected districts by state
                        .map((district, districtIndex) => (
                          <div key={districtIndex} style={{}}>
                            <div
                              className="selectedboundarypannel3"
                              style={{
                                padding: "4px",
                                marginRight: "5px",
                                wordWrap: "break-word",
                                display: "flex",
                                flexWrap: "wrap",
                                marginLeft: "25px",
                                width: "fit-content",
                              }}
                            >
                              {district}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginLeft: "35px",
                                marginTop: "10px",
                              }}
                            >
                              {selectedLevel4
                                .filter((level4value) =>
                                  level4[district]?.includes(level4value)
                                ) // Filter selected Level4 by district
                                .map((level4value, level4Index) => (
                                  <div key={level4Index} style={{}}>
                                    <div
                                      className="selectedboundarypannel3"
                                      style={{
                                        padding: "4px",
                                        marginRight: "5px",
                                        wordWrap: "break-word",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        marginLeft: "25px",
                                        width: "fit-content",
                                      }}
                                    >
                                      {level4value}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        marginLeft: "40px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {selectedLevel5
                                        .filter((level5value) =>
                                          level5[level4value]?.includes(
                                            level5value
                                          )
                                        ) // Filter selected Level5 by Level4
                                        .map((level5value, level5Index) => (
                                          <div
                                            className="selectedboundarypannel3"
                                            key={level5Index}
                                            style={{
                                              padding: "4px",
                                              marginRight: "5px",
                                              wordWrap: "break-word",
                                              display: "flex",
                                              flexWrap: "wrap",
                                              marginLeft: "25px",
                                              width: "fit-content",
                                            }}
                                          >
                                            {level5value}
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "25px",
              color: "rgba(7, 6, 0, 0.58)",
            }}
          >
            Datasets
            <div className="selectedboundarypannel3div">
              <div>
                {selectedDatasets.map((dataset, index) => (
                  <div
                    className="selectedboundarypannel3"
                    style={{ marginTop: 4 }}
                  >
                    <div key={index}>{dataset}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "25px",
              color: "rgba(7, 6, 0, 0.58)",
            }}
          >
            Time Periods
            <div className="selectedboundarypannel3div">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "10px",
                  }}
                >
                  {selectedYears.map((year, index) => (
                    <div
                      key={index}
                      className="selectedboundarypannel3"
                      style={{
                        padding: "4px",
                        marginRight: "5px",
                        maxWidth: "280px",
                        wordWrap: "break-word",
                      }}
                    >
                      {year}
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "25px",
                  }}
                >
                  {selectedMonths.map((month, index) => (
                    <div
                      className="selectedboundarypannel3"
                      key={index}
                      style={{
                        padding: "4px",
                        marginRight: "5px",
                        maxWidth: "280px",
                        wordWrap: "break-word",
                      }}
                    >
                      {month}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "20vw",
            height: "1px",
            background: "rgba(26, 26, 1, 0.12)",
          }}
        ></div>
        <div style={{ height: "20vh", bottom: "0px" }}>
          <div style={{ paddingLeft: "20px", marginTop: "3.4vh" }}>
            {loading ? (
              <button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="download-dataset"
              >
                <CircularProgress
                  style={{ color: "white", height: "20px", width: "20px" }}
                />
                Downloading
              </button>
            ) : (
              <button
                className="download-dataset"
                disabled={isDisabled}
                onClick={handleDataDownload}
              >
                {" "}
                Download{" "}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
