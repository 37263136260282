import { useEffect, useState } from "react";
import { useMap } from "../../hooks/useMap";
import { PiPolygonDuotone } from "react-icons/pi";
import { TbShape } from "react-icons/tb";
import {
  MdOutlineAddLocationAlt,
  MdOutlineDeleteOutline,
  MdOutlinePolyline,
} from "react-icons/md";
import VectorSource from "ol/source/Vector";
import WKT from "ol/format/WKT";
import OLVectorLayer from "ol/layer/Vector";
import { Draw, Modify, Snap } from "ol/interaction";
import { createBox } from "ol/interaction/Draw";
import {
  calculateArea,
  getLayerByName,
  removeLayerByName,
} from "../../lib/gis";
import VisualDataset from "./VisualDataset";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Overlay } from "ol";

function DrawAShape(props) {
  const {
    map,
    area: totalArea,
    draw,
    setDraw,
    setArea,
    setGeometryWKT,
  } = useMap();
  const [type, setType] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("Location");
  const [exploreDataset, setExploreDataset] = useState(false);
  const [drawnArea, setDrawnArea] = useState();
  const [geom, setGeom] = useState();

  useEffect(() => {
    if (!map) return;
    let vectorLayer = new OLVectorLayer({
      source: new VectorSource({ wrapX: false }),
    });
    vectorLayer.set("name", "drawLayer");
    map.addLayer(vectorLayer);

    return () => {
      handleResetFields();
      if (map) removeLayerByName(map, "drawLayer");
    };
  }, [map]);

  useEffect(() => {
    if (!map || type === "") return;
    if (totalArea) {
      setArea(null);
    }

    clearDraw();
    let isDrawInteractionAdded = false;

    // Iterate through map interactions
    map.getInteractions().forEach(function (interaction) {
      // Check if the interaction is a draw interaction
      if (interaction instanceof Draw) {
        // Draw interaction found
        isDrawInteractionAdded = true;
      }
    });
    if (isDrawInteractionAdded) {
      map.removeInteraction(draw);
    }

    const layer = getLayerByName(map, "drawLayer");

    setDraw(
      new Draw({
        source: layer.getSource(),
        type: type === "Box" ? "Circle" : type,
        geometryFunction: type === "Box" ? createBox() : null,
      })
    );

    return () => {
      setDraw(null);
    };
  }, [map, type]);

  useEffect(() => {
    if (!map) return;
    if (isActive) {
      clearDraw();
      /**
       * The measure tooltip element.
       * @type {HTMLElement}
       */
      let measureTooltipElement;

      /**
       * Overlay to show the measurement.
       * @type {Overlay}
       */
      let measureTooltip;

      /**
       * Creates a new measure tooltip
       */
      function createMeasureTooltip() {
        if (measureTooltipElement) {
          measureTooltipElement.parentNode.removeChild(measureTooltipElement);
        }
        measureTooltipElement = document.createElement("div");
        measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
        measureTooltip = new Overlay({
          element: measureTooltipElement,
          offset: [0, -15],
          positioning: "bottom-center",
          stopEvent: false,
          insertFirst: false,
        });
        map.addOverlay(measureTooltip);
      }
      draw.on("drawstart", function (evt) {
        map.addOverlay(measureTooltip);
        let tooltipCoord = evt.coordinate;
        let sketch = evt.feature;
        sketch.getGeometry().on("change", function (evt) {
          const geom = evt.target;
          const area = calculateArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
          measureTooltipElement.innerHTML = area.toFixed(2);
          measureTooltip.setPosition(tooltipCoord);
        });
      });

      draw.on("drawend", (evnt) => {
        map.removeInteraction(draw);
        setIsActive(false);
        if (type !== "Point") {
          const area = calculateArea(evnt.feature.getGeometry());
          setDrawnArea(area);

          if (area > 1000) {
            toast.error("Draw area is geater than 1000 ha");
            return;
          } else {
            const geom = evnt.feature.getGeometry();
            const wktFormat = new WKT({
              splitcollection: true,
            });
            const wktGeom = wktFormat.writeGeometry(geom);
            setGeometryWKT(wktGeom);
            let coordinate = evnt.feature
              .getGeometry()
              .getInteriorPoint()
              .getCoordinates();
            getSelectedLocation([coordinate[0], coordinate[1]]);
          }
        } else {
          let coordinate = evnt.feature.getGeometry().getCoordinates();
          getSelectedLocation(coordinate);
        }
      });
      map.addInteraction(draw);
      createMeasureTooltip();
    } else {
      map.removeInteraction(draw);
    }
  }, [draw, isActive]);

  useEffect(() => {
    if (!map) return;
    return () => {
      if (map) {
        removeLayerByName(map, "drawLayer");
        setDrawnArea();
      }
    };
  }, []);

  const clearDraw = () => {
    const layer = getLayerByName(map, "drawLayer");
    layer.getSource().clear();
    const overlay = map.getOverlays();
    if (overlay) map.removeOverlay(overlay);
    setSelectedLocation("Location");
    setDrawnArea();
    map
      .getOverlays()
      .getArray()
      .forEach((x) => map.removeOverlay(x));
  };

  const getSelectedLocation = async (coordinate) => {
    await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinate[1]}&lon=${coordinate[0]}`
    )
      .then((res) => res.json())
      .then((result) => {
        props.setCountryName(result.address.country);
        setSelectedLocation(result.display_name);
      })
      .catch((err) => console.log(err));
  };

  const handleResetFields = () => {
    clearDraw();
    setExploreDataset(false);
    setSelectedLocation("Location");
    setIsActive(false);
    setType("");
  };

  const explore = () => {
    setExploreDataset(true);
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          width: "400px",
          padding: "16px",
          textAlign: "initial",
          color: "#21201C",
        }}
      />
      <div className="menuContent">
        <div className="exploretext">
          <img
            src="chevron-left.png"
            alt="location icon"
            style={{ marginRight: 10 }}
            onClick={() => props.setShowDrawAShape(false)}
          />
          Draw a shape
        </div>
        <div className="exploresecondarytext">Select a shape.</div>

        <div className="drawTools">
          <button
            className={`${type === "Polygon" ? "active" : ""}`}
            onClick={() => setType("Polygon")}
          >
            <PiPolygonDuotone />
          </button>
          <button
            className={`${type === "Box" ? "active" : ""}`}
            onClick={() => setType("Box")}
          >
            <TbShape />
          </button>
          <button
            className={`${type === "Point" ? "active" : ""}`}
            onClick={() => setType("Point")}
          >
            <MdOutlineAddLocationAlt />
          </button>
        </div>

        <div>
          <button
            className="button"
            style={isActive ? { background: "#999", borderColor: "#999" } : {}}
            onClick={() => setIsActive(!isActive)}
            disabled={type === ""}
          >
            {!isActive ? "Start Draw" : "Stop Draw"}
          </button>
        </div>

        {type !== "" && !isActive && (
          <div style={{ textAlign: "left" }}>
            <button
              className="clearBtn"
              style={{ marginTop: "15px" }}
              onClick={clearDraw}
            >
              <MdOutlineDeleteOutline /> Clear draw
            </button>
          </div>
        )}

        {drawnArea && (
          <div style={{ padding: "16px", textAlign: "center", color: "#999" }}>
            Total Area: {drawnArea.toFixed(2)} ha
          </div>
        )}

        <div className="exploredataset-button-container">
          <div>
            <hr className="horizontal-rule" />
            <button className="selectedloc-button">
              <img src="loc-pin.png" alt="icon" style={{ marginRight: 5 }} />
              {selectedLocation}
              <img
                src="tabler-icon-x.svg"
                className="reset-button"
                alt="Reset"
                onClick={handleResetFields}
              />
            </button>
          </div>

          <div>
            <button
              className="exploredataset-button"
              onClick={explore}
              disabled
            >
             Coming Soon
            </button>
          </div>
        </div>
      </div>
      {exploreDataset && (
        <VisualDataset
          tamsatDailyGraph={props.tamsatDailyGraph}
          setTamsatDailyGraph={props.setTamsatDailyGraph}
          tamsatMonthlyGraph={props.tamsatMonthlyGraph}
          setTamsatMonthlyGraph={props.setTamsatMonthlyGraph}
          chirps5kmMonthlyRainfall={props.chirps5kmMonthlyRainfall}
          setChirps5kmMonthlyRainfall={props.setChirps5kmMonthlyRainfall}
          arc210kmDailyRainfall={props.arc210kmDailyRainfall}
          setarc210kmDailyRainfall={props.setarc210kmDailyRainfall}
          modis5DayIntervalNDVIGraph={props.modis5DayIntervalNDVIGraph}
          setModis5DayIntervalNDVIGraph={props.setModis5DayIntervalNDVIGraph}
          setExploreDataset={setExploreDataset}
          exploreDataset={exploreDataset}
          landcover={props.landcover}
          setlandcover={props.setlandcover}
          chirps5kmDailyRainfall={props.chirps5kmDailyRainfall}
          setChirps5kmDailyRainfall={props.setChirps5kmDailyRainfall}
          era5DailyRainfallGraph={props.era5DailyRainfallGraph}
          setera5DailyRainfallGraph={props.setera5DailyRainfallGraph}
          era5MonthlyRainfallGraph={props.era5MonthlyRainfallGraph}
          setera5MonthlyRainfallGraph={props.setera5MonthlyRainfallGraph}
          era5DailyTemperatureGraph={props.era5DailyTemperatureGraph}
          setera5DailyTemperatureGraph={props.setera5DailyTemperatureGraph}
          era5MonthlyTemperatureGraph={props.era5MonthlyTemperatureGraph}
          setera5MonthlyTemperatureGraph={props.setera5MonthlyTemperatureGraph}
          gsMapDaily={props.gsMapDaily}
          setgsMapDaily={props.setgsMapDaily}
          gsMapMonthly={props.gsMapMonthly}
          setgsMapMonthly={props.setgsMapMonthly}
          sentinal2NDVI={props.sentinal2NDVI}
          setsentinal2NDVI={props.setsentinal2NDVI}
          selectedLocation={selectedLocation}
          countryName={props.countryName}
          setIsDrawerOpen={props.setIsDrawerOpen}
        />
      )}
    </>
  );
}

export default DrawAShape;
