import React, { useRef, useEffect } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'; // Import the highcharts-more module
import HighchartsExporting from 'highcharts/modules/exporting'; // Import the exporting module

// Initialize the additional modules
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);

const ColumnRangeGraph = ({ data, graphName }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const chartData = data.map(item => ({
        name: item.Month,
        low: item.min,
        high: item.max
      }));

      let color;
      if (graphName === 'GSMAP') {
        color = '#8E4EC6';
      } else if (graphName === 'Arc2') {
        color = '#147D6F';
      } else if (graphName === 'Tamsat') {
        color = '#EBBC00';
      } else if (graphName === 'Eras') {
        color = '#E54D2E';
      } else {
        color = '#4D90BB';
      }

      Highcharts.chart(chartRef.current, {
        chart: {
          type: 'columnrange',
          inverted: true
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: data.map(item => item.Month)
        },
        yAxis: {
          title: {
            text: '°C'
          }
        },
        plotOptions: {
          columnrange: {
              borderRadius: '50%',
              dataLabels: {
                  enabled: true,
                  format: '{y}',
              }
          }
      },
  
      legend: {
        enabled: false
      },
      credits:false,
      tooltip: {
        formatter: function () {
          return '<b>' + this.x + '</b><br/>' +
            'Min: ' + this.point.low + '°C' +
             ' '+ 'to'+' '+
            'Max: ' + this.point.high + '°C';
        }
      },
      series: [{
        name: 'Historical Min Max',
        data: chartData,
        color: color // Set the color based on the condition
      }]
    });
  }
}, [data, graphName]);

return <div ref={chartRef} />;
};

export default ColumnRangeGraph;
