import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';

const AreaRangeLineGraphRainMonthly = ({ data, graphName }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data.length > 0  && data.error !== "Null response") {
      const options = {
        chart: {
          type: 'arearange',
          zoomType: 'x'
        },
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: ''
          }
        },
        yAxis: {
          title: {
            text: 'Rainfall (mm)'
          }
        },
        legend: {
          enabled: true
        },
        tooltip: {
          crosshairs: true,
          shared: true,
          valueSuffix: 'mm',
          formatter: function () {
            return '<b>' + Highcharts.dateFormat('%b %e', this.x) + '</b><br/>' +
              'Range: ' + this.point.low + 'mm - ' + this.point.high + 'mm<br/>' +
              'Average: ' + this.points[1].y + 'mm'; 
          }
        },
        credits: false,
        series: [{
          type: 'arearange',
          name: 'Historical Min Max',
          color: graphName === 'Arc2' ? '#147D6F4D' : (graphName === 'Tamsat' ? '#EBBC004D' : '#01619E4D'),
          data: data.map(item => [Date.parse(item.dateString), item.min, item.max]),
        }, {
          type: 'line',
          name: 'Average',
          data: data.map(item => [Date.parse(item.dateString), item.average]),
          color: graphName === 'Arc2' ? '#147D6F' : (graphName === 'Tamsat' ? '#EBBC00' : '#01619E')
        }]
      };

      if (chartRef.current) {
        Highcharts.chart(chartRef.current, options);
      }
    }
  }, [data, graphName]);

  return data.error === "Null response" ? <div>Null values for this time period</div> : <div ref={chartRef} />;
};
export default AreaRangeLineGraphRainMonthly;
