import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DualYAxesLineGraph = ({ data1, data2 }) => {
  const chartRef = useRef(null);
  const [formattedCategories, setFormattedCategories] = useState([]);

  useEffect(() => {
    // Combine data1 and data2 to get a comprehensive list of date_milliseconds
    const allDates = [...new Set([...data1, ...data2].map(entry => entry.dateString))];

    // Sort the dates
    const sortedDates = allDates.sort();

    // Filter dates based on data presence
    const filteredDates = sortedDates.filter(date =>
      data1.some(entry => entry.dateString === date) ||
      data2.some(entry => entry.dateString === date)
    );

    // Format x-axis categories as "01 Jan '23"
    const categories = filteredDates.map(date =>
      Highcharts.dateFormat('%d %b \'%y', new Date(date))
    );

    setFormattedCategories(categories);
  }, [data1, data2]);

  const options = {
    chart: {
      zoomType: 'x',
    },
    title: {
      text: ''
    },
    plotOptions: {
      series: {
        turboThreshold: 9000, // Set to a higher value based on your dataset size
      },
    },
    credits: false,
    xAxis: {
      categories: formattedCategories,
      type: 'datetime', // Set x-axis type to datetime
      labels: {
        formatter: function () {
          return Highcharts.dateFormat('%d %b \'%y', new Date(this.value));
        }
      },
    },
    yAxis: [
      {
        title: {
          text: 'Rainfall (mm)'
        }
      },
      {
        title: {
          text: 'Temperature (°C)'
        },
        opposite: true
      }
    ],
    tooltip: {
      formatter: function () {
        return '<b>' + Highcharts.dateFormat('%e %b %Y', new Date(this.x)) + '</b><br/>' +
               this.series.name + ': ' + this.y;
      }
    },
    series: [
      {
        name: 'Rainfall',
        data: data1.map(entry => ({
          x: new Date(entry.dateString).getTime(), // Convert to UTC timestamp
          y: entry.rainfall
        })),
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          opacity: 0.4,
          stops: [
            [0, '#01619E'], // start color
            [1, 'rgba(1, 97, 158, 0.30)'] // end color
          ]
        },
      },
      {
        name: 'Temperature',
        data: data2.map(entry => ({
          x: new Date(entry.dateString).getTime(), // Convert to UTC timestamp
          y: entry.temperature
        })),
        yAxis: 1,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          opacity: 0.2,
          stops: [
            [0, '#EBBC00'], // start color
            [1, 'rgba(230, 184, 82, 0.40)'] // end color
          ]
        },
      }
    ]
  };

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />;
};

export default DualYAxesLineGraph;
