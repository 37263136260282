import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DoublegraphMonthly = ({ data1, data2 }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    if (chartRef && chartRef.current) {
      const chart = chartRef.current.chart;

      if (chart) {
        chart.update({
          series: [{
            type: 'area',
            name: 'Current',
            data: data1.map(({ Month, rainfall }) => ({ name: Month, y: rainfall })),
            tooltip: {
                valueSuffix: ' mm',
                
              },
              color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                opacity: 0.2,
                stops: [
                  [0, '#EBBC00'], // start color
                  [1, 'rgba(230, 184, 82, 0.40)'] // end color
                ]
              },
          }, {
            type: 'area',
            name: 'Historical',
            data: data2.map(({ Month, rainfall }) => ({ name: Month, y: rainfall })),
            tooltip: {
                valueSuffix: ' mm',
              },
              color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                opacity: 0.2,
                stops: [
                  [0, '#01619E'], // start color
                  [1, 'rgba(1, 97, 158, 0.30)'] // end color
                ]
              },
          }],
        });
      }
    }
  }, [data1, data2]);

  const options = {
    chart: {
      type: 'area',
      zoomType: 'x', 
    },
    credits:false,
    title: {
      text: '',
    },
    xAxis: {
      categories: data1.map(({ Month }) => Month),
      title: {
        text: 'Months',
      },
    },
    yAxis: {
      title: {
        text: 'Rainfall',
      },
    },
    series: [{
        type: 'area',
        name: 'Current',
        data: data1.map(({ Month, rainfall }) => ({ name: Month, y: rainfall })),
        tooltip: {
            valueSuffix: ' mm',
            
          },
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            opacity: 0.2,
            stops: [
              [0, '#EBBC00'], // start color
              [1, 'rgba(230, 184, 82, 0.40)'] // end color
            ]
          },
      }, {
        type: 'area',
        name: 'Historical',
        data: data2.map(({ Month, rainfall }) => ({ name: Month, y: rainfall })),
        tooltip: {
            valueSuffix: ' mm',
          },
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            opacity: 0.2,
            stops: [
              [0, '#01619E'], // start color
              [1, 'rgba(1, 97, 158, 0.30)'] // end color
            ]
          },
      }],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartRef}
    />
  );
};

export default DoublegraphMonthly;
