import React, { useState } from "react";

import '../../login.css';
import { RiEyeCloseLine } from "react-icons/ri";
import { FiEye } from "react-icons/fi";
import { Auth } from 'aws-amplify';


export const Loginmain = (props) => {
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (e) => {
    props.setEmail(e.target.value);
    setEmailError('');
    setPasswordError('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setEmailError('');
    setPasswordError('');
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!props.ForgetPasswordComp) {
      // Handle regular login form submission
      if (!props.email && !password) {
        setEmailError('Please enter email');
        setPasswordError('Please enter password');
        return;
      } else if (!props.email) {
        setEmailError('Please enter email');
        return;
      } else if (!password) {
        setPasswordError('Please enter password');
        return;
      } else {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(props.email)) {
          setEmailError('Please enter email in the correct format');
          return;
        }
      }
    } else {
      // Handle forgot password form submission
      if (!props.email) {
        setEmailError('Please enter email');
        return;
      } else {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(props.email)) {
          setEmailError('Please enter email in the correct format');
          return;
        }
      }
      return;
    }


    try {
      await Auth.signIn(props.email, password);
      // Redirect to home page on successful login
      //history.push('/home');
      setEmailError('');
      setPasswordError('');
      setErrorMessage('');
      props.setMainscreen(true);

    } catch (error) {
      console.log('Login failed:', error);
      setPasswordError('Please enter correct credentials');
      setEmailError('Please enter correct credentials');
      // Optionally, show an error message to the user
    }
  };

  return (
    <>
      <div className="login-right">
        <div className='loginpannel'>
          <img src="Agtuall2.png" alt="Logo" className="logo" />
          <div className='loginheading'>Login to your account</div>
          <div className='subheading'>Don’t have an account? <span>Sign Up</span> </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email" style={{ color: emailError ? 'red' : '' }}>Email</label>
              <input
                className={`logininput ${emailError ? 'error-input' : ''}`}
                type="text"
                id="email"
                value={props.email}
                onChange={handleEmailChange}
                style={{ height: '36px', borderColor: emailError ? 'red' : '' }}
              />
              {emailError && <div className="error-message">{emailError}</div>}
            </div>
            <div className="form-group">
              <div className="password-label-container">
                <label htmlFor="password" style={{ color: errorMessage || passwordError ? 'red' : '', marginRight: 'auto' }}>Password</label>
                <div className="forget-password"
                  style={{ color: 'rgba(7, 6, 0, 0.58)', textAlign: 'right', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', cursor: 'pointer' }}
                  onClick={() => {
                    props.setForgetPasswordComp(!props.ForgetPasswordComp)
                    props.setLoginscreen(!props.loginscreen)
                    //console.log(props.ForgetPasswordComp)
                  }}>
                  Forget password?
                </div>
              </div>
              <div className="password-input-container">
                <input
                  className={`logininput ${errorMessage || passwordError ? 'error-input' : ''}`}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  style={{ height: '36px', borderColor: errorMessage || passwordError ? 'red' : '' }}
                />
                <div className="password-toggle-icon-container" onClick={togglePasswordVisibility}>
                  {showPassword ? <FiEye className="password-toggle-icon" /> : <RiEyeCloseLine className="password-toggle-icon" />}
                </div>
              </div>
            </div>
            {(errorMessage || passwordError) && <div className="error-message" style={{ marginBottom: '10px' }}>{errorMessage || passwordError}</div>}
            <div className="form-group">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={handleRememberMeChange}
                style={{ width: '5%' }}
              />
              <label htmlFor="rememberMe">Remember Me</label>
            </div>
            <button type="submit" className='loginbutton' style={{ backgroundColor: 'black' }} disabled={!!(emailError || passwordError || errorMessage)}>Login</button>
          </form>
        </div>
      </div>

    </>
  );
};
