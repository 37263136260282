import React, { useState } from "react";
import '../../login.css'; 
import { RiEyeCloseLine } from "react-icons/ri";
import { FiEye } from "react-icons/fi";

export const NewPassword = (props) => {
    const [newpassword, setnewPassword] = useState('');
    const [confirmpassword, setconfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleNewPasswordChange = (e) => {
        const newPasswordValue = e.target.value;
        setnewPassword(newPasswordValue);
        setPasswordError('');
        // Check if the password meets the criteria
        if (!/(?=.*[a-zA-Z])(?=.*\d).{8,}/.test(newPasswordValue)) {
            setPasswordError('Please enter a valid password');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const confirmPasswordValue = e.target.value;
        setconfirmPassword(confirmPasswordValue);
        setConfirmPasswordError('');
    };
  
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
  
    const handleSubmit = (e) => {
        e.preventDefault();
        // Check if passwords match
        if (newpassword !== confirmpassword) {
            setConfirmPasswordError('Passwords do not match');
        } else {
            props.setsuccessComp(true);
        }
    };

    const isResetDisabled = !newpassword || !confirmpassword;

    return (
        <>
            <div className="login-right">
                <div className='loginpannel'>
                    <div className='loginheading'>Reset your password</div>
                    <div className='subheading'>Please note that the password must have at least 8 characters, and must contain at least 1 alphabet and 1 digit.</div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <div className="password-label-container">
                                <label htmlFor="password" style={{ color: errorMessage || passwordError ? 'red' : '', marginRight: 'auto' }}> New Password</label>
                            </div>
                            <div className="password-input-container">
                                <input
                                    className={`logininput ${errorMessage || passwordError ? 'error-input' : ''}`}
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    value={newpassword}
                                    onChange={handleNewPasswordChange}
                                    style={{ height: '36px', borderColor: errorMessage || passwordError ? 'red' : '' }}
                                />
                                <div className="password-toggle-icon-container" onClick={togglePasswordVisibility}>
                                    {showPassword ? <FiEye className="password-toggle-icon" /> : <RiEyeCloseLine className="password-toggle-icon" />}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="password-label-container">
                                <label htmlFor="confirmPassword" style={{ color: confirmPasswordError ? 'red' : '', marginRight: 'auto' }}> Confirm Password</label>
                            </div>
                            <div className="password-input-container">
                                <input
                                    className={`logininput ${confirmPasswordError ? 'error-input' : ''}`}
                                    type={showPassword ? 'text' : 'password'}
                                    id="confirmPassword"
                                    value={confirmpassword}
                                    onChange={handleConfirmPasswordChange}
                                    style={{ height: '36px', borderColor: confirmPasswordError ? 'red' : '' }}
                                />
                                <div className="password-toggle-icon-container" onClick={togglePasswordVisibility}>
                                    {showPassword ? <FiEye className="password-toggle-icon" /> : <RiEyeCloseLine className="password-toggle-icon" />}
                                </div>
                            </div>
                            {passwordError && <div className="error-message">{passwordError}</div>}
                            {confirmPasswordError && <div className="error-message">{confirmPasswordError}</div>}
                        </div>
                      
                        <button type="submit" className='loginbutton' style={{ backgroundColor: isResetDisabled ? 'rgba(20, 20, 0, 0.05)' : 'black',color:isResetDisabled ? 'rgba(30, 25, 1, 0.24)' :'#fff', marginBottom: '28px' }} disabled={isResetDisabled}>Reset Password</button>
                    </form>
                </div>
            </div>
        </>
    );
};
