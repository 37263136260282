import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "../../styles.css";
import Interrelation from "./Interrelation";
import Evaluation from "./Evaluation";
import { useDispatch, useSelector } from "react-redux";
const CompareDatasets = (props) => {
const [tabIndex, setTabIndex] = useState(0);
const stateName = useSelector((state) => state.stateName);
const districtName = useSelector((state) => state.districtName);
const getStartYears = useSelector((state) => state.getStartYears);
const getStartMonth = useSelector((state) => state.getStartMonth);
const getEndMonth = useSelector((state) => state.getEndMonth);
const getEndYears = useSelector((state) => state.getEndYears);
const frequency = useSelector((state) => state.frequency);
const getWeatherVariable = useSelector((state) => state.getWeatherVariable);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
    <Box sx={{ borderBottom: '1px solid red', borderColor: 'divider' }}>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        aria-label="compare datasets tabs"
        textColor="secondary"
        indicatorColor="secondary"
        sx={{
          '& .MuiTabs-indicator': {
            // Customize the color of the indicator (underline)
            backgroundColor: tabIndex === 'one' ? '#F76808' : 'inherit',
          },
          '& .MuiTab-root.Mui-selected': {
            // Customize the color of the selected tab label
            color: '#F76808',
          },
        }}
      >
        <Tab label="Intra-Dataset" sx={{ textTransform: 'capitalize', fontSize: '16px' }} />
        <Tab label="Inter-Dataset" sx={{ textTransform: 'capitalize', fontSize: '16px' }} />
      </Tabs>
      <Box>
        {tabIndex === 0 && (
          <div>
           <Evaluation
            comparebutton={props.comparebutton}
            setcomparebutton={props.setcomparebutton}
            countryName={props.countryName}
            setCountryName={props.setCountryName}
            stateName={stateName}
            districtName={districtName}
            getStartMonth={getStartMonth}
            getStartYears={getStartYears}
            getEndYears={getEndYears}
            getEndMonth={getEndMonth}
            frequency={frequency}
            getWeatherVariable={getWeatherVariable}/>
          </div>
        )}
        {tabIndex === 1 && (
          <div>
           <Interrelation
          countryName={props.countryName}
          setCountryName={props.setCountryName}
          stateName={stateName}
          districtName={districtName}
          getStartMonth={getStartMonth}
          getStartYears={getStartYears}
          getEndYears={getEndYears}
          getEndMonth={getEndMonth}
          frequency={frequency}
          getWeatherVariable={getWeatherVariable}
          comparebutton={props.comparebutton}
          setcomparebutton={props.setcomparebutton}
           />
          </div>
        )}
      </Box>
      </Box>
    </>
  );
};

export default CompareDatasets;
